import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";

function OrgLogsList({ hadleradmin }) {
  let token = localStorage.getItem("ChromePayAdmitToken");
  const [pageCount, setpageCount] = useState("");
  const [ShowLoader, setShowLoader] = useState(true);
  let ID = localStorage.getItem("ID");
  const [data, setData] = useState([]);

  const limit = 10;



  const handleblocked = (IP) => {
    axios.post(`/v1/admin/block-ip-address`, {IP},{ headers: {"Authorization" : `Bearer ${token}`}}).then((resp) => {
        if (resp.status) {
          const data = resp?.data;
          if (data.status) {
            toast.success(data.msg);
            return userlist();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }

  
        const total = resp?.data?.totlaRow;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      })
};
  const userlist = async () => {
    await axios
      .post(
        `/v1/admin/getorgLogHistory`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp?.data?.data;
        setData(data);
        const total = resp?.data?.totalPages;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };
  useEffect(() => {
    userlist();
    hadleradmin();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData =
      { page: page } /
      axios
        .post(`/v1/admin/getorgLogHistory`, senData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((resp) => {
          const data = resp?.data?.data;
          setData(data);
        });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };




  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                   
                    </div>
                  
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Organisation Logs List
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">D-ID</th>
                            <th className="min-w-125px">Name</th>
                            <th className="min-w-150px">Email Address</th>
                            <th className="min-w-150px">IP Address</th>
                            <th className="min-w-150px">Latitude</th>
                            <th className="min-w-150px">Longitude</th>
                            <th className="min-w-150px">Address</th>

                            <th className="min-w-100px">Date</th>

                            <th className="min-w-100px ">Time</th>
                            <th className="min-w-100px ">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data?.map((item) => (
                            <tr>
                              <td className="text-center">{item?.ID}</td>
                              <td className="text-center">{item?.Name}</td>
                              <td className="text-center">{item?.Email}</td>
                              <td className="text-center">{item?.IP}</td>
                              <td className="text-center">{item?.location?.latitude || "Not Detected"}</td>
                              <td className="text-center">{item?.location?.longitude || "Not Detected"}</td>
                              <td className="text-center">{item?.location?.address || "Not Detected"}</td>

                              <td className="text-center">{item?.Date}</td>
                              <td className="text-center">
                                <span className="badge badge-light-info fs-5 capitalize-text">
                                  {item?.Time}
                                </span>
                              </td>
                              <td className="text-center">
                                 <div className="d-flex justify-content-center flex-shrink-0">
                                    
                                        <button
                                          onClick={(e) => {
                                            handleblocked(item?.IP);
                                          }}
                                          className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          data-bs-trigger="hover"
                                          title="Block IP "
                                        >
                                          <span className="svg-icon svg-icon-3">
                                            <img
                                              src="/assets_new/images/blocked.png"
                                              width="20px"
                                            />
                                          </span>
                                        </button>
                                    
  
                                   
  
                                  
                                  </div>
                                </td>
                             
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrgLogsList;
