import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { error } from "jquery";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
function SuperAddDocument({ hadlersuper }) {
  let token = localStorage.getItem("SuperAgentChromepayToken");

  const location = useLocation().state;

  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [customer, setCutsmerImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageUrl1, setImageUrl1] = useState("");
  const [imageUrl2, setImageUrl2] = useState("");
  const [imageUrl3, setImageUrl3] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [residances, setresidance] = useState("");
  const [locaDocument, setlocaDocument] = useState("");
  const [landRegistrations, setlandRegistrations] = useState("");
  const [currentStep, setCurrentStep] = useState(3);
  const [profile_View, setprofile_View] = useState(false);
  const shippingStages = ["Profile", "Address", "Document"];
  const handleClick = () => {
    const latInnerLat = document.getElementById("lat").innerHTML;
    console.log(latInnerLat);

    setLat(latInnerLat);

    const latInnerLong = document.getElementById("lon").innerHTML;
    console.log(latInnerLong);

    setLong(latInnerLong);
  };

  const handleresidance = (data) => {
    const url = URL.createObjectURL(data);
    setImageUrl2(url);
    let formdata = new FormData();
    formdata.append("image", data);
    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        setresidance(imgurl);
      })
      .catch(error);
  };

  const handlerlocaDocument = (data) => {
    const url = URL.createObjectURL(data);

    setImageUrl1(url);

    let formdata = new FormData();
    formdata.append("image", data);
    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        setlocaDocument(imgurl);
      })
      .catch(error);
  };

  const handlelandRegistration = (data) => {
    const url = URL.createObjectURL(data);
    setImageUrl3(url);
    let formdata = new FormData();
    formdata.append("image", data);

    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        setlandRegistrations(imgurl);
      })
      .catch(error);
  };

  const AddFormData = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    let dataToSend2 = new FormData();
    dataToSend2.append("landSize", Formvlaues.landSize);
    dataToSend2.append("assetType", Formvlaues.assetType);
    dataToSend2.append("assetID", Formvlaues.assetID);
    dataToSend2.append("residaceImage", residances);
    dataToSend2.append("localImage", locaDocument);
    dataToSend2.append("landImage", landRegistrations);
    dataToSend2.append("isJdcFarmer", Formvlaues.isJdcFarmer == "on" ? 1 : 0);
    dataToSend2.append("isDashboard", 1);
    location.isDashboard = 1;
    let sendData = {
      ...location,
      ...dataToSend2,
    };

    setprofile_View(true);
    await axios
      .post(`/v1/DID/create-web-customer`, sendData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status) {
          let data = res.data;
          toast.success(data.msg);
          setprofile_View(false);
          if (data.status) {
            setprofile_View(false);
            setTimeout(() => {
              navigate("/super/dash-customers");
            }, 3000);
          }
        } else {
          toast.error(data.msg);
          setprofile_View(false);
        }
      });
  };

  useEffect(() => {
    hadlersuper();
  }, []);

  return (
    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <ToastContainer />

        <div class="container-xxl" id="kt_content_container">
          <div class="row g-5 g-xl-8">
            <div class="col-lg-12">
              <div class="card mb-5 mb-xl-10">
                <div
                  class="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_account_profile_details"
                  aria-expanded="true"
                  aria-controls="kt_account_profile_details"
                >
                  <div class="card-title m-0">
                    <h3 class="fw-bold m-0">Add Document</h3>
                  </div>
                  <div class="my">
                    <div class="margin-area">
                      <div class="dot one">1</div>
                      <div class="dot two">2</div>
                      <div
                        class="dot three"
                        style={{ background: "#0C84D9", color: "#fff" }}
                      >
                        3
                      </div>
                      <div class="progress-bar first"></div>
                      <div
                        class="progress-bar second"
                        style={{ background: "#0C84D9" }}
                      ></div>
                      <div class="message message-1">Profile</div>`
                      <div class="message message-2">Address</div>
                      <div class="message message-3">Document</div>
                    </div>
                  </div>
                </div>

                <div
                  id="kt_account_settings_profile_details"
                  class="collapse show"
                >
                  <form onSubmit={(e) => AddFormData(e)}>
                    <div class="card-body border-top p-9">
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                          Asset Types
                        </label>

                        <div className="col-lg-8 fv-row">
                          <select
                            className="form-select border-0 flex-grow-1"
                            data-control="select2"
                            data-placeholder="assetType"
                            data-hide-search="true"
                            name="assetType"
                          >
                            <option value="1" selected="selected">
                              {" "}
                              Select Asset
                            </option>
                            <option value="Land"> Land</option>
                            <option value="House"> House</option>
                            <option value="Car"> Car</option>
                            <option value="Store"> Store</option>
                          </select>
                        </div>
                      </div>

                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                          <span className="">Land Size (HAC.)</span>
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="tel"
                            name="landSize"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Land Size"
                          />
                        </div>
                      </div>

                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                          <span className="">JDC Farmer</span>
                        </label>
                        <div className="col-lg-8 fv-row">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckChecked"
                              name="isJdcFarmer"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-5 mb-6">
                        <div className="col-lg-12">
                          <div className="row mt-5 mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              Identification Document Image
                            </label>
                            <div class="col-lg-8">
                              <div
                                class="image-input image-input-outline"
                                data-kt-image-input="true"
                                style={{
                                  backgroundImage:
                                    "url('/metronic8/demo4/assets/media/svg/avatars/blank.svg')",
                                }}
                              >
                                <div
                                  class="image-input-wrapper w-125px h-125px"
                                  style={{
                                    backgroundImage:
                                      "url(/metronic8/demo4/assets/media/avatars/300-1.jpg)",
                                  }}
                                ></div>

                                <div
                                  style={{ position: "absolute", top: "0px" }}
                                >
                                  {imageUrl1 && (
                                    <img
                                      src={imageUrl1}
                                      alt="Uploaded"
                                      className="w-125px h-125px"
                                    />
                                  )}
                                </div>
                                <label
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="change"
                                  data-bs-toggle="tooltip"
                                  title="Upload Image"
                                >
                                  <i class="bi bi-pencil-fill fs-7"></i>
                                  <input
                                    type="file"
                                    name="localImage"
                                    onChange={(e) =>
                                      handlerlocaDocument(e.target.files[0])
                                    }
                                  />
                                </label>

                                <span
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="cancel"
                                  data-bs-toggle="tooltip"
                                  title="Cancel avatar"
                                >
                                  <i class="bi bi-x fs-2"></i>
                                </span>

                                <span
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="remove"
                                  data-bs-toggle="tooltip"
                                  title="Remove avatar"
                                >
                                  <i class="bi bi-x fs-2"></i>
                                </span>
                              </div>

                              <div class="form-text">
                                Allowed file types: png, jpg, jpeg.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="row mt-5 mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              {" "}
                              Terms and Conditions
                            </label>
                            <div class="col-lg-8">
                              <div
                                class="image-input image-input-outline"
                                data-kt-image-input="true"
                                style={{
                                  backgroundImage:
                                    "url('/metronic8/demo4/assets/media/svg/avatars/blank.svg')",
                                }}
                              >
                                <div
                                  class="image-input-wrapper w-125px h-125px"
                                  style={{
                                    backgroundImage:
                                      "url(/metronic8/demo4/assets/media/avatars/300-1.jpg)",
                                  }}
                                ></div>

                                <div
                                  style={{ position: "absolute", top: "0px" }}
                                >
                                  {imageUrl2 && (
                                    <img
                                      src={imageUrl2}
                                      alt="Uploaded"
                                      className="w-125px h-125px"
                                    />
                                  )}
                                </div>
                                <label
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="change"
                                  data-bs-toggle="tooltip"
                                  title="Upload Image"
                                >
                                  <i class="bi bi-pencil-fill fs-7"></i>
                                  <input
                                    type="file"
                                    name="residaceImage"
                                    onChange={(e) =>
                                      handleresidance(e.target.files[0])
                                    }
                                  />
                                </label>

                                <span
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="cancel"
                                  data-bs-toggle="tooltip"
                                  title="Cancel avatar"
                                >
                                  <i class="bi bi-x fs-2"></i>
                                </span>

                                <span
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="remove"
                                  data-bs-toggle="tooltip"
                                  title="Remove avatar"
                                >
                                  <i class="bi bi-x fs-2"></i>
                                </span>
                              </div>

                              <div class="form-text">
                                Allowed file types: png, jpg, jpeg.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="row mt-5 mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              {" "}
                              Assets Ownership Certificate
                            </label>
                            <div class="col-lg-8">
                              <div
                                class="image-input image-input-outline"
                                data-kt-image-input="true"
                                style={{
                                  backgroundImage:
                                    "url('/metronic8/demo4/assets/media/svg/avatars/blank.svg')",
                                }}
                              >
                                <div
                                  class="image-input-wrapper w-125px h-125px"
                                  style={{
                                    backgroundImage:
                                      "url(/metronic8/demo4/assets/media/avatars/300-1.jpg)",
                                  }}
                                ></div>

                                <div
                                  style={{ position: "absolute", top: "0px" }}
                                >
                                  {imageUrl3 && (
                                    <img
                                      src={imageUrl3}
                                      alt="Uploaded"
                                      className="w-125px h-125px"
                                    />
                                  )}
                                </div>
                                <label
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="change"
                                  data-bs-toggle="tooltip"
                                  title="Upload Image"
                                >
                                  <i class="bi bi-pencil-fill fs-7"></i>
                                  <input
                                    type="file"
                                    name="landImage"
                                    onChange={(e) =>
                                      handlelandRegistration(e.target.files[0])
                                    }
                                  />
                                </label>

                                <span
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="cancel"
                                  data-bs-toggle="tooltip"
                                  title="Cancel avatar"
                                >
                                  <i class="bi bi-x fs-2"></i>
                                </span>

                                <span
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="remove"
                                  data-bs-toggle="tooltip"
                                  title="Remove avatar"
                                >
                                  <i class="bi bi-x fs-2"></i>
                                </span>
                              </div>

                              <div class="form-text">
                                Allowed file types: png, jpg, jpeg.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                      <button
                        type="submit"
                        onClick={handleClick}
                        class="btn btn-primary"
                        id="kt_account_profile_details_submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
          <div class="container-xxl d-flex flex-column flex-md-row flex-stack">
            <div class="text-dark order-2 order-md-1">
              <span class="text-muted text-hover-primary fw-semibold me-2 fs-6">Powered by</span>
              <a
                href="#"
                class="text-muted text-hover-primary fw-semibold me-2 fs-6"
              >
                Chromepay
              </a>
            </div>

            <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
              <li class="menu-item">
                <a href="#" class="menu-link px-2">
                  About
                </a>
              </li>
              <li class="menu-item">
                <a href="#" class="menu-link px-2">
                  Support
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Modal
        style={{ marginTop: "15rem" }}
        show={profile_View}
        onHide={() => setprofile_View(false)}
      >
        <Modal.Header>
          <Modal.Title> Please Wait Work is Processing </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="d-flex justify-content-center align-items-center">
              <div
                class="spinner-border text-primary "
                style={{ width: "5rem", height: "5rem" }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SuperAddDocument;
