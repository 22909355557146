
import GraphComponent from "./GraphComponent";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { JWT } from "jsonwebtoken";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import moment, { months } from "moment";
import { useSelector } from "react-redux";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";
import UserGraph from "../GraphComponent/UserJDCGraph"

import { useNavigate } from "react-router-dom";


function DashbordJdc({ hadlerjdc }) {


  const { authType } = useSelector((state) => state.auth);


  const navigate = useNavigate();
  let token = localStorage.getItem("token");



  const [useractivedatayear, setuseractivedatayear] = useState([]);
  const [JdcProfession, setJdcProfession] = useState([]);
  const [JdcFilter, setJdcFilter] = useState("");
  const [genderFilter, setJdcGender] = useState("");
  const [data1, setData1] = useState([]);
  const [finduser, setFinduser] = useState([]);
  const [transvalue, settransvalue] = useState([]);
  const [transmonths, settransmonths] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [ShowLoader, setShowLoader] = useState(true);

  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);

  const [loanData, setLoanData] = useState([]);
  const [savingData, setSavingData] = useState([]);

  const [useractivedatamonths, setuseractivedatamonths] = useState([]);

  const [frequency, setFrequency] = useState("month");



  const formatNumber = (number) => {
    if (number >= 1e12) {
      return (number / 1e12).toFixed(2) + "T"; 
    } else if (number >= 1e9) {
      return (number / 1e9).toFixed(2) + "B"; 
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(2) + "M"; 
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(2) + "k"; 
    } else {
      return number?.toString(); 
    }
  };
  
 

  useEffect(() => {
    const fetchData = () => {
      let body = {
        Type: frequency,
        category: JdcFilter,
        gender: genderFilter,
        fromDate: fromDate,
        toDate: toDate,
      };
      axios
        .post(`/v1/super-Agent/get_jdc_cust_data_graph`, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const userData = res.data.data;
          const loanTransactions = res.data.loanTransactions;
          const savingTransactions = res.data.savingTransactions;

          const userActivityCounts = userData.map((item) => item.count);
          const userActivityMonths = userData.map((item) => item.value);


          const loanAmounts = loanTransactions.map((item) => item.totalAmount);
          const savingAmounts = savingTransactions.map(
            (item) => item.totalAmount
          );
          const months = userActivityMonths; 

          settransmonths(months);
          settransvalue(userActivityCounts);

          setuseractivedatayear(userActivityCounts);
          setuseractivedatamonths(months);
          setLoanData(loanAmounts);
          setSavingData(savingAmounts);
        });
    };

    fetchData();
  }, [frequency, JdcFilter, genderFilter, fromDate, toDate]);

  const chartOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: useractivedatamonths,
      labels: {
        style: {
          colors: "#37697f", 
          fontSize: "12px",
        },
      },
    },
    yaxis: [
      {
        title: {
          text: "Activity & Savings",
          style: {
            color: "#333",
            fontWeight: "bold",
          },
        },
        labels: {
          style: {
            colors: "#333",
            fontSize: "12px",
          },
        },
      },
      {
        opposite: true,
        title: {
          text: "Loan Amount",
          style: {
            color: "#333",
            fontWeight: "bold",
          },
        },
        labels: {
          style: {
            colors: "#FF4560", 
            fontSize: "12px",
          },
        },
      },
    ],
    colors: ["#63a8b3", "#FF4560", "#00E396"],
    series: [
      {
        name: "User Activity (Buildings)",
        type: "bar",
        data: useractivedatayear,
        color: "#63a8b3",
      },
      {
        name: "Loan Amount",
        type: "line",
        data: loanData,
        yAxisIndex: 1, 
        stroke: {
          curve: "smooth",
          colors: ["#FF4560"],
        },
      },
      {
        name: "Saving Amount",
        type: "line",
        data: savingData,
        stroke: {
          curve: "smooth",
          colors: ["#00E396"],
        },
      },
    ],
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#333"], 
        fontWeight: "bold",
      },
    },
    stroke: {
      width: [0, 3, 3], 
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
      labels: {
        colors: "#333", 
      },
    },
    grid: {
      borderColor: "#e7e7e7",
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  const JdcDashboardInfo = () => {
    setShowLoader(true)

    axios
      .post(
        `/v1/super-Agent/get-jdc-dash`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
        const finduser = data.finduser;
        setData1(data);

        setFinduser(finduser);
        setShowLoader(false)


      });
  };

  const JdcCategories = () => {
    axios
      .post(
        `/v1/super-Agent/get-profession-categories `,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let Profession = resp.data?.data;
        setJdcProfession(Profession);
      });
  };

  const UserPermoance = () => {
    let body = { Type: frequency, category: JdcFilter, gender: genderFilter };
    axios
      .post(`/v1/super-Agent/get_jdc_cust_data_graph`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const userData = res.data.data;
        const year = userData.map((item) => item.count);
        const month = userData.map((item) => item.value);
        setuseractivedatayear(year);
        setuseractivedatamonths(month);
      });
  };

  useEffect(() => {
  }, [frequency, JdcFilter]);



  useEffect(() => {
    JdcDashboardInfo();
    UserPermoance();
    hadlerjdc();
    JdcCategories();
  }, []);





const handleResetDID = ()=>{

  setToDate('')
  setFromDate('')
  setJdcGender('')
  setJdcFilter('')
  setFrequency("month")

}



  return (
    <>
      <ToastContainer position="top-right" />
      <CustomerLoader loader={ShowLoader} />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
           

                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-flush">
                      <div className="bg_div card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px">
                        <h3 className="card-title align-items-start flex-column text-white pt-15">
                          <span className="fw-bold fs-2x mb-3">
                             Dashboard
                          </span>
                          <div className="fs-4 text-white"></div>
                        </h3>
                      </div>

                      <div className="card-body mt-n20">
                        <div className="mt-n20 position-relative dashorgnastion">
                          <div className="row g-md-3 g-1  g-lg-6">
                            <div className="col-md-4 col-sm-6 col-6">
                              <div className="retative bg-gray-100 ative animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fas fa-id-card"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                   
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {formatNumber( data1?.totalIdcount)}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Active IDs{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-6">
                              <div className="retative bg-gray-100  animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                  
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {formatNumber(data1?.JDCfarmers)}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Active Farmers{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                                                     <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                    
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {formatNumber(data1?.totalTransactions)}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Transactions
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                   
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {`${formatNumber(data1?.totalSales)}`}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Store Front Sales (ETB)
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                   
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {`${formatNumber(data1?.totalSaving || 0)}`}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Savings (ETB)
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                   
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {`${formatNumber(data1?.totalLoan || 0)}`}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Loan Disbursed (ETB)
                                  </span>
                                </div>
                              </div>
                            </div>


                            <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                   
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {`${formatNumber(data1?.totalLoanRepayment || 0)}`}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Loan Repayments (ETB)
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                    
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {`${formatNumber(
                                      data1?.noOfCoopertives || 0
                                    )}`}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Numbers Of Cooperative
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4 col-sm-6 col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                   
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {data1?.unionConts}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Numbers Of Unions
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <UserGraph/>

                  <GraphComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashbordJdc;
