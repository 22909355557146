import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Line } from "react-chartjs-2";
import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
var jwt = require("jsonwebtoken");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function AllOrgnReport({ hadleradmin }) {
  const { _id } = useParams();
  let token = localStorage.getItem("ChromePayAdmitToken");
  const [Today_date, setToday_date] = useState([]);
  const [Last_name, setLast_name] = useState([]);
  const [useractivedata, setuseractivedata] = useState([]);
  const [useractivedatayear, setuseractivedatayear] = useState([]);
  const [section, setSection] = useState([]);
  const [addres, setaddres] = useState([]);
  const orgID = _id;
  const map1 = useractivedata.map((item) => item.customers);
  const finaldata = useractivedatayear.map((item) => item.Transections);
  const username = useractivedata.map((item) => item.name);
  const finaldatauser = useractivedatayear.map((item) => item.name?.slice(-15));

  const UserPermoance = async () => {
    await axios
      .post(`/OrgChart`, {}, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        const userData = res.data.sorted;
        setuseractivedata(userData);
      });
  };
  useEffect(() => {
    UserPermoance();
    UserPermoanceyear();
    hadleradmin();
  }, []);

  const UserPermoanceyear = async () => {
    await axios
      .post(
        `/v1/admin/OrgTransectionChart`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const userData1 = res.data.sorted;

        setuseractivedatayear(userData1);
      });
  };

  useEffect(() => {
    const orgTransectionsSection = async () => {
      await axios
        .post(
          "/v1/admin/orgDashSection",
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((resp) => {
          let data = resp.data;
          setSection(data);
        });
    };
    orgTransectionsSection();
  }, []);

  const AddFormData = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);
    axios
      .post(`/v1/admin/OrgChart`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const userData = res.data.sorted;
        setuseractivedata(userData);
      });
  };
  const seconedAddFormData = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);
    axios
      .post(`/v1/admin/OrgTransectionChart`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const userData = res.data.sorted;
        setuseractivedatayear(userData);
      });
  };

  return (
    <>
      <div
        className="wrapper d-flex flex-column flex-row-fluid mb-0 back-color"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid mb-0 py-0"
          id="kt_content"
        >
          <div className="container-xxl mb-0" id="kt_content_container">
            <div className="card-body  pt-9 pb-0 "></div>
          </div>
        </div>

        <div className="container-xxl" id="kt_content_container">
          <div className="row g-5 g-xl-8">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card card-flush h-xl-100">
                    <div className="card-header pb-3row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-md-6">
                            {" "}
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-800 mt-5">
                                Organisation Performance
                              </span>
                              <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
                            </h3>
                          </div>
                          <div className="col-md-6 text-end">
                            <div className="d-lg-flex1 align-lg-items-end">
                              <form onSubmit={(e) => seconedAddFormData(e)}>
                                <div className="rounded d-flex flex-column flex-lg-row mend align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                                  <div className="row flex-grow-1 mb-5 mb-lg-0">
                                    <div className="col-5 d-flex ps-1 input-container align-items-center mb-3  mb-lg-0">
                                      <label className="small_label my-label">
                                        Start Date
                                      </label>
                                      <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                                        <i className="fad fa-calendar"></i>
                                      </span>
                                      <input
                                        type="date"
                                        className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                                        name="fromDate"
                                        placeholder="from Date.."
                                      />
                                      <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                    </div>

                                    <div className="col-4 d-flex ps-1 align-items-center mb-3 input-container mb-lg-0">
                                      <label className="small_label my-label">
                                        End Date
                                      </label>
                                      <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                                        <i className="fad fa-calendar"></i>
                                      </span>
                                      <input
                                        type="date"
                                        className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                                        name="toDate"
                                        placeholder="to Date.."
                                      />
                                    </div>
                                    <div className="col-3 d-flex align-items-center mb-3 ps-1 input-container mb-lg-0">
                                      <button
                                        type="submit"
                                        className="btn btndesignorg"
                                        id="kt_advanced_search_button_1"
                                      >
                                        <i
                                          className="fad fa-search"
                                          style={{ fontSize: "1.3rem" }}
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white overflow-auto">
                      <Chart
                        type="bar"
                        width={"100%"}
                        height={250}
                        series={[
                          {
                            name: "Transaction",
                            data: finaldata,
                          },
                        ]}
                        options={{
                          plotOptions: {
                            bar: {
                              borderRadius: 2,
                              columnWidth: 80,
                            },
                          },
                          title: {},

                          subtitle: {},

                          colors: ["#3c7f8c"],
                          theme: { mode: "light" },

                          xaxis: {
                            tickPlacement: "on",
                            categories: finaldatauser,
                          },

                          yaxis: {
                            labels: {
                              formatter: (val) => {
                                return `${val}`;
                              },
                              style: { fontSize: "15", colors: ["#3c7f8c"] },
                            },
                            title: {},
                          },

                          legend: {
                            show: true,
                            position: "right",
                          },

                          dataLabels: {
                            formatter: (val) => {
                              return `${val}`;
                            },
                            style: {
                              colors: ["white"],
                              fontSize: 12,
                              position: "top",
                            },
                          },
                        }}
                      ></Chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
              <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                <div className="text-dark order-2 order-md-1">
                  <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">
                    Powered by
                  </span>
                  <a
                    href="#"
                    className="text-muted text-hover-primary fw-semibold me-2 fs-6"
                  >
                    Chromepay
                  </a>
                </div>

                <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                  <li className="menu-item">
                    <a href="#" className="menu-link px-2">
                      About
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href="#" className="menu-link px-2">
                      Support
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllOrgnReport;
