import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { error } from "jquery";
import CustomerLoader from "../SuperAgent/CRM/Modal/CustomerLoder";
var jwt = require("jsonwebtoken");

function DigitalID({ hadleradmin }) {
  const navigate = useNavigate();
  let token = localStorage.getItem("ChromePayAdmitToken");

  const [data, setData] = useState([]);
  const [pageCount, setpageCount] = useState("");
  const [menu, setMenu] = useState([]);
  const [ShowLoader, setShowLoader] = useState(true);
  const limit = 10;
  var decode1 = jwt.decode(token);
  let adminID = decode1.admminID;

  const userlist = async () => {
    setShowLoader(true);

    await axios
      .post(
        `/v1/admin/pendingCust`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.findcust;

        setData(data);
        const total = resp.data.totlaRow;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    userlist();
    subAdminlist();
    hadleradmin();
  }, []);
  ///////////////pagenestion///////////////
  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };

    axios
      .post(`/v1/admin/pendingCust`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.findcust;
        setData(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  ////////////////delete api call /////////////////
  const handleVerfiy = (_id) => {
    const { custID } = { custID: _id };
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to verify selected Customer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, verify it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`/v1/admin/customerVerify/${custID}/${adminID}`)
          .then((res) => {
            if (res.status) {
              let data = res.data;
              if (data.status) {
                Swal.fire(
                  "verified!",
                  "Customer verified successfully",
                  "success"
                );
                return userlist();
              } else {
                toast.error(data.msg);
              }
            } else {
              toast.error(data.msg);
            }
          });
      }
    });
  };

  const subAdminlist = async () => {
    await axios
      .post(
        `/v1/admin/subAdminRole/${adminID}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.find;

        setMenu(data.customer);
      });
  };

  const handledelete = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete selected Customer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/v1/admin/deleteCustomer/${_id}/${adminID}`)
          .then((res) => {
            if (res.status) {
              let data = res.data;
              if (data.status) {
                Swal.fire(
                  "Deleted!",
                  "Customer deleted successfully",
                  "success"
                );
                return userlist();
              } else {
                toast.error(data.msg);
              }
            } else {
              toast.error(data.msg);
            }
          });
      }
    });
  };
  const handleView = (_id) => {
    navigate(`/customer-view-admin/${_id}`);
  };

  const handlesuspend = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to Suspend selected Customer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Suspend it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(`/v1/admin/suspendcustomer/${_id}/${adminID}`).then((res) => {
          if (res.status) {
            let data = res.data;
            if (data.status) {
              Swal.fire("Suspend!", "Customer Suspend successfully", "success");
              return userlist();
            } else {
              toast.error(data.msg);
            }
          } else {
            toast.error(data.msg);
          }
        });
      }
    });
  };

  const fitteruserdata = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    axios
      .post(`/v1/admin/pendingCust`, Formvlaues)
      .then((resp) => {
        const data = resp.data.findcust;
        setData(data);
        console.log("ghjsgywqbqwkjsdhbmnswbqsjwbd,wjsquk    svwqmjdhw", data);
      })
      .catch(error);
  };
  return (
    <div>
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        Digital ID
                      </span>
                    </div>

                    <div className="d-flex flex-column">
                      <form onSubmit={fitteruserdata}>
                        <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center  p-5  h-lg-60px me-lg-10 my-5">
                          <div className="min-w-200px text-end  d-flex gap-2 rounded  justify-content-end">
                            <div className="col-lg-12 col-md-6  col-sm-6 d-flex align-items-center mb-3 mb-lg-0">
                              <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-phone"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                </svg>
                              </span>
                              <input
                                type="text"
                                className="form-control form-control-flush flex-grow-1"
                                name="digitalrefID"
                                placeholder="D-ID"
                              />
                            </div>
                          </div>

                          <div className="min-w-150px text-start  d-flex gap-2 rounded ">
                            <button
                              type="submit"
                              className="btn btn-dark me-2"
                              id="kt_advanced_search_button_1"
                            >
                              Search
                            </button>
                            <button
                              style={{ padding: "10px 22px 10px 22px" }}
                              type="reset"
                              onClick={userlist}
                              className="btn btn-secondary me-2"
                              id="kt_advanced_search_button_1"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Digital Id List
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">Digital ID ref</th>
                            <th className="min-w-125px">D-ID</th>
                            <th className="min-w-125px">Full Name</th>
                            <th className="min-w-125px">Email</th>
                            <th className="min-w-125px">Date of Birth</th>
                            <th className="min-w-125px">
                              Date of Registration
                            </th>
                            <th className="min-w-125px">Status</th>
                            <th className="min-w-100px text-center">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data?.map((item) => (
                            <tr>
                              <td className="text-center">
                                {item?.digitalrefID}{" "}
                              </td>
                              <td className="text-center">{item?._id}</td>
                              <td className="text-center"> {item?.fullname}</td>
                              <td className="text-center"> {item?.email}</td>

                              <td className="text-center">
                                {moment(item?.dateOfBirth).format("DD/MM/YYYY")}
                              </td>
                              <td className="text-center">
                                {moment(item?.createdAt || "").format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              {item?.status == "pending" ? (
                                <>
                                  <td className="text-center">
                                    <span className="badge badge-light-pending text-danger fs-5">
                                      {item?.status?.charAt(0).toUpperCase() +
                                        item?.status?.slice(1)}
                                    </span>
                                  </td>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <td className="text-center">
                                    <span className="badge badge-light-info text-success fs-5">
                                      {item?.status?.charAt(0).toUpperCase() +
                                        item?.status?.slice(1)}
                                    </span>
                                  </td>
                                </>
                              )}

                              <td className="text-center">
                                <div className="d-flex justify-content-center flex-shrink-0">
                                  <>
                                    {item?.adminStatus == "pending" ? (
                                      <>
                                        <button
                                          onClick={(e) => {
                                            handleVerfiy(item?._id);
                                          }}
                                          className="btn btn-icon btn-active-color-primary btn-sm me-1 mr-4"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          data-bs-trigger="hover"
                                          title="Pending "
                                        >
                                          <span className="svg-icon svg-icon-3">
                                            <img
                                              src="/assets_new/images/pendding1.png"
                                              width="30px"
                                            />
                                          </span>
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          onClick={(e) => {
                                            handleVerfiy(item?._id);
                                          }}
                                          className="btn btn-icon btn-active-color-primary btn-sm me-1 mr-4"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          data-bs-trigger="hover"
                                          title="Verified"
                                        >
                                          <span className="svg-icon svg-icon-3">
                                            <img
                                              src="/assets_new/images/verify.png"
                                              width="28px"
                                            />
                                          </span>
                                        </button>
                                      </>
                                    )}
                                  </>

                                  <button
                                    onClick={(e) => {
                                      handlesuspend(item?._id);
                                    }}
                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-title="Password Reset "
                                    data-bs-trigger="hover"
                                    title="Suspend "
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <img
                                        src="/assets_new/images/blocked.png"
                                        width="20px"
                                      />
                                    </span>
                                  </button>

                                  <button
                                    onClick={(e) => {
                                      handleView(item?._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="View Detail"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </button>

                                  <button
                                    onClick={(e) => {
                                      handledelete(item?._id);
                                    }}
                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Delete"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <img
                                        src="/assets_new/images/cross.png"
                                        width="20px"
                                      />
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination justify-content-end gap-2"
                        }
                        pageClassName={"page-item mx-3"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DigitalID;
