import { Link, useHistory } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertMessage from "./AlertMessage";
import axios from "axios";
import Chart from "react-apexcharts";
import moment, { months } from "moment";
import { useNavigate } from "react-router-dom";
var jwt = require("jsonwebtoken");

export default function OrganizationDashboard({ hadlerorg }) {
  const navigate = useNavigate();
  let token = localStorage.getItem("organizationToken");
  var decode1 = jwt.decode(token);
  let orgID = decode1.OrganisationID;
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [finduser, setFinduser] = useState([]);
  const [findTrans, setFindTrans] = useState([]);

  const [section, setSection] = useState([]);
  const [useractivedatayear, setuseractivedatayear] = useState([]);
  const [useractivedatamonths, setuseractivedatamonths] = useState([]);
  const [orgdash, setOrgDash] = useState([]);

  const [frequency, setFrequency] = useState("month");

  // bank checking logic

  const [checkbank, setcheckbank] = useState(true);

  useEffect(() => {
    let orgAgentType = localStorage.getItem("orgAgentType");
    if (orgAgentType == "Union") {
      setcheckbank(false);
    } else if (orgAgentType == "Cooperative") {
      setcheckbank(false);
    } else {
      setcheckbank(true);
    }
  }, []);



  const userlist = () => {
    axios
      .post(
        `/v1/org/dash/detail`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
        const findLicenseFees = data.findLicenseFees;
        const finduser = data.finduser;
        const findTrans = data.findTrans;
        setData1(data);
        setOrgDash(findLicenseFees);
        setFinduser(finduser);
        setFindTrans(findTrans);
      });
  };

  const UserPermoance = () => {
    let type = frequency;
    let body = { Type: type };
    axios
      .post(`/v1/org/dash/get_org_cust_data_graph`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const userData = res.data.data;
        const year = userData.map((item) => item.count);
        const month = userData.map((item) => item.value);
        setuseractivedatayear(year);
        setuseractivedatamonths(month);
      });
  };

  const custDetail = (_id) => {
    navigate(`/org-customer-view/${_id}`);
  };



  useEffect(() => {
    userlist();
    hadlerorg();
  }, []);
  useEffect(() => {
    UserPermoance();
  }, [frequency]);

  const handlesorting = (event) => {
    let sort = event.target.value;
    setFrequency(sort);
    UserPermoance(sort);
    console.log(sort);
  };
  let mystyle = {
    border: "2px solid red",
  };

  return (
    <>
      <ToastContainer position="top-right" />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                {!data1.remaning_Licenses >= 15 ? <AlertMessage /> : ""}

                <div className="row">
                  <div className="col-lg-6">
                    <div className="card card-flush">
                      <div className="bg_div card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px">
                        <h3 className="card-title align-items-start flex-column text-white pt-15">
                          <span className="fw-bold fs-2x mb-3">
                            {section.name}
                          </span>
                          <div className="fs-4 text-white"></div>
                        </h3>
                      </div>

                      <div className="card-body mt-n20">
                        <div className="mt-n20 position-relative dashorgnastion">
                          <div className="row g-3 g-lg-6">
                            <div className="col-6">
                              <div className="retative bg-gray-100 ative animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fas fa-id-card"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={data1.findNoOfuser}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {data1.findNoOfuser}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Active{" "}
                                    {checkbank ? "IDs" : "Bruh IDs"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="retative bg-gray-100  animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={data1.find_Agent}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {data1.find_Agent}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Number Of Agents
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-envelope-open-dollar"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={`$${orgdash.recuuringFees}`}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {`$${orgdash.recuuringFees}`}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Recurring Fees{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            {!data1.remaning_Licenses >= 15 ? (
                              <div className="col-6">
                                <Link to="/remaning-license">
                                  <div
                                    className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5"
                                    style={mystyle}
                                  >
                                    <div className="symbol symbol-30px me-5 mb-8">
                                      <span className="symbol-label">
                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                          <i className="fad fa-landmark"></i>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="m-0">
                                      <span
                                        data-kt-countup="true"
                                        data-kt-countup-value={
                                          data1.remaning_Licenses
                                        }
                                        className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                      >
                                        {data1.remaning_Licenses}
                                      </span>
                                      <span className="text-gray-500 fw-semibold fs-6">
                                        Remaining{" "}
                                        {checkbank ? "IDs" : "Bruh IDs"}{" "}
                                        Licences{" "}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ) : (
                              <div className="col-6">
                                <Link to="/remaning-license">
                                  <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                    <div className="symbol symbol-30px me-5 mb-8">
                                      <span className="symbol-label">
                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                          <i className="fad fa-landmark"></i>
                                        </span>
                                      </span>
                                    </div>
                                    <div className="m-0">
                                      <span
                                        data-kt-countup="true"
                                        data-kt-countup-value={
                                          data1.remaning_Licenses
                                        }
                                        className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                      >
                                        {data1.remaning_Licenses}
                                      </span>
                                      <span className="text-gray-500 fw-semibold fs-6">
                                        Remaining{" "}
                                        {checkbank ? "IDs" : "Bruh IDs"}{" "}
                                        Licences{" "}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-flush h-xl-100">
                      <div
                        className="container card-header pt-7"
                        style={{ display: "block" }}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            {" "}
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-800">
                                Organisation Activity
                              </span>
                              <span className="text-gray-400 mt-1 fw-semibold fs-6">
                                Activity Of Organisation
                              </span>
                            </h3>
                          </div>

                          <div className="col-md-6 p-0">
                            <form>
                              <div className="d-flex align-items-center filterCss gap-1 w-100">
                                <div>
                                  <select
                                    defaultValue={frequency}
                                    onChange={handlesorting}
                                    className="form-select graph-select"
                                  >
                                    <option value="day"> Day</option>
                                    <option value="week"> Week</option>
                                    <option value="month"> Monthly</option>
                                    <option value="year">Yearly</option>
                                  </select>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="bg-white overflow-auto">
                        <Chart
                          type="bar"
                          width={"100%"}
                          height={400}
                          series={[
                            {
                              name: "User",
                              data: useractivedatayear,
                            },
                          ]}
                          options={{
                            plotOptions: {
                              bar: {
                                borderRadius: 2,
                                columnWidth: "40%",
                              },
                              dataLabels: {
                                position: "Top",
                              },
                            },
                            title: {},

                            subtitle: {},

                            colors: ["#3c7f8c"],
                            theme: { mode: "light" },

                            xaxis: {
                              tickPlacement: "on",
                              categories: useractivedatamonths,
                            },

                            yaxis: {
                              labels: {
                                formatter: (val) => {
                                  return `${val}`;
                                },
                                style: { fontSize: "15", colors: ["#3c7f8c"] },
                              },
                              title: {},
                            },

                            legend: {
                              show: true,
                              position: "right",
                            },

                            dataLabels: {
                              formatter: (val) => {
                                return `${val}`;
                              },
                              style: {
                                colors: ["white"],
                                fontSize: 10,
                                position: "top",
                              },
                            },
                          }}
                        ></Chart>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">
                        Recent Data
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Recent Users
                      </span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">Name</th>
                            <th className="min-w-150px">D-ID ref No.</th>
                            <th className="min-w-150px">Contact No. </th>
                            <th className="min-w-100px">Date of Creation</th>
                            <th className="min-w-100px ">Status</th>
                            <th className="min-w-100px text-end">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {finduser?.map((item) => (
                            <tr>
                              <td>{item?.fullname}</td>
                              <td>{item?.digitalrefID}</td>
                              <td>
                                <span>{item?.phone}</span>
                              </td>
                              <td>
                                {moment(item?.createdAt).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                {item?.status == "pending" ? (
                                  <>
                                    <span className="badge badge-light-pending text-danger fs-5">
                                      {item?.status?.charAt(0).toUpperCase() +
                                        item?.status?.slice(1)}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span className="badge badge-light-info text-success fs-5">
                                      {item?.status?.charAt(0).toUpperCase() +
                                        item?.status?.slice(1)}
                                    </span>
                                  </>
                                )}
                              </td>
                              <td>
                                <div className="d-flex justify-content-end flex-shrink-0">
                                  <button
                                    onClick={(e) => custDetail(item?._id)}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
