import React, { useState } from 'react'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import Header from "./Header";
import Sidebar from "./Sidebar";
import PhoneInput from "react-phone-input-2";
import { useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function AdminEdit({ hadlerorg }) {
  const { _id } = useParams();
   let token = localStorage.getItem("organizationToken");
  const navigate = useNavigate();
  const [Image, setImage] = useState();
  const [data, setData] = useState();
  const [phone, setPhone] = useState('');
  const userlist = () => {
    const { adminID } = { adminID: _id }
    axios.post(`/v1/org/Agent/view-admin-detail`, { adminID }, { headers: { "Authorization": `Bearer ${token}` } })
      .then(resp => {
        let data = resp.data.data;
        let number = data.phone
        setPhone(number)
        setData(data);
        console.log({ data, number });
      })

  }





  const AddFormData = async (e) => {







    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formdata = Formvlaues;
    console.log(Formvlaues);
    axios.post(`/v1/org/Agent/update-admin`, formdata, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {

        let data = res.data;
        if (data) {

          toast.success(data.msg);
           navigate("/Viewadmin");

        } else {
          toast.error(data.msg);
        }

      });
  };



  useEffect(() => {
    userlist();
    hadlerorg()
  }, [])

  return (
    <>

      <ToastContainer />
      <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div className="container-xxl" >
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Edit Admin Profile</h3>
                    </div>
                  </div>
                  <div id="kt_account_settings_profile_details" className="collapse show">
                    <form id="kt_account_profile_details_form" className="form" onSubmit={AddFormData} >
                      <div className="card-body border-top p-9">

                   
                        <div className="row mb-6">

                          <label className="col-lg-4 col-form-label fw-semibold fs-6">First Name</label>
                          <div className="col-lg-8">
                            <div className="row">
                              <div className="col-lg-12 fv-row">
                                <input type="hidden" name="adminId" defaultValue={data?._id} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Full Name" />
                                <input type="text" name="firstName" defaultValue={data?.firstName} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="First Name" />
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="row mb-6">

                          <label className="col-lg-4 col-form-label fw-semibold fs-6">Last Name</label>
                          <div className="col-lg-8">
                            <div className="row">
                              <div className="col-lg-12 fv-row">

                                <input type="text" name="lastName" defaultValue={data?.lastName} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Last Name" />
                              </div>
                            </div>
                          </div>

                        </div>






                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span className="required">Contact No.</span>
                            <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i>
                          </label>
                          <div className="col-lg-8 fv-row">
                            <PhoneInput
                              country={"eg"}
                              enableSearch={true}
                              inputProps={{
                                name: "phone",
                                required: true,

                                 value:phone,

                              }}
                              onChange={(phone) => setPhone(phone)}
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Contact No."
                            />


                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">Email Address</label>
                          <div className="col-lg-8 fv-row">
                            <input type="email" name="email" defaultValue={data?.email} className="form-control form-control-lg form-control-solid" placeholder="Email Address" />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">Address</label>
                          <div className="col-lg-8 fv-row">
                            <input type="text" name="address" defaultValue={data?.address} className="form-control form-control-lg form-control-solid" placeholder="Address" />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">City</label>
                          <div className="col-lg-8 fv-row">
                            <input type="text" name="city" defaultValue={data?.city} className="form-control form-control-lg form-control-solid" placeholder="city" />
                          </div>
                        </div>
                       

                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">

                        <button type="submit" className="btn btn-primary btn-sub me-2">Update</button>


                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  )
}


export default AdminEdit