
import axios from "axios";
import React, { useState, useEffect } from "react";
import Moment from 'moment';
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from 'react-toastify'; 
import Swal from "sweetalert2";
function Blockagentlist({hadlerorg}) {





    const limit = 10;
    let token = localStorage.getItem("organizationToken")
    const [data, setData] = useState([]);
    const [pageCount, setpageCount] = useState("");

    const userlist = async () => {
        await axios.post(`/v1/org/Agent/view-blockedAgent`,{},{headers:{"Authorization":`Bearer ${token}`}}).then((resp) => {
          let data = resp.data.filter;
    

          setData(data);
     
          const total = resp.data.totlaRow;
          const totalPage = Math.ceil(total / limit);
          setpageCount(totalPage);
        }).catch();
      };
      
      useEffect(() => {
        userlist();
        hadlerorg()
      }, []);


      const handlePageClick = async (data) => {
    
        const page = data.selected + 1;
        const commentsFormServer = await fetchComments(page);
      };


      const fetchComments = async (page) => {
        const senData = { page: page };
      
        axios.post(`/v1/org/Agent/view-blockedAgent`, senData,{headers:{"Authorization":`Bearer ${token}`}}).then((resp) => {
          const data = resp.filter;
          setData(data);
        });
        return data;
      };

      const handleunblocked  = (_id) => {
        Swal.fire({
          title: 'Are you sure?',
          text: "Are you sure you want to Un-block selected Agent",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Un-block it!'
        }).then((result) => {
          if (result.isConfirmed) {
            axios.post(`/v1/org/Agent/Unsuspend-agent/${_id}`,{}, {  headers:{"Authorization":`Bearer ${token}`}})
            .then(resp=>{
                if(resp.status){
                    let data = resp.data;
                    Swal.fire(
                      'Un-block!',
                      'Your Agent has been Un-block.',
                      'success'
                    )
                    return userlist();
                }
                
                
            })
          }
        })
        
    
    }


const filterdata  = (e)=>{

    e.preventDefault();

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());

     axios.post(`/v1/org/Agent/view-blockedAgent`,Formvlaues,{headers:{"Authorization":`Bearer ${token}`}}).then((resp) => {
        let data = resp.data.filter;
  
        setData(data);
   
     
      }).catch();


}

  return (
    <div>

    <div
      className="wrapper d-flex flex-column flex-row-fluid"
    
    >
      <div
        className="content d-flex flex-column flex-column-fluid"
       
      >
        <div className="container-xxl" >
          <div className="row g-5 g-xl-8">
            <div className="col-lg-12">
              <div
                className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                style={{ marginTop: "2rem" }}
              >
                <div className="card-body container-xxl pt-10 pb-8">
                  <div className="d-flex align-items-center">
                    <h1 className="fw-semibold me-3 text-white">Filter</h1>
                    <span className="fw-semibold text-white opacity-50">
                    Blocked Agents List
                    </span>
                  </div>

                  <div className="d-flex flex-column">
                    <form onSubmit={filterdata} >
                      <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                        <div className="row flex-grow-1 mb-5 mb-lg-0">
                          <div className="col-lg-6  col-sm-6 d-flex align-items-center mb-3 mb-lg-0">
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-phone"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                              </svg>
                            </span>
                            <input
                              type="text"
                              className="form-control form-control-flush flex-grow-1"
                              name="phone"
                              placeholder="Contact No."
                            />
                          </div>
                          <div className="col-lg-6 col-sm-6 d-flex align-items-center justify-content-end mb-3 mb-lg-0">
                          <div className="min-w-150px text-end">
                          <button
                            type="submit"
                            className="btn btn-dark mx-3"
                            id="kt_advanced_search_button_1"
                          >
                            Search
                          </button>
                          <button type="reset" onClick={()=>userlist()} className="btn btn-secondary" id="kt_advanced_search_button_1">Reset</button>
                        </div>
                            </div>
                        </div>

                      
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="card card-xxl-stretch mb-5 mb-xl-8">
                <div className="card-header border-0 pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">
                    Blocked Agents List
                    </span>
                    <span className="text-muted mt-1 fw-semibold fs-7"></span>
                  </h3>
                </div>

                <div className="card-body py-3">
                  <div className="table-responsive">
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                      <thead>
                        <tr className="fw-bold text-muted th-title">
                          <th className="min-w-125px">Full Name</th>
                          <th className="min-w-125px">Agent Code</th>
                          <th className="min-w-125px">Contact No.</th>
                          <th className="min-w-125px">Email</th>
                          <th className="min-w-125px">City</th>
                          <th className="min-w-150px"> Date of Registration</th>
                          <th className="min-w-125px">Address</th>
                          <th className="min-w-125px text-center">Action</th>
                        
                        </tr>
                      </thead>

                      <tbody>
                        {data.map((item) => (
                          <tr>
                            <td>{item.name} </td>
                            <td>{item.agentCode}</td>
                            <td> {item.phone}</td>
                            <td> {item.email}</td>
                            <td> {item.city}</td>
                            <td>{Moment(item.createdAt).format("DD/MM//YYYY")}</td>
                            <td> {item.address}</td>
                            <td className="d-flex justify-content-center ">
                            <button onClick={(e) => { handleunblocked(item._id);}}
                                                                    className="btn btn-icon btn-danger1 d-flex justify-content-center btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Unblock">
                                                                    <span className="svg-icon svg-icon-3">
                                                                    <img src="/assets_new/images/blocked.png" width="20px" />
                                                                    </span>
                                                                </button></td>
                          
                          
                          
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-lg-12 mt-2 text-end">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination justify-content-end gap-2"}
                      pageClassName={"page-item mx-2"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link pagestyle"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Blockagentlist