import axios from "axios";
import React, { useState, useEffect } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CustomerLoader from "../../../SuperAgent/CRM/Modal/CustomerLoder";
var jwt = require("jsonwebtoken");

function FaceHistoryList({ hadleradmin }) {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [pageCount, setpageCount] = useState("");
  const [menu, setMenu] = useState([]);
  const [total, settotal] = useState([]);
  const [Query, setQuery] = useState("");
  const [ShowLoader, setShowLoader] = useState(true);
  const limit = 10;

  let token = localStorage.getItem("ChromePayAdmitToken");
  var decode1 = jwt.decode(token);
  let adminID = decode1.admminID;

  const AddFormData = async (e, page) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);
    axios
      .post(`/v1/admin/get-face-history`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp?.data?.data;
        setData(data);
        const total = resp.data.totalRows;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };

  const userlist = async () => {
    await axios
      .post(
        `/v1/admin/get-face-history`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp?.data?.data;
        setData(data);
        const total = resp?.data?.totalRows;
        console.log(total);
        settotal(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    userlist();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };
    axios
      .post(`/v1/admin/get-face-history`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp?.data?.data;
        setData(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  const subAdminlist = async () => {
    await axios
      .post(
        `v1/admin/subAdminRole/${adminID}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp?.data?.find;
        setMenu(data.customer);
      });
  };

  useEffect(() => {
    subAdminlist();
    hadleradmin();
  }, []);

  return (
    <>
      <ToastContainer />

      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        Face History
                      </span>
                    </div>

                    <div className="d-inline-flex d-flex flex-column">
                      <div className=" align-lg-items-center">
                        <form onSubmit={(e) => AddFormData(e)}>
                          <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                            <div className="row flex-grow-1 mb-5 mb-lg-0">
                              <div className="col-lg-12 d-flex align-items-center mb-5 mb-lg-0">
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="2"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="2"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <select
                                  className="form-select border-0 flex-grow-1"
                                  data-control="select2"
                                  data-placeholder="Type"
                                  data-hide-search="true"
                                  name="type"
                                >
                                  <option value="" selected disabled>
                                    Type
                                  </option>
                                  <option value="passport">Passport</option>
                                  <option value="kabele">Kabele</option>
                                </select>
                              </div>
                            </div>

                            <div className="min-w-150px d-flex">
                              <button
                                type="submit"
                                className="btn btn-dark"
                                id="kt_advanced_search_button_1"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={userlist}
                                className="btn btn-secondary"
                                id="kt_advanced_search_button_1"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div class="card-header border-0 pt-6 d-flex align-items-center">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Face History List
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        {" "}
                        Over {total} Customer
                      </span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table
                        className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">User Image</th>
                            <th className="min-w-125px">Kabele Image</th>

                            <th className="min-w-150px">Type</th>
                            <th className="min-w-150px">Package Name</th>
                            <th className="min-w-150px"> Date of Creation</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data?.map((item) => (
                            <tr>
                              <td className="text-center">
                                {" "}
                                <div class="d-flex align-items-center">
                                  <div class="symbol symbol-45px me-5">
                                    <img src={item?.userImage} alt="" />
                                  </div>
                                </div>
                              </td>
                              <td className="text-center">
                                {" "}
                                <div class="d-flex align-items-center">
                                  <div class="symbol symbol-45px me-5">
                                    {item?.type == "facematch" ? (
                                      <>
                                        <img
                                          src="https://shadi-app.s3.ap-southeast-2.amazonaws.com/abc/noun-no-image-3378172.png"
                                          alt=""
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img src={item?.kabeleImage} alt="" />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </td>

                              <td className="text-center">{item?.type}</td>

                              <td className="text-center">
                                {item?.packageName}
                              </td>

                              <td className="text-center">
                                {Moment(item?.createdAt).format("DD/MM/YYYY")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaceHistoryList;
