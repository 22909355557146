import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";

const GraphComponent = ({ id }) => {
  const [frequency, setFrequency] = useState("month");
  const [cumulative, setCumulative] = useState("cumulative");

  const [JdcFilter, setJdcFilter] = useState("all"); // Default to saving data
  const [genderFilter, setJdcGender] = useState("");
  const [transmonths, setTransMonths] = useState([]);
  const [transvalue, setTransValue] = useState([]);
  const [fromDate, setFromDate] = useState(""); // State for start date
  const [toDate, setToDate] = useState(""); // State for end date
  const [JdcProfession, setJdcProfession] = useState([]);
  const [ShowLoader, setShowLoader] = useState(true);
  const [jdcUnions, setJdcUnions] = useState([]);
  const [jdcSelectedUnions, setJdcSelectedUnions] = useState("");
  let token = localStorage.getItem("token");
  if (!token) {
    token = localStorage.getItem("token"); //ChromePayAdmitToken
  }

  
  const formatNumber1 = (number) => {
    return new Intl.NumberFormat('en-IN').format(number?.toFixed(2));
  };

  const JDCUnions = () => {
    axios
      .post(
        `/v1/super-Agent/get-unions-for-filters`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let Profession = resp.data?.data;
        console.log({ Profession });
        setJdcUnions(Profession);
      });
  };
  // Fetch data from API
  useEffect(() => {
    setShowLoader(true);
    const fetchData = () => {
      let body = {
        Type: frequency,
        category: JdcFilter,
        gender: genderFilter,
        fromDate: fromDate,
        toDate: toDate,
        agentId: id,
        format: JdcFilter === 'all' ?  'nonCumulative' : cumulative,
        orgId : jdcSelectedUnions
      };
      axios
        .post(`/v1/super-Agent/jdc-new-transaction-graph`, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const userData = res.data;

          console.log("graph data", { userData });

          const savingTransactions = res.data.savingTransactions;
          const loanTransactions = res.data.loanTransactions;
          const shareTransactions = res.data.shareTransactions;
          const storeTransactions = res.data.storeTransactions;

          // Data for chart

          // Conditionally show loan or saving data
          if (JdcFilter === "loan") {
            let loanTransactionsData = loanTransactions.map((item) =>
              item.totalAmount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            );
            setTransValue(loanTransactionsData);
            const months = loanTransactions.map((item) =>`${item?.month || item.value}-${item?.year || ""}`);
            setTransMonths(months);
          } else if (JdcFilter === "share") {
            let shareTransactionsData = shareTransactions.map((item) =>
              item.totalAmount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            );
            setTransValue(shareTransactionsData);
            const months = shareTransactions.map((item) => `${item?.month || item.value}-${item?.year || ""}`);
            setTransMonths(months);
          } else if (JdcFilter === "store") {
            let storeTransactionsData = storeTransactions.map((item) =>
              item.totalAmount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            );
            setTransValue(storeTransactionsData);

            const months = storeTransactions.map((item) => `${item?.month || item.value}-${item?.year || ""}`);
            setTransMonths(months);
          } else if (JdcFilter === "saving") {
            let savingTransactionsData = savingTransactions.map((item) =>
              item.totalAmount.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            );
            setTransValue(savingTransactionsData);
            const months = savingTransactions.map((item) => `${item?.month || item.value}-${item?.year || ""}`);
            setTransMonths(months);
          }
           else {
            if (cumulative === "noncumulative") {
              console.log("test-status",{cumulative})
              let totalAmountsByMonth = {};

              // Function to add totalAmount for each month
              const addTransactionTotals = (transactions) => {
                transactions.forEach(({ month, value,year, totalAmount }) => {
                  if (!totalAmountsByMonth[month]) {
                    totalAmountsByMonth[month] = {
                      monthName: `${month || value}-${year || ""}`,
                      totalAmount: 0,
                    };
                  }
                  totalAmountsByMonth[month].totalAmount += totalAmount;
                });
              };
            
              // Process each category of transactions
              addTransactionTotals(userData?.loanTransactions || []);
              addTransactionTotals(userData?.savingTransactions || []);
              addTransactionTotals(userData?.shareTransactions || []);
              addTransactionTotals(userData?.storeTransactions || []);

              const sortByYearAndMonth = (data) => {
                const entries = Object.entries(data);
                              const sortedEntries = entries.sort(([, a], [, b]) => {
                  const [monthA, yearA] = a.monthName.split("-").map(Number);
                  const [monthB, yearB] = b.monthName.split("-").map(Number);
              
                  if (yearA !== yearB) return yearA - yearB;
                  return monthA - monthB;
                });
              
                const sortedObject = {};
                sortedEntries.forEach(([key, value], index) => {
                  const paddedKey = String(index + 1).padStart(2, "0");
                  sortedObject[paddedKey] = value;
                });
              
                return sortedObject;
              };
             const sortedData = sortByYearAndMonth(totalAmountsByMonth);
             totalAmountsByMonth = sortedData

              // Filter out months with a totalAmount of 0 and prepare data for display
              const filteredMonths = Object.keys(totalAmountsByMonth)
                .filter((month) => totalAmountsByMonth[month].totalAmount > 0) // Exclude months with 0 totalAmount
                .sort((a, b) => a - b) // Sort by month number
                .map((month) => totalAmountsByMonth[month]);
            
              // Format the totalAmount with commas
              const totalAmountsArray = filteredMonths.map((item) =>
                item.totalAmount.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              );
              const months = filteredMonths.map((item) => item.monthName);
            
              console.log("Filtered and sorted data:", {
                months,
                totalAmountsArray,
                totalAmountsByMonth,
              });
            
              // Set state with filtered and formatted data
              setTransValue(totalAmountsArray);
              setTransMonths(months);
            } else if(cumulative === 'cumulative') {

              let totalAmountsByMonth = {};
              const addTransactionTotals = (transactions) => {
                transactions.forEach(({ month, value,year, totalAmount }) => {
                  if (!totalAmountsByMonth[month]) {
                    totalAmountsByMonth[month] = {
                      monthName: `${month || value}-${year || ""}`,
                      totalAmount: 0,
                    };
                  }
                  totalAmountsByMonth[month].totalAmount += totalAmount;
                });
              };


            
            
            // Sort the data

            

              

              addTransactionTotals(userData?.loanTransactions || []);
              addTransactionTotals(userData?.savingTransactions || []);
              addTransactionTotals(userData?.shareTransactions || []);
              addTransactionTotals(userData?.storeTransactions || []);

              const sortByYearAndMonth = (data) => {
                const entries = Object.entries(data);
                              const sortedEntries = entries.sort(([, a], [, b]) => {
                  const [monthA, yearA] = a.monthName.split("-").map(Number);
                  const [monthB, yearB] = b.monthName.split("-").map(Number);
              
                  if (yearA !== yearB) return yearA - yearB;
                  return monthA - monthB;
                });
              
                const sortedObject = {};
                sortedEntries.forEach(([key, value], index) => {
                  const paddedKey = String(index + 1).padStart(2, "0");
                  sortedObject[paddedKey] = value;
                });
              
                return sortedObject;
              };
             const sortedData = sortByYearAndMonth(totalAmountsByMonth);



              console.log("test-final-data",{totalAmountsByMonth, sortedData})
              totalAmountsByMonth = sortedData
              const filteredMonths = Object.keys(totalAmountsByMonth)
                .filter((month) => totalAmountsByMonth[month].totalAmount > 0)
                .sort((a, b) => a - b) 
                .map((month) => totalAmountsByMonth[month]);
            
              const totalAmountsArray = filteredMonths.map((item) => item.totalAmount);
              const months = filteredMonths.map((item) => item.monthName);
            
              // Function to format numbers with commas and two decimal places
              function formatNumber(value) {
                return value.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              }
            
              // Convert to cumulative with formatted numbers
              function convertToCumulative(array) {
                const cumulativeArray = [];
                let cumulativeTotal = 0;
            
                array.forEach((value) => {
                  cumulativeTotal += value;
                  cumulativeArray.push(formatNumber(cumulativeTotal)); // Format cumulative total
                });
            
                return cumulativeArray;
              }
            
              const cumulativeArray = convertToCumulative(totalAmountsArray);
            
              // Format months' total amounts
              const formattedMonths = months.map((month, index) => ({
                month,
                totalAmount: formatNumber(totalAmountsArray[index]),
              }));
            
              console.log("Cumulative Array (Formatted):", cumulativeArray);
              console.log("Formatted Months Data:", formattedMonths);
            
              // Set state with filtered, sorted, and formatted data
              setTransValue(cumulativeArray);
              setTransMonths(months);
            }
            
          }

          setShowLoader(false);
        });
    };

    fetchData();
  }, [frequency, JdcFilter, genderFilter, fromDate, toDate, cumulative, jdcSelectedUnions]);

  
  useEffect(() => {
    JDCUnions()
  }, []);
  // Handle date input changes
  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);

  const handleResetDID = () => {
    setToDate("");
    setFromDate("");
    setJdcGender("");
    setJdcFilter("");
    setFrequency("month");
    setJdcSelectedUnions("")
  };

  return (
    <div className="col-lg-12 mt-5">
      <CustomerLoader loader={ShowLoader} />

      <div className="card card-flush h-xl-100">
        <div className="card-header row pt-7">
          <h3 className="card-title col-md-4 align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">
              Transaction Activity
            </span>
            <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
          </h3>
          <div className="row mb-4 align-items-center">
            <div className="col-md-3">
              <form>
                <div class="row mb-6">
                  <label class="col-lg-4 col-form-label  fw-semibold fs-6">
                    Cumulative
                  </label>
                  <div class="col-lg-8 fv-row">
                    <div class="d-flex align-items-center mt-3">
                      <label class="form-check form-check-inline form-check-solid me-5">
                        <input
                          class="form-check-input"
                          name="format"
                          type="radio"
                          value="cumulative"
                          defaultChecked
                          onChange={(e) => setCumulative(e.target.value)}
                        />
                        <span class="fw-semibold ps-2 fs-6">True</span>
                      </label>

                      <label class="form-check form-check-inline form-check-solid">
                        <input
                          class="form-check-input"
                          name="format"
                          type="radio"
                          value="noncumulative"
                          onChange={(e) => setCumulative(e.target.value)}
                        />
                        <span class="fw-semibold ps-2 fs-6">False</span>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            {/* Frequency Filter */}
            <div className="col-md-3">
              <form>
                <div className="d-flex align-items-center gap-2">
                  <select
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                    className="form-select graph-select shadow-sm"
                  >
                    {/* <option value="day">Day</option> */}
                    <option value="week">Week</option>
                    <option value="month">Monthly</option>
                    <option value="year">Yearly</option>
                  </select>
                </div>
              </form>
            </div>

            <div className="col-md-3">
              <form>
                <div className="d-flex align-items-center gap-2">
                  <select
                    value={jdcSelectedUnions}
                    onChange={(e) => setJdcSelectedUnions(e.target.value)}
                    className="form-select graph-select shadow-sm"
                  >
                    <option selected>Choose any one union</option>

                    {jdcUnions?.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>

            {/* Data Filter (Loan or Saving) */}
            <div className="col-md-3">
              <form>
                <div className="d-flex align-items-center gap-2">
                  <select
                    value={JdcFilter}
                    onChange={(e) => setJdcFilter(e.target.value)}
                    className="form-select graph-select shadow-sm"
                  >
                    <option value="all">All</option>
                    <option value="saving">Saving</option>
                    <option value="loan">Loan</option>
                    <option value="share">Share</option>
                    <option value="store">Store</option>
                  </select>
                </div>
              </form>
            </div>

            {/* Date Range Filter */}
            <div className="rounded d-flex flex-column flex-lg-row mend align-items-lg-center bg-body p-5 h-lg-60px me-lg-10 my-5">
              <div className="row  justify-content-end    flex-grow-1 mb-5 mb-lg-0">
                <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                  <label className="small_label my-label">Start Date</label>
                  <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                    <i className="fad fa-calendar"></i>
                  </span>
                  <input
                    type="date"
                    className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                    value={fromDate}
                    onChange={handleFromDateChange}
                  />
                  <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                </div>

                <div className="col-md-3 d-flex ps-1 align-items-center mb-3 input-container mb-lg-0">
                  <label className="small_label my-label">End Date</label>
                  <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                    <i className="fad fa-calendar"></i>
                  </span>
                  <input
                    type="date"
                    className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                    value={toDate}
                    onChange={handleToDateChange}
                  />
                </div>

                <div className="col-md-2 d-flex align-items-center mb-3 justify-content-start ps-1 input-container mb-lg-0">
                  <button
                    type="reset"
                    onClick={() => handleResetDID()}
                    className="btn btn-light btn-active-light-primary"
                    id="kt_advanced_search_button_1"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Chart Section */}
        <div className="bg-white overflow-auto">
        <Chart
  type="area"
  width={"100%"}
  height={400}
  series={[
    {
      name: JdcFilter === "loan" ? "Loan Amount" : "Saving Amount",
      data: transvalue.map((val) => parseFloat(val?.replace(/,/g, ""))),
    },
  ]}
  options={{
    plotOptions: {
      bar: {
        borderRadius: 2,
        columnWidth: 50,
      },
    },
    markers: {
      size: 0,
    },
    stroke: {
      width: 3,
      curve: "smooth",
    },
    colors: ["#3c7f8c"],
    theme: { mode: "light" },
    xaxis: {
      tickPlacement: "on",
      categories: transmonths,
      title: {
        style: { color: "#f90000", fontSize: 0 },
      },
    },
    yaxis: {
      labels: {
        formatter: (val) => {
          return `ETB ${val.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        },
      },
      title: {
        text: "Amount",
        style: { color: "#3c7f8c", fontSize: 14 },
      },
    },
    legend: {
      show: true,
      position: "right",
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => {
        return `ETB ${val.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
      },
      style: {
        colors: ["#3c7f8c"],
        fontSize: "12px",
      },
    },
    tooltip: {
      shared: true, // Enable shared tooltip for smooth hovering
      intersect: false, // Disable intersect for better control on tooltips
      followCursor: true, // Keep tooltip fixed with the cursor
      y: {
        formatter: (val) => {
          return `ETB ${val.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        },
      },
      x: {
        show: true,
        formatter: (value) => {
          return value; // Format x-axis value if necessary
        },
      },
      marker: {
        show: false, // Disable marker to make the hover cleaner
      },
      style: {
        fontSize: "14px",
      },
    },
  }}
/>


</div>



      </div>
    </div>
  );
};

export default GraphComponent;
