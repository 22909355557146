import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";
import { useSelector } from "react-redux";


function JdcCreatedSubadmin({ hadlerjdc }) {
  const navigate = useNavigate();
  const { authType } = useSelector((state) => state.auth);
  let token = localStorage.getItem("token");
  const [ShowLoader, setShowLoader] = useState(false);

  const AddFormData = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    try {
      const formData = new FormData(e.target);
      const imageFile = formData.get("image");

      const password = formData.get("password");
      const confirmPassword = formData.get("confirmPassword");
      if (password !== confirmPassword) {
        toast.error('Passwords do not match!');
        setShowLoader(false); 
        return;
      }

      if (imageFile) {
        let uploadData = new FormData();
        uploadData.append("file", imageFile);
        const imageUploadResponse = await axios.post(
          `/v1/DID/ImageUploader`,
          uploadData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (imageUploadResponse.data && imageUploadResponse.data.data) {
          const uploadedImageUrl = imageUploadResponse.data.data;
          formData.image= uploadedImageUrl; 
        }
      }


      const response = await axios.post(
        `/v1/super-Agent/add-jdc-admin`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      

      

      if (response.status && response.data) {
        const data = response.data;

        if (data.status) {
          toast.success(data.msg);
          setShowLoader(false);

          navigate(`/${authType}/subadmin-list`);
          e.target.reset(); 
        } else {
          toast.error(data.msg); 
        }
      } else {
        toast.error("Unexpected error occurred. Please try again."); 
      }
    } catch (error) {
      console.error("Error uploading image or submitting form:", error);
      toast.error("Failed to submit the form. Please try again."); 
    }
  };

  useEffect(() => {
    hadlerjdc();
  }, []);

  return (
    <>
      <ToastContainer position="top-right" />
      <CustomerLoader loader={ShowLoader} />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Add Sub-admin</h3>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={(e) => AddFormData(e)}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9">
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Full Name
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="fullName"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="First Name"
                              required
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span className="required">Contact No.</span>
                            <i
                              className="fas fa-exclamation-circle ms-1 fs-7"
                              data-bs-toggle="tooltip"
                              title="Phone number must be active"
                            ></i>
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="tel"
                              name="phone"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Contact No."
                              required
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                            Email Address
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="email"
                              name="email"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Email Address"
                              required
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Address
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="address"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Address"
                              required
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Password
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="password"
                              name="password"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Password"
                              required
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                           Confirm Password
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="password"
                              name="confirmPassword"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Confirm Password"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-light btn-active-light-primary me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JdcCreatedSubadmin;
