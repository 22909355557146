import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Modal from "react-responsive-modal";
import { Button } from "react-bootstrap";
import moment from "moment";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";
var jwt = require("jsonwebtoken");

const SuperAgentReport = ({ hadleradmin }) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [pageCount, setpageCount] = useState("");
  const [orgmenu, setOrgMenu] = useState([]);
  const [open, setOpen] = useState(false);
  const [orgID2, setorgID2] = useState("");
  const [total, settotal] = useState("");
  const [listorg, setlistOrg] = useState([]);
  const [ShowLoader, setShowLoader] = useState(true);

  const limit = 10;
  let token = localStorage.getItem("ChromePayAdmitToken");
  var decode1 = jwt.decode(token);
  let adminID = decode1.admminID;
  const AddFormData = async (e, page) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    axios
      .post(`/v1/admin/super-agent-list`, formData, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((resp) => {
        const data = resp?.data?.data;
        setData(data);
        const total = resp?.data?.totalRow;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };

  const userlist = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/admin/super-agent-list`,
        {},
        { headers: { Authorization: `bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp?.data?.data;
        setData(data);
        const total = resp?.data?.totalRow;
        settotal(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };
  const orgList = async () => {
    await axios.get(`/v1/admin/orgList`).then((resp) => {
      let data = resp?.data?.Org;
      setlistOrg(data);
    });
  };
  useEffect(() => {
    userlist();
    hadleradmin();
    orgList();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };
    axios
      .post(`/v1/admin/super-agent-list`, senData, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((resp) => {
        const data = resp?.data?.data;
        setData(data || []);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  const handleView = (_id) => {
    navigate(`/admin/super-agent-view/${_id}`);
    return false;
  };

  const subAdminlist = async () => {
    await axios
      .post(
        `/v1/admin/subAdminRole/${adminID}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp?.data?.find;

        setOrgMenu(data.Organisation);
      });
  };

  useEffect(() => {
    subAdminlist();
  }, []);

  const handleReport = (_id) => {
    navigate(`/admin/super-agent-perfomance/${_id}`);
  };
  return (
    <div>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content"
            >
              <div className="container-xxl" id="kt_content_container">
                <div className="row g-5 g-xl-8">
                  <div className="col-lg-12">
                    <div className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                      <div className="card-body container-xxl pt-10 pb-8">
                        <div className="d-flex align-items-center">
                          <h1 className="fw-semibold me-3 text-white">
                            Filter
                          </h1>
                          <span className="fw-semibold text-white opacity-50">
                            Super Agent Report List
                          </span>
                        </div>

                        <div className="d-flex flex-column">
                          <div className=" align-lg-items-center">
                            <form onSubmit={(e) => AddFormData(e)}>
                              <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body  me-lg-10 my-5">
                                <div className="row flex-grow-1 mb-5 mb-lg-0 h-lg-60px">
                                  <div className="col-lg-4 d-flex align-items-center mb-3 mb-lg-0">
                                    <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                    <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.5"
                                          x="17.0365"
                                          y="15.1223"
                                          width="8.15546"
                                          height="2"
                                          rx="1"
                                          transform="rotate(45 17.0365 15.1223)"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control form-control-flush flex-grow-1"
                                      name="AgentCode"
                                      placeholder="Enter Agent Code"
                                    />
                                  </div>

                                  <div className="col-lg-4 d-flex align-items-center mb-5 mb-lg-0">
                                    <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                    <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="2"
                                          y="2"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x="13"
                                          y="2"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x="13"
                                          y="13"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x="2"
                                          y="13"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    <select
                                      className="form-select border-0 flex-grow-1"
                                      data-control="select2"
                                      data-placeholder="Organisation Type"
                                      data-hide-search="true"
                                      name="organization"
                                    >
                                      <option value="1">
                                        {" "}
                                        Select Organisation
                                      </option>
                                      {listorg.map((item) => (
                                        <option
                                          key={item?._id}
                                          value={item?._id}
                                        >
                                          {item?.name}
                                        </option>
                                      ))}
                                    </select>
                                    <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                  </div>
                                </div>

                                <div className="min-w-250px text-end d-flex gap-3 justify-content-end me-3">
                                  <button
                                    type="submit"
                                    className="btn btn-dark"
                                    id="kt_advanced_search_button_1"
                                  >
                                    Search
                                  </button>
                                  <button
                                    type="reset"
                                    onClick={userlist}
                                    className="btn btn-secondary"
                                    id="kt_advanced_search_button_1"
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold fs-3 mb-1">
                            Super Agent Report List
                          </span>
                          <span className="text-muted mt-1 fw-semibold fs-7">
                            Over {total} Super Agent{" "}
                          </span>
                        </h3>
                      </div>

                      <div className="card-body py-3">
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bold text-muted th-title">
                                <th className="min-w-125px">Image</th>
                                <th className="min-w-125px">First Name</th>
                                <th className="min-w-125px">Last Name</th>
                                <th className="min-w-150px">Email</th>
                                <th className="min-w-150px">Contact No. </th>
                                <th className="min-w-100px">Agent Code</th>
                                <th className="min-w-100px">Organisation</th>
                                <th className="min-w-100px">Status</th>

                                <th className="min-w-100px text-end">
                                  Actions
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {data.map((item) => (
                                <tr>
                                  <td className="text-center">
                                    <img
                                      src={
                                        item?.Image ||
                                        "/assets/img/male_avtar.png"
                                      }
                                      alt="profile"
                                      width="80px"
                                      height="80px"
                                    />
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    {item?.firstName}{" "}
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    {item?.lastName}{" "}
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    {item?.email}{" "}
                                  </td>
                                  <td className="text-center">
                                    <span>{item?.phone}</span>{" "}
                                  </td>
                                  <td className="text-center">
                                    {item?.AgentCode}
                                  </td>
                                  <td className="text-center">
                                    {item?.org_detail?.name}
                                  </td>

                                  <td className="text-center">
                                    {item?.status == "1" ? (
                                      <>
                                        <span class="capitalize-text badge badge-light-pending fs-5">
                                          Inactive
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span class="badge badge-light-info  fs-5">
                                          Active
                                        </span>
                                      </>
                                    )}
                                  </td>

                                  <td className="text-center">
                                    <div className="d-flex justify-content-center flex-shrink-0">
                                      <button
                                        onClick={(e) => {
                                          handleReport(item?._id);
                                        }}
                                        className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Report"
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          <img
                                            src="/assets_new/images/report.png"
                                            width="35px"
                                          />
                                        </span>
                                      </button>
                                      <button
                                        onClick={(e) => handleView(item?._id)}
                                        className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="View Detail"
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          <i className="fad fa-eye fs-4"></i>
                                        </span>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-lg-12 mt-2 text-end">
                          <ReactPaginate
                            PreviousLabel={"Previous"}
                            NextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item mx-4"}
                            pageLinkClassName={"page-link"}
                            PreviousClassName={"page-item mx-2"}
                            PreviousLinkClassName={"page-link pagestyle"}
                            NextClassName={"page-item"}
                            NextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAgentReport;
