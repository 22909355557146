import React from "react";
import { useState, useEffect, useCallback } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import GraphComponent from "./SuperTransGraphComponent";
import UserGraph from "./SuperUserGraph";

function SuperAgentDashboard({ hadlersuper }) {
  const navigate = useNavigate();
  let token = localStorage.getItem("SuperAgentChromepayToken");
  const [userData, setUserData] = useState([]);
  const [dashData, setData] = useState("");
  const [totalLoans, setTotalLoans] = useState("");
  const [passLoans, setPassLoans] = useState("");
  const [pendingLoans, setPendingLoans] = useState("");
  const [totalUser, setTotalUser] = useState("");
  const [totalAgent, setAgent] = useState("");
  const [useractivedatamonths, setuseractivedatamonths] = useState([]);
  const [useractivedatayear, setuseractivedatayear] = useState([]);
  const [mandatoryAmount, setMandatory] = useState([]);
  const [volantoryAmount, setVolantory] = useState([]);
  const [totalSalesAmount, setTotalSales] = useState([]);
  const [totalLoanAmount, setTotalLoanAmount] = useState([]);
  const [numberOfLoans, setNumberOfLoans] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [frequency, setFrequency] = useState("month");
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);

  const [transvalue, settransvalue] = useState([]);
  const [transmonths, settransmonths] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [JdcProfession, setJdcProfession] = useState([]);
  const [JdcFilter, setJdcFilter] = useState("");
  const [genderFilter, setJdcGender] = useState("");
  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);
  const [loanData, setLoanData] = useState([]);
  const [savingData, setSavingData] = useState([]);
  const handleResetDID = () => {
    setToDate("");
    setFromDate("");
    setJdcGender("");
    setJdcFilter("");
    setFrequency("month");
  };

  useEffect(() => {
    const fetchData = () => {
      let body = {
        Type: frequency,
        category: JdcFilter,
        gender: genderFilter,
        fromDate: fromDate,
        toDate: toDate,
      };
      axios
        .post(`/v1/super-Agent/super_agent_user_graph`, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const userData = res.data.data;
          const loanTransactions = res?.data?.loanTransactions;
          const savingTransactions = res?.data?.savingTransactions;
          const userActivityCounts = userData?.map((item) => item?.count);
          const userActivityMonths = userData?.map((item) => item?.value);

          // Extracting loan and saving data for line charts
          const loanAmounts = loanTransactions?.map(
            (item) => item?.totalAmount
          );
          const savingAmounts = savingTransactions?.map(
            (item) => item?.totalAmount
          );
          const months = userActivityMonths; // Ensure all datasets have the same categories (months)

          settransmonths(months);
          settransvalue(userActivityCounts);

          setuseractivedatayear(userActivityCounts);
          setuseractivedatamonths(months);
          setLoanData(loanAmounts);
          setSavingData(savingAmounts);
        });
    };

    fetchData();
  }, [frequency, JdcFilter, genderFilter, fromDate, toDate]);
  const subAdminlist = async () => {
    await axios
      .post(
        `/v1/super-Agent/get-roles`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.data;
        setRoleData(data);
      });
  };

  const SuperAgentAccessRole = useCallback(
    (role) => {
      const RoleMatch = roleData?.filter(
        (item) => item?.type == role && item?.status
      );

      return RoleMatch[0]?.status;
    },
    [roleData]
  );

  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Update state with the user's location
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setError(null); // Clear any previous errors
        },
        (err) => {
          // Update state with the error message
          setError(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  let AgentDashData = () => {
    axios
      .post(
        "/v1/super-Agent/agent-Dash",
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp?.data;
        let userData = data?.user;
        let totalUser = data?.totalUser;
        let totalLoans = data?.TotalLoans;
        let passLoans = data?.PassLoans;
        let pendingLoans = data?.PendingLoans;
        let noOfAgent = data?.noOfAgent;
        let totalSalesAmount = data?.totalSales;

        setUserData(userData);
        setData(data);
        setTotalLoans(totalLoans);
        setPassLoans(passLoans);
        setPendingLoans(pendingLoans);
        setTotalUser(totalUser);
        setAgent(noOfAgent);
        setTotalSales(totalSalesAmount);
      });
  };

  let getSavingAccounts = () => {
    axios
      .post(
        "/v1/super-Agent/get-saving-Accs",
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let mandatoryAmount = resp?.data?.mandatoryAmount;
        let volantoryAmount = resp?.data?.volantoryAmount;
        let totalLoans = resp?.data?.numberOfLoans;
        let totalLoanAmount = resp?.data?.totalLoanAmount;

        setMandatory(mandatoryAmount);
        setVolantory(volantoryAmount);
        setTotalLoanAmount(totalLoanAmount);
        setNumberOfLoans(totalLoans);
      });
  };

  const handleView = (_id) => {
    navigate(`/Super-Agent-customer-view/${_id}`);
    return false;
  };

  useEffect(() => {
    hadlersuper();
    AgentDashData();
    getSavingAccounts();
    subAdminlist();
  }, [totalUser, totalAgent]);

  const transactionGraph = async (sort) => {
    const body = { Type: frequency };
    await axios
      .post(`/v1/super-Agent/super-agent-graph`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const userData = res.data.data;

        const categories = userData.map((item) => item?.count);

        const users = userData.map((item) => {
          let data = item?.value + item?.year;
          return data;
        });

        setuseractivedatayear(categories);
        setuseractivedatamonths(users);
      });
  };

  useEffect(() => {
    transactionGraph();
  }, [frequency]);

  const handlesorting = (event) => {
    let sort = event.target.value;
    setFrequency(sort);
    transactionGraph(sort);
  };

  const formatNumber = (number) => {
    if (number >= 1e12) {
      return (number / 1e12).toFixed(1) + "T"; // Trillions
    } else if (number >= 1e9) {
      return (number / 1e9).toFixed(1) + "B"; // Billions
    } else if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + "M"; // Millions
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + "k"; // Thousands
    } else {
      return number?.toString(); // Less than a thousand
    }
  };

  return (
    <>
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card card-flush">
                      <div className="bg_div card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px">
                        <h3 className="card-title align-items-start flex-column text-white pt-15">
                          <span className="fw-bold fs-2x mb-3">
                            SUPER AGENT
                          </span>
                          <div className="fs-4 text-white"></div>
                        </h3>
                      </div>

                      <div className="card-body mt-n20">
                        <div className="mt-n20 position-relative dashorgnastion">
                          <div className="row g-3 g-lg-6">
                            <div className="col-md-4">
                              <div className="retative bg-gray-100 ative animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={totalUser}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {totalUser}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Number Of Users
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="retative bg-gray-100  animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fas fa-user-friends"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={totalAgent}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {totalAgent}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Number Of Agents
                                  </span>
                                </div>
                              </div>
                            </div>

                            {SuperAgentAccessRole("loan") && (
                              <>
                                <div className="col-md-4">
                                  <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                    <div className="symbol symbol-30px me-5 mb-8">
                                      <span className="symbol-label">
                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                          <i className="fas fa-id-card"></i>
                                        </span>
                                      </span>
                                    </div>

                                    <div className="m-0">
                                      <span
                                        data-kt-countup="true"
                                        data-kt-countup-value={numberOfLoans}
                                        className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                      >
                                        {numberOfLoans}
                                      </span>
                                      <span className="text-gray-500 fw-semibold fs-6">
                                        Applied Loans
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                    <div className="symbol symbol-30px me-5 mb-8">
                                      <span className="symbol-label">
                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                          <i className="fad fa-hands-usd"></i>
                                        </span>
                                      </span>
                                    </div>

                                    <div className="m-0">
                                      <span
                                        data-kt-countup="true"
                                        data-kt-countup-prefix=""
                                        className="text-gray-700  fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                      >
                                        {formatNumber(totalLoanAmount)}
                                      </span>
                                      <span className="text-gray-500 fw-semibold fs-6">
                                        Total Amount Of Loans (ETB){" "}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {SuperAgentAccessRole("saving") && (
                              <>
                                <div className="col-md-4">
                                  <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                    <div className="symbol symbol-30px me-5 mb-8">
                                      <span className="symbol-label">
                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                          <i className="fad fa-hands-usd"></i>
                                        </span>
                                      </span>
                                    </div>

                                    <div className="m-0">
                                      <span
                                        data-kt-countup-prefix=""
                                        className="text-gray-700  fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                      >
                                        {formatNumber(mandatoryAmount)}
                                      </span>
                                      <span className="text-gray-500 fw-semibold fs-6">
                                        Mandatory Savings (ETB)
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                    <div className="symbol symbol-30px me-5 mb-8">
                                      <span className="symbol-label">
                                        <span className="svg-icon svg-icon-1 svg-icon-primary">
                                          <i className="fad fa-sack-dollar"></i>
                                        </span>
                                      </span>
                                    </div>

                                    <div className="m-0">
                                      <span
                                        data-kt-countup="true"
                                        data-kt-countup-prefix=""
                                        className="text-gray-700  fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                      >
                                        {formatNumber(volantoryAmount)}
                                      </span>
                                      <span className="text-gray-500 fw-semibold fs-6">
                                        Voluntary Savings (ETB)
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {SuperAgentAccessRole("buyingAndSelling") && (
                              <div className="col-md-4">
                                <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                  <div className="symbol symbol-30px me-5 mb-8">
                                    <span className="symbol-label">
                                      <span className="svg-icon svg-icon-1 svg-icon-primary">
                                        <i className="fad fa-sack-dollar"></i>
                                      </span>
                                    </span>
                                  </div>

                                  <div className="m-0">
                                    <span
                                      data-kt-countup="true"
                                      data-kt-countup-value={Math.round(
                                        parseFloat(totalSalesAmount)
                                      )}
                                      data-kt-countup-prefix=""
                                      className="text-gray-700  fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                    >
                                      {formatNumber(totalSalesAmount)}
                                    </span>
                                    <span className="text-gray-500 fw-semibold fs-6">
                                      Total Sales (ETB)
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-5">
                    <div className="card card-flush h-xl-100">
                      <div className="card-header pt-7">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold text-gray-800">
                            User Onboarded Activity
                          </span>
                          <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
                        </h3>
                        <div className="row mb-4 align-items-center">
                          <div className="col-md-4">
                            <form>
                              <div className="d-flex align-items-center gap-2">
                                <select
                                  value={frequency}
                                  onChange={(e) => setFrequency(e.target.value)}
                                  className="form-select graph-select shadow-sm"
                                >
                                  <option value="day">Day</option>
                                  <option value="week">Week</option>
                                  <option value="month">Monthly</option>
                                  <option value="year">Yearly</option>
                                </select>
                              </div>
                            </form>
                          </div>

                          <div className="col-md-4">
                            <form>
                              <div className="d-flex align-items-center gap-2">
                                <select
                                  value={genderFilter}
                                  onChange={(e) => setJdcGender(e.target.value)}
                                  className="form-select graph-select shadow-sm"
                                >
                                  <option selected>
                                    Choose any gender option
                                  </option>

                                  <option key="Male" value="MALE">
                                    Male
                                  </option>

                                  <option key="Female" value="FEMALE">
                                    Female
                                  </option>
                                </select>
                              </div>
                            </form>
                          </div>

                          <div className="rounded d-flex flex-column flex-lg-row mend align-items-lg-center bg-body p-5 h-lg-60px me-lg-10 my-5">
                            <div className="row  justify-content-end  flex-grow-1 mb-5 mb-lg-0">
                              <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                                <label className="small_label my-label">
                                  Start Date
                                </label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                                  <i className="fad fa-calendar"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                                  value={fromDate}
                                  onChange={handleFromDateChange}
                                />
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                              </div>

                              <div className="col-md-3 d-flex ps-1 align-items-center mb-3 input-container mb-lg-0">
                                <label className="small_label my-label">
                                  End Date
                                </label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                                  <i className="fad fa-calendar"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                                  value={toDate}
                                  onChange={handleToDateChange}
                                />
                              </div>
                              <div className="col-md-2 d-flex justify-content-start align-items-center mb-3 ps-1 input-container mb-lg-0">
                                <button
                                  type="reset"
                                  onClick={() => handleResetDID()}
                                  className="btn btn-light btn-active-light-primary"
                                  id="kt_advanced_search_button_1"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="bg-white overflow-auto">
                        <Chart
                          type="area"
                          width={"100%"}
                          height={400}
                          series={[
                            {
                              name: "User",
                              data: transvalue,
                            },
                          ]}
                          options={{
                            plotOptions: {
                              bar: {
                                borderRadius: 2,
                                columnWidth: 50,
                              },
                            },
                            markers: {
                              size: 0,
                            },
                            stroke: {
                              width: 3,
                              curve: "smooth",
                            },

                            colors: ["#3c7f8c"],
                            theme: { mode: "light" },

                            xaxis: {
                              tickPlacement: "on",
                              categories: transmonths,
                              title: {
                                style: { color: "#f90000", fontSize: 0 },
                              },
                            },

                            yaxis: {
                              labels: {
                                formatter: (val) => {
                                  return `${val}`;
                                },
                              },
                              //
                            },

                            legend: {
                              show: true,
                              position: "right",
                            },

                            dataLabels: {
                              formatter: (val) => {
                                return `${val}`;
                              },
                              style: {
                                colors: ["#3c7f8c"],
                                fontSize: 15,
                              },
                            },
                          }}
                        ></Chart>
                      </div>
                    </div>
                  </div>

                  <GraphComponent />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Recent Users
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        {totalUser}
                      </span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">Name</th>
                            <th className="min-w-150px">Email</th>
                            <th className="min-w-150px">Contact No. </th>
                            <th className="min-w-150px">Address </th>
                            <th className="min-w-100px">
                              {" "}
                              Date of Registration
                            </th>
                            <th className="min-w-100px ">Status</th>
                            <th className="min-w-100px">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {userData.map((item) => (
                            <tr>
                              <td>{item?.fullname}</td>
                              <td>{item?.email} </td>
                              <td>
                                <span>{item?.phone} </span>
                              </td>
                              <td>
                                <span>
                                  {item?.address ? item?.address : "N/A"}{" "}
                                </span>
                              </td>
                              <td>
                                {moment(item?.createdAt).format("DD/MM/YYYY")}
                              </td>
                              {item?.status == "pending" ||
                              item?.status == "inactive" ? (
                                <>
                                  <td>
                                    <span className="text-danger">
                                      {item?.status?.charAt(0).toUpperCase() +
                                        item?.status?.slice(1)}
                                    </span>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <span className="text-success">
                                      {item?.status?.charAt(0).toUpperCase() +
                                        item?.status?.slice(1)}
                                    </span>
                                  </td>
                                </>
                              )}

                              <td>
                                <div className="d-flex justify-content-center flex-shrink-0">
                                  <a
                                    onClick={(e) => {
                                      handleView(item?._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuperAgentDashboard;
