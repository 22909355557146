import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import Moment from "moment";

function LoanTypes({ hadlersuper }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [Paymentprocess, setPaymentprocess] = useState(false);

  const focusPoint = useRef(null);
  const [pageCount, setpageCount] = useState("");
  const [typeId, settypeId] = useState(true);
  const [loanType, setLoanType] = useState("");
  const [loanInterestRate, setInterestRate] = useState("");
  const [Blockhandler, setBlockhandler] = useState(false);

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const currentFont = localStorage.getItem("liesenapply");
  let token = localStorage.getItem("SuperAgentChromepayToken");



  const addactive = () => {
    focusPoint.current.classList.toggle("processactive");
  };





  const addactivepayment = () => {
    focusPoint.current.classList.toggle("processactivepayment");
  };


  const AddFormData1 = async (e) => {
    e.preventDefault();

    let data = new FormData(e.target);
    let Formvlaues = Object.fromEntries(data.entries());
    let formData = Formvlaues;
    formData.typeId = typeId;

    axios
      .post(`/v1/super-Agent/update-loans-type-with-interest`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        if (data.status) {
          toast.success(data.msg);
          Licenseslist();
          settypeId(null);
          setShow1(false);
        } else {
          toast.error(data.msg);
        }
      });
  };

  const AddFormData2 = async (e) => {
    e.preventDefault();

    let data = new FormData(e.target);
    let Formvlaues = Object.fromEntries(data.entries());
    let formData = Formvlaues;
    axios
      .post(`/v1/super-Agent/add-loan-types-with-int`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        if (data.status) {
          toast.success(data.msg);
          Licenseslist();

          setShow2(false);
          setShow1(false);
        } else {
          toast.error(data.msg);
        }
      });
  };

  const VerfiyBlock = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const FormValues = Object.fromEntries(data.entries());

    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        FormValues,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setBlockhandler(false);
      let responseData = response.data; 

      if (responseData.status) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${responseData.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        if (typeId) {
          setShow2(true);
        } else {
          setShow1(true);
        }
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${responseData.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        setBlockhandler(false);
      }
    }
  };



  const Paymentprocessbank = async () => {
    await axios
      .post(
        `/v1/org/dash/LicenseProcess`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.status;
        setPaymentprocess(data);
        console.log("+++++++++++++++++++", data);
      });
  };



  useEffect(() => {
    Paymentprocessbank();
    if (currentFont) {
      addactive();
    }
  }, []);
  useEffect(() => {
    if (Paymentprocess) {
      addactivepayment();
    }
  }, [Paymentprocess]);



  useEffect(() => {
    hadlersuper();
  }, []);





  const limit = 5;

  const Licenseslist = async () => {
    await axios
      .post(
        `/v1/super-Agent/get-loans-type-with-interest`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
        const total = resp.data.totalRow;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };
  useEffect(() => {
    Licenseslist();
    hadlersuper();
  }, []);
  const fetchComments = async (page) => {
    const senData = { page: page };
    axios
      .post(`/v1/super-Agent/get-loans-type-with-interest`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };
  return (
    <>
      <div>
        <ToastContainer />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <div
            className="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="container-xxl">
              <div className="row g-5 g-xl-8"></div>
              <div className="col-lg-12">
                <div className="row g-5 g-xl-8">
                  <div className="col-lg-12">
                    <div
                      className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                      style={{ marginTop: "2rem" }}
                    >
                      <div className="card-body container-xxl pt-10 pb-8">
                        <div className="d-flex align-items-center">
                          <h1 className="fw-semibold me-3 text-white">
                            LOAN'S LIST
                          </h1>
                          <span className="fw-semibold text-white opacity-50">
                            {" "}
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div className="d-flex align-item center justify-content-end">
                        <button
                          onClick={(e) => {
                            setBlockhandler(true);
                          }}
                          type="button"
                          className="btn btn-primary ms-3"
                        >
                          Add Loan
                        </button>
                      </div>
                      <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold fs-3 mb-1"></span>
                          <span className="text-muted mt-1 fw-semibold fs-7"></span>
                        </h3>
                      </div>

                      <div className="card-body py-3">
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bold text-muted th-title">
                                <th className="min-w-125px">Loan Icon</th>
                                <th className="min-w-150px">Type</th>
                                <th className="min-w-150px">Interest Rate</th>
                                <th className="min-w-150px"> Last Update</th>
                                <th className="min-w-100px">Action</th>
                       
                              </tr>
                            </thead>

                            <tbody>
                              {data.map((item) => (
                                <tr>
                                  <td>
                                    {" "}
                                    <div className="d-flex align-items-center">
                                      <div className="symbol symbol-45px me-5">
                                        <img
                                          src="https://chromepay.s3.ap-south-1.amazonaws.com/abc/loan-line-icon-png_233984.jpg"
                                          alt=""
                                        />
                                      </div>
                                
                                    </div>
                                  </td>

                                  <td>{item.loanType}</td>
                                  <td>{item.interestRate}</td>
                                  <td>
                                    {Moment(item.updatedAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>


                                  <td>
                                    <button
                                      onClick={(e) => {
                                        setBlockhandler(true);
                                        settypeId(item._id);
                                        setLoanType(item.loanType);
                                        setInterestRate(item.interestRate);
                                      }}
                                      className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                      data-bs-trigger="hover"
                                      title="Update Loan"
                                    >
                                      <span className="svg-icon svg-icon-3">
                                        <i className="fas fa-pencil-alt"></i>
                                      </span>
                                    </button>
                                  </td>
                                </tr>
                              ))}

                            </tbody>
                          </table>
                        </div>

                        <div className="col-lg-12 mt-2 text-end">
                          <ReactPaginate
                            PreviousLabel={"Previous"}
                            NextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            PreviousClassName={"page-item mx-2"}
                            PreviousLinkClassName={"page-link pagestyle"}
                            NextClassName={"page-item"}
                            NextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal-center"
        show={Blockhandler}
        onHide={() => setBlockhandler(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Password</Modal.Title>
        </Modal.Header>
        <form onSubmit={VerfiyBlock}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="row">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  {" "}
                  Password
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                  />
                </div>
              </div>
           
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="center">
              {" "}
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={show1}
        onHide={() => setShow1(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Loan</Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => AddFormData1(e)}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6 mt-3">
                <label className="mb-2">Loan Type</label>
                <input
                  className="form-control"
                  placeholder="Loan Type"
                  type="text"
                  name="loanType"
                  defaultValue={loanType}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mt-3 ">
                <label className=" fs-6">
                  <span className="">Interest Rate</span>
                </label>

                <input
                  type="number"
                  name="interestRate"
                  defaultValue={loanInterestRate}
                  pattern="[0-9]{10}"
                  title=" Only number allow  Ten digits code"
                  required
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Interest Rate"
                  step="any"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow1(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              {" "}
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={show2}
        onHide={() => setShow2(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Loan</Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => AddFormData2(e)}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6 mt-3">
                <label className="mb-2">Loan Type</label>
                <input
                  className="form-control"
                  placeholder="Loan Type"
                  type="text"
                  name="loanType"
                  defaultValue={loanType}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mt-3 ">
                <label className=" fs-6">
                  <span className="">Interest Rate</span>
                </label>

                <input
                  type="number"
                  name="interestRate"
                  defaultValue={loanInterestRate}
                  pattern="[0-9]{10}"
                  title=" Only number allow  Ten digits code"
                  required
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Interest Rate"
                  step="any"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow2(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              {" "}
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

    </>
  );
}

export default LoanTypes;
