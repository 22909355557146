import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import { error } from "jquery";
var jwt = require("jsonwebtoken");

function EmployeeEdit({ hadlerorg }) {
  const { _id } = useParams();
  const navigate = useNavigate();
  let token = localStorage.getItem("organizationToken");

  const [phone, setPhone] = useState("");
  const [data, setData] = useState();

  const userlist = () => {
    const { employeeID } = { employeeID: _id };
    axios
      .post(
        `/v1/org/Agent/view-employee-detail`,
        { employeeID },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.employee_details;
        let number = data.phone;
        let type = data.employee_type;
        setPhone(number);
        setOther(type);
        setData(data);
      });
  };
  const AddFormData = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());

    Object.keys(Formvlaues).forEach((key) => {
      Formvlaues[key] = Formvlaues[key] === "on" ? 1 : Formvlaues[key];
      Formvlaues[key] = Formvlaues[key] === "off" ? 0 : Formvlaues[key];
    });

    axios
      .post(`/v1/org/Agent/update-employee`, Formvlaues, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            toast.success(data.msg);
            navigate("/EmpolyListORG");
            e.target.reset();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      })
      .catch(error);
  };

  const [Other, setOther] = useState("");

  function changeOther(event) {
    setOther(event.target.value);
  }

  useEffect(() => {
    userlist();
    hadlerorg();
  }, []);

  return (
    <>
      <ToastContainer />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Edit Employee Profile</h3>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={(e) => AddFormData(e)}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9">
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            First Name
                          </label>
                          <div className="col-lg-8">
                            <div className="row">
                              <div className="col-lg-12 fv-row">
                                <input
                                  type="hidden"
                                  name="eployeeId"
                                  defaultValue={data?._id}
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder=" First Name"
                                />
                                <input
                                  type="text"
                                  name="first_name"
                                  defaultValue={data?.first_name}
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder=" First Name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            {" "}
                            Last Name
                          </label>
                          <div className="col-lg-8">
                            <div className="row">
                              <div className="col-lg-12 fv-row">
                                <input
                                  type="text"
                                  name="last_name"
                                  defaultValue={data?.last_name}
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span className="">Contact No.</span>
                            <i
                              className="fas fa-exclamation-circle ms-1 fs-7"
                              data-bs-toggle="tooltip"
                              title="Phone number must be active"
                            ></i>
                          </label>
                          <div className="col-lg-8 fv-row">
                            <PhoneInput
                              country={"eg"}
                              enableSearch={true}
                              inputProps={{
                                name: "phone",
                                required: true,
                                value: phone,
                              }}
                              onChange={(phone) => setPhone(phone)}
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Phone number"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Email Address
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="email"
                              name="email"
                              defaultValue={data?.email}
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Email Address"
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Employee Type
                          </label>
                          <div className="col-lg-8 fv-row">
                            <select
                              onChange={changeOther}
                              value={Other}
                              className="form-select border-0 flex-grow-1"
                              data-control="select2"
                              data-placeholder="Please select"
                              data-hide-search="true"
                              name="employee_type"
                            >
                              <option value="" selected disabled>
                                Please select
                              </option>
                              <option value="Agent">Agent</option>
                              <option value="Office Worker">
                                Office Worker{" "}
                              </option>
                              <option value="Loan Collector">
                                Loan Collector
                              </option>
                              <option value="Loan Dealer">Loan Dealer</option>
                              <option value="Cashier">Cashier</option>
                              <option value="DID Manager">DID Manager</option>
                              <option value="other">Other</option>
                            </select>
                            {Other == "other" ? (
                              <>
                                <input
                                  type="text"
                                  name="profession"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Employee Type"
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Is this Employee allowed to create a add
                                customer Network?
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  Checked={
                                    data?.employee_roles?.add_customer || false
                                  }
                                  name="add_customer"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Is this Employee allowed to approve customer?
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="approve_customer"
                                  Checked={
                                    data?.employee_roles?.approve_customer ||
                                    false
                                  }
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Is this Employee allowed to block customer.
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="block_customer"
                                  Checked={
                                    data?.employee_roles?.block_customer ||
                                    false
                                  }
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Is this Employee allowed to delete customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="delete_customer"
                                  Checked={
                                    data?.employee_roles?.delete_customer ||
                                    false
                                  }
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sub me-2"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmployeeEdit;
