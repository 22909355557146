import axios from "axios";
import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";

export default function Agentuserwallet({hadleragent}) {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [pageCount, setpageCount] = useState("");
  const [amount, setAmount] = useState([]);
  const [walletdetails, setwalletdetails] = useState([]);
  const [Transection_limit, setTransection_limit] = useState([]);
  const [data, setData] = useState([]);

  const limit = 10;

  const walletblance = () => {
    const { custID } = { custID: _id };
    console.log("bejdne2dhmdhuiek3hek31hemn3eb3ev1hjesw", custID);
    axios
      .post(`/get_Cust_wallet/${custID}`)
      .then((res) => {
        let data = res.data.find_cust_wallet.customer_ID;
        let data1 = res.data.find_cust_wallet;
        let data2 = res.data;
        console.log(res);
        setAmount(data2);
        setwalletdetails(data);
        setTransection_limit(data1);
      })
      .catch(console.error());
  };

  useEffect(() => {
    walletblance();
    hadleragent();
  }, []);

  const formsave = (e, page) => {
    e.preventDefault();
    const { custID } = { custID: _id };
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log("Formvlaues === ", Formvlaues);
    axios.post(`/latest_transecitons/${custID}`, formData).then((resp) => {
      const data = resp.data.sort;
      setData(data);
      const total = resp.data.totlaRow;
      console.log(total);
      const totalPage = Math.ceil(total / limit);
      setpageCount(totalPage);
    });
  };

  const userlist = async () => {
    const { custID } = { custID: _id };
    await axios.post(`/latest_transecitons/${custID}`).then((resp) => {
      const data = resp.data.sort;
      setData(data);
      const total = resp.data.totlaRow;
      console.log(total);
      const totalPage = Math.ceil(total / limit);
      setpageCount(totalPage);
    });
  };
  useEffect(() => {
    userlist();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const { custID } = { custID: _id };
    const senData = { page: page };
    // const cosole = Fromvalue;
    // console.log(Fromvalue);
    axios.post(`/latest_transecitons/${custID}`, senData).then((resp) => {
      const data = resp.data.sort;
      setData(data);
    });
    return data;
  };

  const handlePageClick = async (data) => {
    // console.log(data.selected);
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
    setData(commentsFormServer);
  };

  const handleView = (_id) => {
  
    navigate( `/latest-transaction/${_id}`);
  
  };

  return (
    <>
    

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="card rounded-15 mb-5 mb-xl-5 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                      <div className="card-body  pt-9 pb-0 ">
                        {/* <!--begin::Details--> */}
                        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                          {/* <!--begin: Pic--> */}
                          <div className="me-7 mb-4">
                            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                              <img src={walletdetails.IDphoto} />
                              <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                            </div>
                          </div>

                          {/* <!--end::Pic--> */}

                          <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                              {/* <!--begin::User--> */}
                              <div className="d-flex flex-column">
                                {/* <!--begin::Name--> */}
                                <div className="d-flex align-items-center mb-2">
                                  <a
                                    href="#"
                                    className="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                                  >
                                    {walletdetails.fullname}
                                  </a>
                                  <a href="#">
                                    {/* <!--begin::Svg Icon | path: icons/duotune/general/gen026.svg--> */}
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24px"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </span>
                                  </a>
                                  {/* <!--  <a href="#" className="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Verified</a> --> */}
                                </div>
                                {/* <!--end::Name--> */}

                                <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                  <a
                                    href="#"
                                    className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                  >
                                    {/* <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg--> */}
                                    <span className="svg-icon svg-icon-4 me-1">
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                          fill="currentColor"
                                        />
                                        <rect
                                          x="7"
                                          y="6"
                                          width="4"
                                          height="4"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {walletdetails.profession}
                                  </a>
                                  <a
                                    href="#"
                                    className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                  >
                                    {/* <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg--> */}
                                    <span className="svg-icon svg-icon-4 me-1">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {walletdetails.nationality}
                                  </a>
                                  <a
                                    href="#"
                                    className="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                                  >
                                    {/* <!--begin::Svg Icon | path: icons/duotune/communication/com011.svg--> */}
                                    <span className="svg-icon svg-icon-4 me-1">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          opacity="0.3"
                                          d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    {walletdetails.email}
                                  </a>
                                </div>
                                {/* <!--end::Info--> */}
                              </div>
                              {/* <!--end::User--> */}
                            </div>

                            <div className="d-flex flex-wrap flex-stack">
                              <div className="d-flex flex-column flex-grow-1 pe-8"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white overflow-auto p-5 card">
                      <Chart
                        type="line"
                        width={500}
                        height={250}
                        series={[
                          {
                            //   name: "Social Media Subscriber",
                            data: [
                              2578, 6787, 3245, 9876, 2324, 5123, 2435, 6594,
                              8956, 4582, 9562, 8569,
                            ],
                          },
                        ]}
                        options={{
                          plotOptions: {
                            bar: {
                              borderRadius: 2,
                              columnWidth: 50,
                            },
                          },

                          stroke: {
                            width: 2,
                            curve: "stepline",
                          },

                          colors: ["#0000ff"],
                          theme: { mode: "light" },

                          xaxis: {
                            tickPlacement: "on",
                            categories: [
                              "Facebook",
                              "Twitter",
                              "Linkedin",
                              "Instagram",
                              "GitHub",
                              "Stackoverflow",
                              "Youtube",
                              "Linkedin",
                              "Instagram",
                              "GitHub",
                              "Stackoverflow",
                              "Youtube",
                            ],
                            title: {
                              style: { color: "#f90000", fontSize: 0 },
                            },
                          },

                          yaxis: {
                            labels: {
                              formatter: (val) => {
                                return `${val}`;
                              },
                            },
                            //
                          },

                          legend: {
                            show: true,
                            position: "right",
                          },

                          dataLabels: {
                            formatter: (val) => {
                              return `${val}`;
                            },
                            style: {
                              colors: ["#f4f4f4"],
                              fontSize: 15,
                            },
                          },
                        }}
                      ></Chart>
                    </div>
                  </div>

                  <div className="col-lg-7">
                    <div className="card card-flush">
                      <div className="bg_div card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px">
                        <h3 className="card-title align-items-start flex-column text-white pt-15">
                          <span className="fw-bold fs-2x mb-3">
                            Your Wallet
                          </span>
                          <div className="fs-4 text-white">
                            {/* <span className="opacity-75">Lorem Ipsum is simply dummy text</span> */}
                          </div>
                        </h3>
                      </div>

                      <div className="card-body mt-n20">
                        <div className="mt-n20 position-relative">
                          <div className="row g-3 g-lg-6">
                            <div className="col-6">
                              <div className="bg-gray-100 ative animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i class="fad fa-wallet fs-2"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="mt-3">
                                  <span className="bank-logo text-center text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-5">
                                    {Transection_limit.current_Amount ?`$${Transection_limit.current_Amount}`:"$0"}
                                  </span>

                                  <span className="text-gray-500 fw-semibold d-block mt-8 fs-6">
                                    Current Amount
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i class="fad fa-wallet fs-2"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="mt-3">
                                  <span className="bank-logo text-center text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-5">
                                    {Transection_limit.Transection_limit ?`$${Transection_limit.Transection_limit}`:"$0"}
                                  </span>

                                  <span className="text-gray-500 fw-semibold d-block mt-8 fs-6">
                                  Transaction Limit
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i class="fad fa-wallet fs-2"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="mt-3">
                                  <span className="bank-logo text-center text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-5">
                                    {amount.sending_amount ? `$${amount.sending_amount}`:"$0"}
                                  </span>

                                  <span className="text-gray-500 fw-semibold d-block mt-8 fs-6">
                                    Sending Amount
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i class="fad fa-wallet fs-2"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="mt-3">
                                  <span className="bank-logo text-center text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-5">
                                    {amount.recived_amount ? `$${amount.recived_amount}`:"$0"}
                                  </span>

                                  <span className="text-gray-500 fw-semibold d-block mt-8 fs-6">
                                  Received Amount
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-5 g-xl-8">
                  <div className="col-lg-12">
                    <div
                      className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                      style={{ marginTop: "2rem" }}
                    >
                      <div className="card-body container-xxl pt-10 pb-8">
                        <div className="d-flex align-items-center">
                          <h1 className="fw-semibold me-3 text-white">
                            Filter
                          </h1>
                          <span className="fw-semibold text-white opacity-50">
                            Customers
                          </span>
                        </div>
                        <form onSubmit={(e) => formsave(e)}>
                          <div className="d-flex flex-column">
                            <div className="d-lg-flex align-lg-items-center">
                              <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                                <div className="row flex-grow-1 mb-5 mb-lg-0">
                                  <div className="col-lg-2 d-flex align-items-center mb-3 mb-lg-0">
                                    <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.5"
                                          x="17.0365"
                                          y="15.1223"
                                          width="8.15546"
                                          height="2"
                                          rx="1"
                                          transform="rotate(45 17.0365 15.1223)"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control form-control-flush flex-grow-1"
                                      name="ID"
                                      placeholder="Enter D-ID"
                                    />
                                  </div>

                                  <div className="col-lg-2 d-flex align-items-center mb-3 mb-lg-0">
                                    <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-phone"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                      </svg>
                                    </span>
                                    <input
                                      type="number"
                                      className="form-control form-control-flush flex-grow-1"
                                      name="phone"
                                      placeholder="Contact No."
                                    />
                                  </div>

                                  <div className="col-lg-2 d-flex align-items-center mb-5 mb-lg-0">
                                    <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                    <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="2"
                                          y="2"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x="13"
                                          y="2"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x="13"
                                          y="13"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x="2"
                                          y="13"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    <select
                                      className="form-select border-0  flex-grow-1"
                                      name="status"
                                      data-control="select2"
                                      data-placeholder="Category"
                                      data-hide-search="true"
                                    >
                                      <option value="" selected disabled>
                                        Status
                                      </option>
                                      <option value="pending">
                                        In Progress
                                      </option>
                                      <option value="verify">Verified</option>
                                    </select>
                                  </div>

                                  <div className="col-lg-2 d-flex align-items-center mb-5 mb-lg-0">
                                    <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                    <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="2"
                                          y="2"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x="13"
                                          y="2"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x="13"
                                          y="13"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                        <rect
                                          opacity="0.3"
                                          x="2"
                                          y="13"
                                          width="9"
                                          height="9"
                                          rx="2"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                    <select
                                      name="nationality"
                                      className="form-select border-0 flex-grow-1"
                                      data-control="select2"
                                      data-placeholder="Category"
                                      data-hide-search="true"
                                    >
                                      <option value="" selected disabled>
                                        Country
                                      </option>
                                      <option value="india">India</option>
                                      <option value="USA">USA</option>
                                      <option value="Uganda">Uganda</option>
                                    </select>
                                  </div>

                                  <div className="col-lg-2 d-flex input-container align-items-center mb-3  mb-lg-0">
                                    <label className="small_label">
                                      Start Date
                                    </label>
                                    <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                      <i className="fad fa-calendar fs-2"></i>
                                    </span>
                                    <input
                                      type="date"
                                      className="form-control unstyled form-control-flush flex-grow-1"
                                      name="fromDate"
                                      placeholder="User Name.."
                                    />
                                    <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                  </div>

                                  <div className="col-lg-2 d-flex align-items-center mb-3 input-container mb-lg-0">
                                    <label className="small_label">
                                      End Date
                                    </label>
                                    <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                      <i className="fad fa-calendar fs-2"></i>
                                    </span>
                                    <input
                                      type="date"
                                      className="form-control unstyled form-control-flush flex-grow-1"
                                      name="toDate"
                                      placeholder="User Name.."
                                    />
                                  </div>
                                </div>

                                <div className="min-w-150px text-end d-flex">
                                  <button
                                    type="submit"
                                    className="btn btn-dark mr-3"
                                    id="kt_advanced_search_button_1"
                                  >
                                    Search
                                  </button>
                                  <button
                                    type="reset"
                                    onClick={userlist}
                                    className="btn btn-secondary"
                                    id="kt_advanced_search_button_1"
                                  >
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold fs-3 mb-1">
                            Transaction List
                          </span>
                          <span className="text-muted mt-1 fw-semibold fs-7"></span>
                        </h3>
                      </div>

                      <div className="card-body py-3">
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bold text-muted th-title">
                                <th className="min-w-125px">Transaction-ID</th>
                                <th className="min-w-125px"> Sender Name</th>
                                <th className="min-w-125px"> Receiving</th>
                                <th className="min-w-150px">Sending Amount</th>
                                <th className="min-w-150px">
                                  Receiving Amount{" "}
                                </th>

                                <th className="min-w-100px">
                                  Date of Creation
                                </th>
                                <th className="min-w-100px">PCN</th>
                                <th className="min-w-100px ">Status</th>
                                <th className="min-w-100px text-end">
                                  Actions
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {data.map((item) => (
                                <tr>
                                  <td>{item.transactionID}</td>
                                  <td>{item.senderName} </td>
                                  <td>{item.recieverName} </td>
                                  <td> {item.sendingAmount}</td>
                                  <td>
                                    <span>{item.receivingAmount}</span>{" "}
                                  </td>

                                  <td>
                                    {moment(item.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>{item.PCN}</td>
                                  <td>
                                    <td>
                                      {item.status == "Confirmed" ? (
                                        <>
                                          <span className="badge badge-light-info fs-5">
                                            {item.status}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="badge badge-light-pending fs-5">
                                            {item.status}
                                          </span>
                                        </>
                                      )}
                                    </td>
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-end flex-shrink-0">
                                      <button
                                        onClick={(e) => {
                                          handleView(item._id);
                                        }}
                                        className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="View Detail"
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          <i className="fad fa-eye fs-4"></i>
                                        </span>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="col-lg-12 mt-2 text-end">
                          <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link pagestyle"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">
                      Created by
                    </span>
                    <a
                      href="#"
                      className="text-muted text-hover-primary fw-semibold me-2 fs-6"
                    >
                      Chromepay
                    </a>
                  </div>

                  <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        About
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
