


import React, { useRef, useState } from "react";
import moment from "moment";
import axios from "axios";
import Jwt from "jsonwebtoken";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Chart from "react-apexcharts";
import CustomerLoader from "../CRM/Modal/CustomerLoder";



export default function SavingPriceUpdated({ hadlersuper }) {

  const navigate = useNavigate();
  let token = localStorage.getItem("SuperAgentChromepayToken");
  const [ShowLoader, setShowLoader] = useState(true);
  const [latestSavingPrice, setPrice] = useState("");

  useEffect(() => {
    hadlersuper();

    latestPrice();
  }, []);

  const SavingPriceUpdated = (e) => {
    e.preventDefault();
    setShowLoader(true);
  
    const data = new FormData(e.target);
    const formValues = Object.fromEntries(data.entries());
  
    if (formValues?.percentage == latestSavingPrice) {
      toast.error('Current percentage and updated percentage are the same. No update made.');
      setShowLoader(false); 
      return;
    }
  
    axios
      .post('/v1/super-Agent/update-saving-share-percentage', formValues, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        if (resp.status) { 
          let data = resp.data;
          if (data.status) {
            toast.success(data.msg);
            e.target.reset();
            latestPrice(); 
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error('Unexpected error occurred.');
        }
      })
      .catch((error) => {
        toast.error('An error occurred while updating the saving percentage.');
        console.error(error); 
      })
      .finally(() => {
        setShowLoader(false); 
      });
  };
  

  const latestPrice = (e) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-share-per`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            setPrice(data.data);
            setShowLoader(false)
            return;
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error("error");
        }
      });
  };





  return (
    <>
      <div>
        <ToastContainer />

        <CustomerLoader loader={ShowLoader}/>
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <div
            className="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="container-xxl">
              <div className="row g-5 g-xl-8">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-xl-10">
                    <div
                      className="card-header border-0 cursor-pointer"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_profile_details"
                      aria-expanded="true"
                      aria-controls="kt_account_profile_details"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0">Saving Information</h3>
                      </div>
                    </div>
                    <div
                      id="kt_account_settings_profile_details"
                      className="collapse show"
                    >
                      <div className="d-flex p-9">
                        <label className="col-lg-2 col-form-label fw-semibold fs-6">
                           Current Saving Share Percentage
                        </label>
                        <div className="col-lg-4 fv-row">
                          <input
                            type="text"
                            name="Licenses"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Current Saving Price"
                            defaultValue={latestSavingPrice}
                            readOnly
                          />
                        </div>
                      </div>
                      <form
                        onSubmit={(e) => SavingPriceUpdated(e)}
                        id="kt_account_profile_details_form"
                        className="form"
                      >
                        <div className="card-body border-top p-9">
                          <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">
                            Update Saving Share Percentage (ETB)
                            </label>

                            <div className="col-lg-4 fv-row">
                              <input
                                type="number"
                                min={1}
                                required
                                name="percentage"
                                className="form-control form-control-lg form-control-solid"
                                placeholder="Update Saving Price"
                              />
                            </div>

                            <div className="col-lg-2 fv-row">
                              <button
                                type="submit"
                                className="btn btn-light btn-sub me-2"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
          
            </div>
          </div>
        </div>
      </div>

    </>
  );
}


