import React from "react";
import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";

var jwt = require("jsonwebtoken");

const Jdccooperative = ({ hadlerjdc }) => {
  const { authType } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  const [ShowLoader, setShowLoader] = useState(true);

  const [custID, setcustID] = useState("");
  const [pageCount, setpageCount] = useState("");
  const [notereason, setnotereason] = useState("");
  const [total, settotal] = useState([]);
  const [unions, setUnions] = useState([]);
  const [Query, setQuery] = useState("");
  const [Delethandler, setDelethandler] = useState(false);
  const [Blockhandler, setBlockhandler] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [paymenthandler, setpaymenthandler] = useState(false);
  const [csvData, setCSVData] = useState([]);

  const limit = 10;

  const formsave = (e, page) => {
    e.preventDefault();
    setShowLoader(true);
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    axios
      .post(`/v1/super-Agent/get_jdc_cooperative_list`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.filter;
        setData(data);
        setCSVData(data);
        const total = resp.data.totalRows;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };

  const userlist = async () => {
    setShowLoader(true);

    await axios
      .post(
        `/v1/super-Agent/get_jdc_cooperative_list`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.filter;
        setData(data);

        const total = resp.data.totalRows;
        const csvData = resp.data.csvData;
        settotal(total);
        setCSVData(csvData);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };
  useEffect(() => {
    userlist();
  }, []);

  const orglist = async () => {
    setShowLoader(true);

    await axios
      .post(
        `/v1/super-Agent/get-unions-for-filters`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.data;
        console.log("test-orgs-unions",{data})
        setUnions(data);
      });
  };
  useEffect(() => {
    orglist();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    setShowLoader(true);

    const senData = { page: page };
  
    axios
      .post(`/v1/super-Agent/get_jdc_cooperative_list`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.filter;
        setData(data);
        setCSVData(data);
        setShowLoader(false);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };





  const handleblocked = () => {
    const { ID } = { ID: custID };
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to Block selected Customer ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Block it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `/v1/org/ActiveDID/Block-cust/${ID}`,
            { note: "Admin Block" },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((res) => {
            if (res.status) {
              Swal.fire(
                "Blocked!",
                "Customer Blocked  successfully",
                "success"
              );
            }
            return userlist();
          });
      }
    });
  };

  const handledelete = () => {
    const { ID } = { ID: custID };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `/v1/org/ActiveDID/Delete-cust/${ID}`,
            {},
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((res) => {
            if (res.status) {
              Swal.fire(
                "Deleted!",
                "Customer deleted successfully.",
                "success"
              );
            }
            return userlist();
          });
      }
    });
  };




  const userpay = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    axios.post(`/Chrome_pay_transection/${custID}`, formData).then((res) => {
      if (res.status) {
        setpaymenthandler(false);
        let data = res.data;
        {
          data.status
            ? Swal.fire({
                position: "center",
                icon: "success",
                title: `${data.msg}`,
                showConfirmButton: false,
                timer: 3500,
              })
            : Swal.fire({
                icon: "error",
                text: `${data.msg}`,
              });
        }
      }
    });
  };




  useEffect(() => {
    hadlerjdc();
  }, []);


  const VerfiyBlock = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    try {
      const response = await axios.post(
        `/v1/org/dash/match-jdc-password`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setBlockhandler(false);
      let data = response.data;
      if (data.status) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });

        handleblocked();

        return userlist();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(error.response.data.message);
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        setBlockhandler(false);
      }
    }
  };





  const VerfiyDelete = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    try {
      const response = await axios.post(
        `/v1/org/dash/match-jdc-password`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setDelethandler(false);
      let data = response.data;
      if (data.status) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        handledelete();

        return userlist();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(error.response.data.message);
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    }
  };


  function handleImageError(e, item) {
    console.log({ item });
    e.target.src =
      "https://chromepaybucket.blr1.digitaloceanspaces.com/DID/images%20%282%29.png";
  }

  const [formData, setFormData] = useState({
    nationality: "",
    organization: "",
    fromDate: "",
    toDate: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData, 
      [name]: value, 
    });
  };

  const resetForm = () => {
    setFormData({
      nationality: "",
      organization: "",
      fromDate: "",
      toDate: "",
    });

    userlist();
  };

  return (
    <>
      <ToastContainer />

      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-5">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        Cooperatives
                      </span>
                    </div>
                    <form onSubmit={formsave}>
                      <div className="d-flex flex-column">
                        <div className="d-lg-flex align-lg-items-center">
                          <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                            <div className="row flex-grow-1 mb-5 mb-lg-0">
                              <div className="col-lg-3 d-flex align-items-center mb-5 mb-lg-0">
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1"></span>
                                <select
                                  name="organization"
                                  className="form-select border-0 flex-grow-1"
                                  value={formData.organization} 
                                  onChange={handleInputChange} 
                                >
                                  <option value="" disabled>
                                    Select Organization
                                  </option>
                                  {unions?.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item?.name}
                                </option>
                              ))}
                                </select>
                              </div>

                              <div className="col-lg-3 col-6 d-flex input-container align-items-center mb-3 mb-lg-0">
                                <label className="small_label">
                                  Start Date
                                </label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <i className="fad fa-calendar fs-2"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush flex-grow-1"
                                  name="fromDate"
                                  value={formData.fromDate} 
                                  onChange={handleInputChange} 
                                />
                              </div>

                              {/* End Date Field */}
                              <div className="col-lg-3 col-6 d-flex input-container align-items-center mb-3 mb-lg-0">
                                <label className="small_label">End Date</label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <i className="fad fa-calendar fs-2"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush flex-grow-1"
                                  name="toDate"
                                  value={formData.toDate} 
                                  onChange={handleInputChange} 
                                />
                              </div>
                            </div>

                            {/* Buttons */}
                            <div className="min-w-150px gap-1 text-end d-flex">
                              <button
                                type="submit"
                                className="btn btn-dark mr-3"
                              >
                                Search
                              </button>
                              <button
                                type="button"
                                onClick={resetForm} 
                                className="btn btn-secondary"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div class="card-header border-0 pt-6 d-flex align-items-center">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        COOPERATIVE LIST
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        {" "}
                        Over {total} Cooperative
                      </span>
                    </h3>
                    <div class="card-title">
                      <div class="d-flex align-items-center position-relative my-1">
                        <span class="svg-icon svg-icon-1 position-absolute ms-6">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="currentColor"
                            ></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>

                        <input
                          type="text"
                          data-kt-customer-table-filter="search"
                          class="form-control form-control-solid w-250px ps-15"
                          placeholder="Search Cooperative"
                          onChange={(e) => setQuery(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="text-end">
                      <CSVLink
                        data={csvData}
                        filename={"table_data.csv"}
                        className="btn btn-success mb-3"
                        target="_blank"
                      >
                        Export Data to CSV
                      </CSVLink>
                    </div>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-300px">ID Photo</th>

                            <th className="min-w-150px">Cooperative Code</th>
                            <th className="min-w-150px">Total Customers</th>
                            <th className="min-w-150px">Organization</th>
                            <th className="min-w-150px"> Date of Creation</th>
                            <th className="min-w-100px">City</th>

                       
                          </tr>
                        </thead>

                        <tbody>
                          {data
                            .filter((user) =>
                              user.firstName.toLowerCase().includes(Query)
                            )
                            .map((item) => (
                              <tr>
                                <td>
                                  {" "}
                                  <div class="d-flex align-items-center">
                                    <div class="symbol symbol-45px me-5">
                                      <img
                                        src={
                                          item?.Image ||
                                          "https://chromepaybucket.blr1.digitaloceanspaces.com/DID/images%20%282%29.png"
                                        }
                                        alt=""
                                        onError={(e) =>
                                          handleImageError(e, item)
                                        }
                                      />
                                    </div>
                                    <div class="d-flex justify-content-start flex-column">
                                      <a
                                        href={`/org-customer-view/${item._id}`}
                                        class="usertitle Acolor mb-2"
                                      >
                                        {" "}
                                        {item.firstName}
                                      </a>
                                      <span class="font-icon mb-1">
                                        <span class="">
                                          <i class="fal fa-phone-volume"></i>
                                        </span>
                                        +{item.phone}
                                      </span>

                                      <span class="font-icon mb-1">
                                        <span class="">
                                          <i class="fal fa-envelope"></i>
                                        </span>
                                        {item.email}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </td>

                                <td>{item.AgentCode}</td>
                                <td>{item?.totalCustomer}</td>
                                <td>{item?.orgData?.name}</td>
                                <td>
                                  {Moment(item.createdAt).format("DD/MM/YYYY")}
                                </td>

                                <td>{item.city}</td>
                                <td>
                                  <div className="d-flex justify-content-center flex-shrink-0">
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                show={paymenthandler}
                onHide={() => setpaymenthandler(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Payment details</Modal.Title>
                </Modal.Header>
                <form onSubmit={userpay}>
                  <Modal.Body>
                    <div className="col-lg-12 ">
                      <div className="row">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          {" "}
                          User Number
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="receiver_phone"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Number"
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Amount
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="amount"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Amount"
                          />
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" type="submit" className="center">
                      {" "}
                      Pay
                    </Button>
                  </Modal.Footer>
                </form>
              </Modal>
              <Modal
                className="modal-center"
                show={Delethandler}
                onHide={() => setDelethandler(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Verify Password</Modal.Title>
                </Modal.Header>
                <form onSubmit={VerfiyDelete}>
                  <Modal.Body>
                    <div className="col-lg-12 ">
                      <div className="row">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          {" "}
                          Password
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="password"
                            name="password"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Enter password"
                          />
                        </div>
                      </div>
                   
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" type="submit" className="center">
                      {" "}
                      Submit
                    </Button>
                  </Modal.Footer>
                </form>
              </Modal>
              <Modal
                className="modal-center"
                show={Blockhandler}
                onHide={() => setBlockhandler(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Verify Password</Modal.Title>
                </Modal.Header>
                <form onSubmit={VerfiyBlock}>
                  <Modal.Body>
                    <div className="col-lg-12 ">
                      <div className="row">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          {" "}
                          Password
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="password"
                            name="password"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Enter password"
                          />
                        </div>
                      </div>
                     </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" type="submit" className="center">
                      {" "}
                      Submit
                    </Button>
                  </Modal.Footer>
                </form>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Jdccooperative;
