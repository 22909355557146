import axios from "axios";
import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import Swal from "sweetalert2";
import { error } from "jquery";
import CustomerLoader from "../SuperAgent/CRM/Modal/CustomerLoder";
var jwt = require("jsonwebtoken");

function OrgnizationBlockList({ hadleradmin }) {
  let token = localStorage.getItem("ChromePayAdmitToken");
  var decode1 = jwt.decode(token);
  let adminID = decode1.admminID;


  const [ShowLoader, setShowLoader] = useState(true);
  const [data, setData] = useState([]);
  const [pageCount, setpageCount] = useState("");
  const [orgmenu, setOrgMenu] = useState([]);

  const limit = 10;

  const userlist = async () => {

    setShowLoader(true);
    await axios
      .post(
        `/v1/admin/blockedOrglist`,
        {},
        { headers: { Authorization: `bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp?.data?.data;
        setData(data);
        const total = resp?.data?.totlaRow;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      })
      .catch(error);
  };
  useEffect(() => {
    userlist();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };

    axios
      .post(`/v1/admin/blockedOrglist`, senData, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((resp) => {
        const data = resp?.data?.data;
        setData(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  /////////////////delete api call /////////////////
  const handledelete = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to Unblocked selected Customer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Unblocked it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const { OrganisationID } = { OrganisationID: _id };
        console.log(OrganisationID);
        axios
          .put(
            `v1/admin/unsuspend/${OrganisationID}`,
            {},
            { headers: { Authorization: `bearer ${token}` } }
          )
          .then((res) => {
            if (res.status) {
              let data = res.data;
              if (data.status) {
                Swal.fire(
                  "Unblocked!",
                  "Customer Unblocked successfully",
                  "success"
                );
                return userlist();
              } else {
                toast.error(data.msg);
              }
            } else {
              toast.error(data.msg);
            }
          });
      }
    });
  };

  const subAdminlist = async () => {
    await axios
      .post(
        `/v1/admin/subAdminRole/${adminID}`,
        {},
        { headers: { Authorization: `bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp?.data?.find;
    
        setOrgMenu(data?.Organisation);
    
      });
  };

  useEffect(() => {
    subAdminlist();
    hadleradmin();
  }, []);

  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">
                        Blocked Organisation
                      </h1>
                  
                    </div>

                                    </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Organisation List
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">D-ID</th>
                            <th className="min-w-125px">Name</th>
                            <th className="min-w-150px">Email</th>
                            <th className="min-w-150px">Mobile No. </th>

                            <th className="min-w-100px text-center">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data?.map((item) => (
                            <tr>
                              <td>{item?._id}</td>
                              <td> {item?.name} </td>
                              <td> {item?.email} </td>
                              <td>
                                <span>{item?.phoneNo}</span>{" "}
                              </td>

                              <td>
                               <div className="d-flex justify-content-center flex-shrink-0">
                                  {orgmenu?.unBlockOrganisation == 1 ? (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handledelete(item?._id);
                                        }}
                                        className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Unblock"
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          <img
                                            src="/assets_new/images/suspand.png"
                                            width="20px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item mx-2"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrgnizationBlockList;
