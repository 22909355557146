import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import Header from "./Header";
// import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import moment from "moment";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";
import OrgAllNameModal from "../ice-management/OrgAllNameModal/OrgAllNameModal";
import JdcModal from "./jdcModal/jdcModal";

var jwt = require("jsonwebtoken");

const JdcManagement = ({ hadleradmin }) => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [orgmenu, setOrgMenu] = useState([]);
    const [open, setOpen] = useState(false);
    const [OrgModalShow, setOrgModalShow] = useState(false);
    const [orgID2, setorgID2] = useState("");
    const [total, settotal] = useState("");
    const [ShowLoader, setShowLoader] = useState(true);
    const limit = 10;
    const [OrgInfo, setOrgInfo] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    let token = localStorage.getItem("ChromePayAdmitToken");
    var decode1 = jwt.decode(token);
    let adminID = decode1.admminID;

    const AddFormData = async (e, page) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const Formvlaues = Object.fromEntries(data.entries());
        const formData = Formvlaues;
        axios
            .post(`/v1/admin/list-org-jdc-ice`, formData, {
                headers: { Authorization: `bearer ${token}` },
            })
            .then((resp) => {
                const data = resp?.data?.data;
                setData(data.data);
        
                const total = resp.data.totlaRow;
                const totalPage = Math.ceil(total / limit);
                // setpageCount(totalPage);
            });
    };

    const userlist = async () => {
        setShowLoader(true);
        await axios
            .post(
                `/v1/admin/list-org-jdc-ice`,
                { type: "jdc" },
                { headers: { Authorization: `bearer ${token}` } }
            )
            .then((resp) => {
                const data = resp?.data?.data;
                const total = resp?.data?.totlaRow;
                setData(data);
       
                settotal(total);
                const totalPage = Math.ceil(total / limit);
                // setpageCount(totalPage);
                setShowLoader(false);
            });
    };

    useEffect(() => {
        userlist();
        hadleradmin();
    }, []);



    ///////////////pagenestion///////////////

    const fetchComments = async (page) => {
        const senData = { page: page };
        setShowLoader(true);
        axios
            .post(`/v1/admin/list-org-jdc-ice`, senData, {
                headers: { Authorization: `bearer ${token}` },
            })
            .then((resp) => {
                const data = resp?.data?.data;
                const total = resp?.data?.totlaRow;
                setData(data);
        
                settotal(total);
                const totalPage = Math.ceil(total / limit);
                setShowLoader(false);
            });
        return data;
    };




    const handlesuspend = (_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to Suspend selected Organisation ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Suspend it!",

        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`/v1/admin/suspend/${_id}`).then((res) => {
                    if (res.status) {
                        let data = res.data;
                        if (data.status) {
                            Swal.fire(
                                "Suspend!",
                                "Organisation Suspend successfully",
                                "success"
                            );
                            return userlist();
                        } else {
                            toast.error(data.msg);
                        }
                    } else {
                        toast.error(data.msg);
                    }
                });
            }
        });
    };

  

    const handledelete = (_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to delete selected Organisation ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",

        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`v1/admin/remove-org-jdc-ice`,{id:_id,type:"jdc"}).then((res) => {
                    if (res.status) {
                        let data = res.data;
                        if (data.status) {
                            Swal.fire(
                                "Deleted!",
                                "Organisation deleted successfully",
                                "success"
                            );
                            return userlist();
                        } else {
                            toast.error(data.msg);
                        }
                    } else {
                        toast.error(data.msg);
                    }
                });
            }
        });
    };

    const subAdminlist = async () => {
        await axios
            .post(
                `/v1/admin/subAdminRole/${adminID}`,
                {},
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((resp) => {
                let data = resp?.data?.find;
          
                setOrgMenu(data.Organisation);
           
            });
    };

    useEffect(() => {
        subAdminlist();
    }, []);



    return (
        <div>
            <ToastContainer />
            <CustomerLoader loader={ShowLoader} />
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <div
                        className="wrapper d-flex flex-column flex-row-fluid"
                        id="kt_wrapper"
                    >
                        <div
                            className="content d-flex flex-column flex-column-fluid"
                            id="kt_content">
                            <div className="container-xxl" id="kt_content_container">
                                <div className="row g-5 g-xl-8">
                                    <div className="col-lg-12">
                                        <div className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                                            <div className="card-body container-xxl pt-10 pb-8">
                                                <div className="d-flex align-items-center">
                                                    <h1 className="fw-semibold me-3 text-white">
                                                        JDC Management
                                                    </h1>
                                                    <span className="fw-semibold text-white opacity-50">
                                                        Organisation List
                                                    </span>
                                                </div>

                                                <div className="d-flex flex-column">
                                                    <div className=" align-lg-items-center">
                                                        <form onSubmit={(e) => AddFormData(e)}>
                                                            <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body  me-lg-10 my-5">
                                                                <div className="row flex-grow-1 mb-5 mb-lg-0 h-lg-60px">
                                                                    <div className="col-lg-3 d-flex align-items-center mb-5 mb-lg-0">
                                                                        <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                            <svg
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <rect
                                                                                    x="2"
                                                                                    y="2"
                                                                                    width="9"
                                                                                    height="9"
                                                                                    rx="2"
                                                                                    fill="currentColor"
                                                                                />
                                                                                <rect
                                                                                    opacity="0.3"
                                                                                    x="13"
                                                                                    y="2"
                                                                                    width="9"
                                                                                    height="9"
                                                                                    rx="2"
                                                                                    fill="currentColor"
                                                                                />
                                                                                <rect
                                                                                    opacity="0.3"
                                                                                    x="13"
                                                                                    y="13"
                                                                                    width="9"
                                                                                    height="9"
                                                                                    rx="2"
                                                                                    fill="currentColor"
                                                                                />
                                                                                <rect
                                                                                    opacity="0.3"
                                                                                    x="2"
                                                                                    y="13"
                                                                                    width="9"
                                                                                    height="9"
                                                                                    rx="2"
                                                                                    fill="currentColor"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <select
                                                                            className="form-select border-0 flex-grow-1"
                                                                            data-control="select2"
                                                                            data-placeholder="Status"
                                                                            data-hide-search="true"
                                                                            name="status"
                                                                        >
                                                                            <option value=""></option>
                                                                            <option value="1" selected="selected">
                                                                                Category
                                                                            </option>
                                                                            <option value="pending">Pending</option>
                                                                            <option value="Confirmed">
                                                                                Confirmed
                                                                            </option>
                                                                            <option value="verified">verified</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-lg-3 d-flex align-items-center mb-5 mb-lg-0">
                                                                        <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                            <svg
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                            >
                                                                                <rect
                                                                                    x="2"
                                                                                    y="2"
                                                                                    width="9"
                                                                                    height="9"
                                                                                    rx="2"
                                                                                    fill="currentColor"
                                                                                />
                                                                                <rect
                                                                                    opacity="0.3"
                                                                                    x="13"
                                                                                    y="2"
                                                                                    width="9"
                                                                                    height="9"
                                                                                    rx="2"
                                                                                    fill="currentColor"
                                                                                />
                                                                                <rect
                                                                                    opacity="0.3"
                                                                                    x="13"
                                                                                    y="13"
                                                                                    width="9"
                                                                                    height="9"
                                                                                    rx="2"
                                                                                    fill="currentColor"
                                                                                />
                                                                                <rect
                                                                                    opacity="0.3"
                                                                                    x="2"
                                                                                    y="13"
                                                                                    width="9"
                                                                                    height="9"
                                                                                    rx="2"
                                                                                    fill="currentColor"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                        <select
                                                                            className="form-select border-0 flex-grow-1"
                                                                            data-control="select2"
                                                                            data-placeholder="Organisation Type"
                                                                            data-hide-search="true"
                                                                            name="orgType"
                                                                        >
                                                                            <option value="1">
                                                                                Organisation Type
                                                                            </option>
                                                                            <option value="Cooperative">
                                                                                Cooperative
                                                                            </option>
                                                                            <option value="Union">Union</option>
                                                                            <option value="Microfinance Institution">
                                                                                Microfinance Institution
                                                                            </option>
                                                                            <option value="Bank">Bank</option>
                                                                            <option value="Fintech/E-Wallet">
                                                                                {" "}
                                                                                Fintech/E-Wallet
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="col-lg-3 d-flex input-container align-items-center mb-3  mb-lg-0">
                                                                        <label className="small_label">
                                                                            Start Date
                                                                        </label>
                                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                            <i className="fad fa-calendar fs-2"></i>
                                                                        </span>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control unstyled form-control-flush flex-grow-1"
                                                                            name="fromDate"
                                                                            placeholder="User Name.."
                                                                        />
                                                                        <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                    </div>

                                                                    <div className="col-lg-3 d-flex align-items-center mb-3 input-container mb-lg-0">
                                                                        <label className="small_label">
                                                                            End Date
                                                                        </label>
                                                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                            <i className="fad fa-calendar fs-2"></i>
                                                                        </span>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control unstyled form-control-flush flex-grow-1"
                                                                            name="toDate"
                                                                            placeholder="User Name.."
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="min-w-250px text-center d-flex justify-content-end gap-3 me-3">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn btn-dark"
                                                                        id="kt_advanced_search_button_1"
                                                                    >
                                                                        Search
                                                                    </button>
                                                                    <button
                                                                        type="reset"
                                                                        onClick={userlist}
                                                                        className="btn btn-secondary"
                                                                        id="kt_advanced_search_button_1"
                                                                    >
                                                                        Reset
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">

                                        <div className="card card-xxl-stretch mb-5 mb-xl-8">
                        
                                            <div className="card-header border-0 pt-5 w-100 ">

                                                <h3 className="card-title align-items-start flex-column justify-content-between">
                                                    <span className="card-label fw-bold fs-3 mb-1">
                                                        <p> Organisation List</p>

                                                        <span className="text-muted mt-1 fw-semibold fs-7">
                                                            Over {total} Organisation{" "}
                                                        </span>
                                                    </span>
                                                </h3>

                                                <span> <button className="btn btn-primary " type="button" onClick={() => setOrgModalShow(true)}>Add Organization</button></span>

                                            </div>

                                            <div className="card-body py-3">
                                                <div className="table-responsive">
                                                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">

                                                        <thead>
                                                            <tr className="fw-bold text-muted th-title">
                                                                <th className="min-w-100px">Image</th>
                                                                <th className="min-w-150px">Code</th>
                                                                <th className="min-w-150px">Organisation </th>
                                                                <th className="min-w-150px">
                                                                    Organisation Type{" "}
                                                                </th>

                                                                <th className="min-w-150px">Country</th>
            
                                                                <th className="min-w-150px">
                                                                    Date of Registration{" "}
                                                                </th>

                                                                <th className="min-w-150px ">Status</th>
                                                                <th className="min-w-150px text-center">Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {data?.map((item) => (
                                                                <tr>
                                                                    <td className="text-center">
                                                                        <img
                                                                            src={
                                                                                item?.organizations?.logo ||
                                                                                "/assets_new/images/origination.png"
                                                                            }
                                                                            width="80px"
                                                                        />
                                                                    </td>
                                                                    

                                                                    <td className="text-center">{item?.organizations.code}</td>
                                                                    <td className="text-center">{item?.organizations.name}</td>
                                                                    <td className="text-center">{item?.organizations.orgType}</td>
                                                                    <td className="text-center">{item?.organizations.country}</td>

                                                                    <td className="text-center">
                                                                        {moment(item?.organizations?.createdAt).format(
                                                                            "DD/MM/YYYY"
                                                                        )}
                                                                    </td>


                                                                    <td className="text-center">
                                                                        {item?.organizations?.status == "pending" ?(
                                                                            <>
                                                                                <span className="badge badge-light-pending text-danger fs-5">

                                                                                    {item?.organizations?.status
                                                                                        ?.charAt(0)
                                                                                        ?.toUpperCase() +
                                                                                        item?.organizations?.status?.slice(1)}
                                                                                </span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <span className="badge badge-light-info text-success fs-5">
                                                                                    {item?.organizations?.status
                                                                                        ?.charAt(0)
                                                                                        .toUpperCase() +
                                                                                        item?.organizations?.status?.slice(1)}
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </td>

                                                                    <td className="text-center">
                                                                        <div className="d-flex justify-content-center flex-shrink-0">
                                                                            {orgmenu.blockOrganisation == 1 ? (
                                                                                <>
                                                                                    <button
                                                                                        onClick={(e) => {
                                                                                            handlesuspend(item._id);
                                                                                        }}
                                                                                        className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                                                        data-bs-toggle="tooltip"
                                                                                        data-bs-placement="top"
                                                                                        data-bs-title="Password Reset "
                                                                                        data-bs-trigger="hover"
                                                                                        title="Suspend "
                                                                                    >
                                                                                        <span className="svg-icon svg-icon-3">
                                                                                            <img
                                                                                                src="/assets_new/images/blocked.png"
                                                                                                width="20px"
                                                                                            />
                                                                                        </span>
                                                                                    </button>
                                                                                </>
                                                                            ) : (
                                                                                ""
                                                                            )}

                                                                            {orgmenu?.deleteOrganisation == 1 ? (
                                                                                <>
                                                                                    <button
                                                                                        onClick={(e) => {
                                                                                            handledelete(item?._id);
                                                                                        }}
                                                                                        className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                                                        data-bs-toggle="tooltip"
                                                                                        data-bs-placement="top"
                                                                                        data-bs-title="Password Reset "
                                                                                        data-bs-trigger="hover"
                                                                                        title="Delete "
                                                                                    >
                                                                                        <span className="svg-icon svg-icon-3">
                                                                                        
                                                                                            <img
                                                                                                src="/assets_new/images/cross.png"
                                                                                                width="20px"
                                                                                            />

                                                                                        </span>
                                                                                    </button>
                                                                                </>

                                                                            ) : (
                                                                                ""
                                                                            )}

                                                                            <Link
                                                                                to={`/Add_admin_document/${item._id}`}
                                                                            >
                                                                            </Link>

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        
                    </div>
                </div>
            </div>

            <JdcModal open={OrgModalShow} setOpen={setOrgModalShow} handlereload={userlist} />

        </div>

    );
};

export default JdcManagement
