import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import CustomerLoader from "./Modal/CustomerLoder";

import CustomerLoader from "../CRM/Modal/CustomerLoder";

import { useLocation, useNavigate, useParams } from "react-router-dom";

var jwt = require("jsonwebtoken");

export default function BulkUploadDataDetails({ hadlersuper }) {
    let token = localStorage.getItem("SuperAgentChromepayToken");
    let orgType = localStorage.getItem("orgType");
    var decode1 = jwt.decode(token);
    let orgID = decode1.OrganisationID;
    const navigate = useNavigate();
    const [custID, setcustID] = useState("");
    const [pageCount, setpageCount] = useState("");
    const [notereason, setnotereason] = useState("");
    const [total, settotal] = useState([]);
    const [Query, setQuery] = useState("");
    const csvId = useParams();
    let ID = localStorage.getItem("ID");
    const [data, setData] = useState({});
    const [paymenthandler, setpaymenthandler] = useState(false);
    const [ShowLoader, setShowLoader] = useState(true);
    const limit = 10;

    const userlist = async () => {
        setShowLoader(true);
        await axios
            .post(
                `/v1/super-Agent/get-Upload-Details`,
                { id: csvId?.id },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((resp) => {
                if (resp?.data?.status) {
                    const data = resp?.data;
                    console.log(data);
                    setData(data);
                    const total = resp?.data?.totalSuccessRecords;
                    settotal(total);
                    const totalPage = Math.ceil(total / limit);
                    setpageCount(totalPage);
                    setShowLoader(false);
                }
            });
    };

    const handleUpdateCout = (total) => {
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
    }

    useEffect(() => {
        userlist();
    }, []);

    ///////////////pagenestion///////////////
    const fetchComments = async (page) => {
        const senData = { page: page };

        axios
            .post(
                `/v1/super-Agent/get-Upload-Details`,
                {
                    id: csvId?.id,
                    limit: 10,
                    page: page
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((resp) => {
                const data = resp.data;
                setData(data);
            });
        return data;
    };

    const handlePageClick = async (data) => {
        const page = data.selected + 1;
        const commentsFormServer = await fetchComments(page);
    };

    const userpay = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const Formvlaues = Object.fromEntries(data.entries());
        const formData = Formvlaues;

        axios.post(`/Chrome_pay_transection/${custID}`, formData).then((res) => {
            if (res.status) {
                setpaymenthandler(false);
                let data = res.data;
                {
                    data.status
                        ? Swal.fire({
                            position: "center",
                            icon: "success",
                            title: `${data.msg}`,
                            showConfirmButton: false,
                            timer: 3500,
                        })
                        : Swal.fire({
                            icon: "error",
                            text: `${data.msg}`,
                        });
                }
            }
        });
    };
    const handleView = (_id) => {
        return false;
    };

    useEffect(() => {
        hadlersuper();
    }, []);


    ///new code
    let selectedFile = null;
    const handleFileChange = (event) => {
        selectedFile = event.target.files[0]; // Store the selected file
    };
    const handleSubmit = () => {
        if (!selectedFile) {
            // alert("Please select a file to upload.");
            Swal.fire("Please select", "Please select a file to upload.", "warning");
            return;
        }
        uploadCsvFile(selectedFile);
    };

    const uploadCsvFile = async (file) => {
        // Check if the selected file is a CSV
        if (!file || !file.name.endsWith(".csv")) {
            Swal.fire("Invalid File", "Please upload a valid CSV file.", "error");
            return;
        }
        setShowLoader(true);
        const formData = new FormData();
        formData.append("file", file);
        try {
            const response = await axios.post("/v1/super-Agent/csv-upload", formData, {
                headers: {
                    Authorization: `Bearer ${token}`, // Replace with your token variable
                    "Content-Type": "multipart/form-data",
                },
            }
            );
            setShowLoader(false);
            Swal.fire("Success", "CSV file uploaded successfully!", "success");
            userlist();
        } catch (error) {
            setShowLoader(false);
            Swal.fire(
                "Upload Failed",
                error.response.data.message || "An error occurred during the upload.",
                "error"
            );
        }
    };



    return (
        <>
            <CustomerLoader loader={ShowLoader} />
            <ToastContainer />
            <div className="wrapper d-flex flex-column flex-row-fluid">
                <div className="content d-flex flex-column flex-column-fluid">
                    <div className="container-xxl">
                        <div className="row g-5 g-xl-8">
                            <div className="col-lg-12">

                                <div
                                    className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                                    style={{ marginTop: "2rem" }}
                                >

                                    <div className="card-body container-xxl pt-10 pb-8">
                                        <div className="d-flex align-items-center">
                                            <h1 className="fw-semibold me-3 text-white">Bulk Upload Details</h1>
                                        </div>
                                        <ul
                                            className="mt-3 nav flex-wrap border-transparent fw-bold"
                                            role="tablist"
                                        >
                                            <li className="nav-item my-1" role="presentation">
                                                <a  onClick={() => handleUpdateCout(data?.totalSuccessRecords)}
                                                    role="tab"
                                                    aria-selected="true"
                                                    data-bs-toggle="tab"
                                                    href="#SUCCESS"
                                                    className="btn btn-color-gray-600 btn-active-secondary 
                                                    active btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                                                >
                                                    Success List
                                                </a>
                                            </li>

                                            <li className="nav-item my-1" role="presentation">
                                                <a
                                                   onClick={() => handleUpdateCout(data?.totalFailedRecords)}
                                                    role="tab"
                                                    aria-selected="true"
                                                    data-bs-toggle="tab"
                                                    href="#Failed"
                                                    className="btn btn-color-gray-600 btn-active-secondary 
                                                    btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase"
                                                >
                                                    Failed List
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div
                                    className="tab-pane fade active show"
                                    id="SUCCESS"
                                    role="tabpanel"
                                >
                                    <div className="col-lg-12">
                                        <div className="card card-xxl-stretch mb-5 mb-xl-8">
                                            <div class="card-header border-0 pt-6 d-flex align-items-center">
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fw-bold fs-3 mb-1">
                                                        Success List
                                                    </span>
                                                    <span className="text-muted mt-1 fw-semibold fs-7">
                                                        {" "}
                                                        Over {data?.totalSuccessRecords} Success List
                                                    </span>
                                                </h3>
                                                <div class="card-title"></div>
                                            </div>

                                            <div className="card-body py-3">
                                                <div className="table-responsive">
                                                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                                        <thead>
                                                            <tr className="fw-bold text-muted th-title">
                                                                <th className="text-center" style={{ paddingRight: '1em' }}>Sr.No.</th>
                                                                <th></th>
                                                                <th className="min-w-150px">
                                                                    Bruh IDs Ref No.
                                                                </th>
                                                                <th>
                                                                    Date of Registration
                                                                </th>
                                                                <th className="min-w-100px text-center">Action</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {data?.successData?.map((item, index) => (
                                                                <tr>
                                                                    <td className="text-center" style={{ paddingRight: '1em' }}>{index + 1}</td>
                                                                    <td>
                                                                        {" "}
                                                                        <div class="d-flex align-items-center">
                                                                            <div class="symbol symbol-45px me-5">
                                                                                <img src={item?.IDphoto} alt="" />
                                                                            </div>
                                                                            <div class="d-flex justify-content-start flex-column">
                                                                                <a class="usertitle Acolor mb-2">
                                                                                    {" "}
                                                                                    {item.fullname}
                                                                                </a>
                                                                                <span class="font-icon mb-1">
                                                                                    <span class="">
                                                                                        <i class="fal fa-phone-volume"></i>
                                                                                    </span>
                                                                                    +{item.phone}
                                                                                </span>

                                                                                <span class="font-icon mb-1">
                                                                                    <span class="">
                                                                                        <i class="fal fa-envelope"></i>
                                                                                    </span>
                                                                                    {item.email}{" "}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{item.digitalrefID}</td>
                                                                    <td>
                                                                        {Moment(item.createdAt).format("DD/MM//YYYY")}
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <button
                                                                            type="button"
                                                                            onClick={() =>
                                                                                navigate(
                                                                                    `/Super-Agent-customer-view/${item._id}`
                                                                                )
                                                                            }
                                                                            className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            data-bs-trigger="hover"
                                                                            title="View Detail"
                                                                        >
                                                                            <span className="svg-icon svg-icon-3">
                                                                                <i className="fad fa-eye fs-4"></i>
                                                                            </span>
                                                                        </button>
                                                                    </td>


                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="col-lg-12 mt-2 text-end">
                                                    <ReactPaginate
                                                        PreviousLabel={"Previous"}
                                                        NextLabel={"Next"}
                                                        breakLabel={"..."}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={1}
                                                        pageRangeDisplayed={6}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={
                                                            "pagination justify-content-end gap-1"
                                                        }
                                                        pageClassName={"page-item mx-2"}
                                                        pageLinkClassName={"page-link"}
                                                        PreviousClassName={"page-item"}
                                                        PreviousLinkClassName={"page-link pagestyle"}
                                                        NextClassName={"page-item"}
                                                        NextLinkClassName={"page-link"}
                                                        breakClassName={"page-item"}
                                                        breakLinkClassName={"page-link"}
                                                        activeClassName={"active"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {/* faild data */}
                                <div
                                    className="tab-pane fade"
                                    id="Failed"
                                    role="tabpanel"
                                >
                                    <div className="col-lg-12">
                                        <div className="card card-xxl-stretch mb-5 mb-xl-8">
                                            <div class="card-header border-0 pt-6 d-flex align-items-center">
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fw-bold fs-3 mb-1">
                                                        Failed List
                                                    </span>
                                                    <span className="text-muted mt-1 fw-semibold fs-7">
                                                        {" "}
                                                        Over {data?.totalFailedRecords} Success List
                                                    </span>
                                                </h3>
                                                <div class="card-title"></div>
                                            </div>

                                            <div className="card-body py-3">
                                                <div className="table-responsive">
                                                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                                        <thead>
                                                            <tr className="fw-bold text-muted th-title">
                                                                <th className="text-center" style={{ paddingRight: '1em' }}>Sr.No.</th>
                                                                <th></th>
                                                                <th className="min-w-150px">
                                                                    Bruh IDs Ref No.
                                                                </th>
                                                                <th>
                                                                    Date of Registration
                                                                </th>
                                                                <th className="min-w-100px text-center">Error Message</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {data?.failedData?.map((item, index) => (
                                                                <tr>
                                                                    <td className="text-center" style={{ paddingRight: '1em' }}>{index + 1}</td>
                                                                    <td>
                                                                        {" "}
                                                                        <div class="d-flex align-items-center">
                                                                            <div class="symbol symbol-45px me-5">
                                                                                <img src={item?.IDphoto} alt="" />
                                                                            </div>
                                                                            <div class="d-flex justify-content-start flex-column">
                                                                                <a class="usertitle Acolor mb-2">
                                                                                    {" "}
                                                                                    {item.fullname}
                                                                                </a>
                                                                                <span class="font-icon mb-1">
                                                                                    <span class="">
                                                                                        <i class="fal fa-phone-volume"></i>
                                                                                    </span>
                                                                                    +{item.phone}
                                                                                </span>

                                                                                <span class="font-icon mb-1">
                                                                                    <span class="">
                                                                                        <i class="fal fa-envelope"></i>
                                                                                    </span>
                                                                                    {item.email}{" "}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{item.digitalrefID}</td>
                                                                    <td>
                                                                        {Moment(item.createdAt).format("DD/MM//YYYY")}
                                                                    </td>
                                                                    <td className="text-danger">
                                                                       {item?.error}
                                                                    </td>


                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="col-lg-12 mt-2 text-end">
                                                    <ReactPaginate
                                                        PreviousLabel={"Previous"}
                                                        NextLabel={"Next"}
                                                        breakLabel={"..."}
                                                        pageCount={pageCount}
                                                        marginPagesDisplayed={1}
                                                        pageRangeDisplayed={6}
                                                        onPageChange={handlePageClick}
                                                        containerClassName={
                                                            "pagination justify-content-end gap-1"
                                                        }
                                                        pageClassName={"page-item mx-2"}
                                                        pageLinkClassName={"page-link"}
                                                        PreviousClassName={"page-item"}
                                                        PreviousLinkClassName={"page-link pagestyle"}
                                                        NextClassName={"page-item"}
                                                        NextLinkClassName={"page-link"}
                                                        breakClassName={"page-item"}
                                                        breakLinkClassName={"page-link"}
                                                        activeClassName={"active"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
