import ReactPaginate from "react-paginate";

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { error } from "jquery";
import Modal from "react-bootstrap/Modal";

var jwt = require("jsonwebtoken");




function AdminDetailsView({ hadlerorg }) {
  const { _id } = useParams('');

  const [data, setData] = useState([])
  const [document, setdocument] = useState([]);
  const [transmonths, settransmonths] = useState([]);
  const [transvalue, settransvalue] = useState([]);
  const [translist, settranslist] = useState([]);
  const [Query, setQuery] = useState("");
  const [searching, setsearching] = useState("");
  const [show, setshow] = useState(false);
  const [photo, setphoto] = useState("");
  const [pageCount, setpageCount] = useState("");


  const [Orguselistdata, setOrguselistdata] = useState([]);
  let token = localStorage.getItem("organizationToken")

  const limit = 10;
  const userlist = () => {
    const { adminID } = { adminID: _id }
    axios.post(`/v1/org/Agent/view-admin-detail`, { adminID }, { headers: { "Authorization": `Bearer ${token}` } })
      .then(resp => {
        let data = resp.data.data;
        setData(data);
      }).catch(error)

  }
  const userdoc = () => {
    const { orgID } = { orgID: _id }
    axios.post(`/v1/admin/viewDoc/${orgID}`)
      .then(resp => {
        let data = resp?.data?.findDoc;
        setdocument(data);
      }).catch(error)

  }




  // first Pagination start

  const Orguselist = async () => {
    const { agentId } = { agentId: _id };
    await axios.post(`/v1/org/CRM/get-super-agent-loans`, { agentId }, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
      const data = resp.data.data;
      setOrguselistdata(data)
      const total = resp.data.totalRow;
      const totalPage = Math.ceil(total / limit);
      setpageCount(totalPage);
    });
  };

  const fetchComments = async (page) => {
    const senData = { page: page, agentId: _id };
    axios.post(`/v1/org/CRM/get-super-agent-loans`, senData,{ headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
      const data = resp.data.data;
      setOrguselistdata(data)
    });
    return data;
  };

  const handlePageClick = async (data) => {

    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };


  // end first Pagination

  // start secondes page

  const Transactionuselist = () => {
    const { agentId } = { agentId: _id }
    axios.post(`/v1/org/CRM/get-super-agent-cust-loans`, { agentId }, { headers: { "Authorization": `Bearer ${token}` } })
      .then(resp => {

        let data11 = resp.data.data;
        settranslist(data11);
        const total = resp.data.totlaRow;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);





      }).catch(error)


  }


  const fetchCommentspage = async (page) => {
    const { orgID } = { orgID: _id }
    const senData = { page: page };
    axios.post(`/v1/admin/get_transctions/${orgID}`, senData)
      .then(resp => {
        let data11 = resp.data.findCust;
        settranslist(data11);

      });
    return data;
  };


  const handlePageClickpage = async (data) => {

    const page = data.selected + 1;
    const commentsFormServer = await fetchCommentspage(page);
  };


  // end  seconded page

  // graph use transction

  const Transactionuse = () => {
    const { orgID } = { orgID: _id }
    axios.get(`/v1/admin/get_org_transections_months/${orgID}`)
      .then(resp => {

        let data = resp.data.obj;
        settransmonths(Object.keys(data))
        settransvalue(Object.values(data))
      }).catch(error)


  }





  // document show function


  const imageview = (image) => {
    setphoto(image);
    setshow(true);
  };

  // api call
  useEffect(() => {
    userlist();
    userdoc();
    Transactionuse();
    Transactionuselist();
    hadlerorg()
    Orguselist();
  }, [])













  return (
    <>
      <ToastContainer position="top-right" />


      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" >
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">


                  <div className="col-lg-12">
                    <div
                      className="card mb-12 rounded-15 bgi-no-repeat bgi-position-x-end 
        bgi-size-cover bg_card"
                    >
                      <div className="card-body flex-column p-5">
                        <div className="d-flex align-items-center ">
                          <div className="card-body  pt-9 pb-0 ">
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                              <div className="me-7 mb-4">
                                <div className="symbol border border-gray-300 border-dashed symbol-100px symbol-lg-160px symbol-fixed position-relative text-white">




                                  <img src={data?.Image} alt="image" className="rounded-circle mx-2" />

                                </div>
                              </div>


                              <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                  <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-2">
                                      <a
                                        href="#"
                                        className="-900 text-hover-primary fs-2 text-white fw-bold me-1"
                                      >
                                        {data?.firstName} {data?.lastName}

                                      </a>

                                    </div>

                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                              fill="currentColor"
                                            />
                                            <rect
                                              x="7"
                                              y="6"
                                              width="4"
                                              height="4"
                                              rx="2"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {"Admin"}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        <span className="svg-icon text-white  svg-icon-4 me-1">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {"Ethiopian"}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-white mb-2"
                                      >
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {data?.email}
                                      </a>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex flex-wrap flex-stack">
                                  <div className="d-flex flex-column flex-grow-1 pe-8">
                                    <div className="d-flex flex-wrap">
                                                                        </div>
                                    <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">


                                    </div>
                                  </div>

                        
                                </div>
                              </div>
                            </div>
                 
                          </div>

                          <div className="flex-equal d-flex justify-content-center align-items-end ms-5">
                            <img
                              src="../../assets/media/illustrations/dozzy-1/20.png"
                              alt=""
                              className="mw-100 mh-125px mh-lg-275px mb-lg-n12"
                            />
                          </div>
                        </div>

                        <div className="card-rounded  col-12 d-flex flex-stack flex-wrap p-5 pb-0">
                          <ul
                            className="nav flex-wrap border-transparent fw-bold"
                            role="tablist"
                          >
                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#overview"
                                className="btn btn-color-gray-600 btn-active-secondary  active btn-active-color-primary fw-bolder fs-5 fs-lg-base nav-link px-3 px-lg-7 mx-1 "
                              >
                                Overview
                              </a>
                            </li>

                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#documents"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-5 fs-lg-base nav-link px-3 px-lg-7 mx-1 "
                              >
                               Admin Documents
                              </a>
                            </li>

          
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="overview"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-title m-0">
                                <h3 className="fw-bold m-0">Admin Details</h3>
                              </div>
                            </div>

                            <div className="card-body p-9">
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                Admin Name
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-bold fs-6 -800">
                                    {data?.firstName} {data?.lastName}
                                  </span>
                                </div>
                              </div>

                            
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Joining Date
                                </label>

                                <div className="col-lg-8 fv-row">
                                  <span className="fw-semibold -800 fs-6">
                                    {data?.createdAt}
                                  </span>
                                </div>
                              </div>
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Email Address
                                </label>

                                <div className="col-lg-8 fv-row">
                                  <span className="fw-semibold -800 fs-6">
                                
                                    {data?.email}
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                Contact No.
                                  <i
                                    className="fas fa-exclamation-circle ms-1 fs-7"
                                    data-bs-toggle="tooltip"
                                    title="Phone number must be active"
                                  ></i>
                                </label>

                                <div className="col-lg-8 d-flex align-items-center">
                                  <span className="fw-bold fs-6 -800 me-2">
                                    +{data?.phone}
                                  </span>
                                  <span className="badge badge-success">
                                    Verified
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Country
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-semibold fs-6 -800 text-hover-primary">
                                    {"Ethiopian"}
                                  </span>
                                </div>
                              </div>

                             

                            </div>
                          </div>
                        </div>
                     

                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="documents"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-12 mb-4">
                          <h3 className="fw-bold my-2">
                            User Documents
                            <span className="fs-6 -400 fw-semibold ms-1"></span>
                          </h3>
                        </div>

                        <div className="row mt-4">
                          <div className="col-lg-3 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() => imageview("../../assets_new/images/largepreview.png")}
                              >
                                <a className="img_div">
                                  <img src="../../assets_new/images/largepreview.png" />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    data-toggle="lightbox"
                                    data-gallery="gallery"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between p-3">
                                <h5> Identification Document Image</h5>
                                <div>
                                  <a href="../../assets_new/images/largepreview.png" className="ml-2">
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className="col-lg-3 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() => imageview("../../assets_new/images/electronic.png")}
                              >
                                <a className="img_div">
                                  <img src="../../assets_new/images/electronic.png" />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between p-3">
                                <h5>Company Profile Brochure</h5>
                                <div>
                                  <a
                                    href="../../assets_new/images/electronic.png"
                                    className="ml-2"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() => imageview("../../assets_new/images/gov.jpg")}
                              >
                                <a className="img_div">
                                  <img src="../../assets_new/images/gov.jpg" />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between p-3">
                                <h5>Proof of Company Address</h5>
                                <div>
                                  <a
                                    href="../../assets_new/images/gov.jpg"
                                    className="ml-2"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() => imageview("../../assets_new/images/largepreview.png")}
                              >
                                <a className="img_div">
                                  <img src="../../assets_new/images/largepreview.png" />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between p-3">
                                <h5>Customer Agreement</h5>
                                <div>
                                  <a
                                    href="../../assets_new/images/largepreview.png"
                                    className="ml-2"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane fade" id="land" role="tabpanel">
                      <div className="row g-5 g-xl-8">
                        <div className="col-lg-12">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div class="card-header border-0 pt-6 d-flex align-items-center">

                              <h4>Super Agent Loans List</h4>
                              

                            </div>

                            <div class="card-body pt-0">
                              <div className="table-responsive">

                                <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_customers_table">

                                  <thead>
                                    <tr class="fw-bold text-muted th-title">
                                      <th className="min-w-125px">D-ID</th>
                                      <th className="min-w-150px">Loan Type</th>
                                      <th className="min-w-150px">Interest Rate </th>
                                      <th className="min-w-100px">EMI</th>
                                      <th className="min-w-100px">Total Amount</th>
                                      <th className="min-w-100px">Duration Year</th>
                                      <th className="min-w-125px">Total Interest Amount</th>
                                      <th className="min-w-100px ">Status</th>
                                    </tr>
                                  </thead>

                                  <tbody>


                                    {Orguselistdata?.map((item) =>
                                      <tr>


                                        <td>

                                          <div class="d-flex align-items-center">
                                            <div class="symbol symbol-45px me-5">
                                              <img src={item.agent_detail?.Image} alt="" />
                                            </div>
                                            <div class="d-flex justify-content-start flex-column">
                                              <a href={`/customer-view-admin/${item.agent_detail?._id}`} class="usertitle Acolor mb-2"> {item.agent_detail?.firstName} {item.agent_detail?.lastName}</a>
                                              <span class="font-icon mb-1">

                                                <span class="">
                                                  <i class="fal fa-phone-volume"></i>
                                                </span>

                                                +{item.agent_detail?.phone}</span>

                                              <span class="font-icon mb-1">
                                                <span class="">
                                                  <i class="fal fa-envelope"></i>
                                                </span>
                                                {item.agent_detail?.email} </span>
                                            </div>
                                          </div>

                                        </td>





                                        <td>{item.loanType}</td>
                                        <td><span>{`${item.interestRate}%`}</span> </td>
                                        <td>{item.payMethod}</td>
                                        <td>{item.totalAmount?.toFixed(2)}</td>
                                        <td>{item.timePeriod}</td>
                                        <td>{item.intrestAmount?.toFixed(2)}</td>
                                        <td><span class="badge badge-light-info fs-5">{item.status}</span>
                                        </td>




                                      </tr>)}


                                  </tbody>

                                </table>


                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  previousLabel={"previous"}
                                  nextLabel={"next"}
                                  breakLabel={"..."}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={3}
                                  onPageChange={handlePageClick}
                                  containerClassName={"pagination justify-content-end"}
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link pagestyle"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>









                          </div>
                        </div>
                      </div>






                    </div>

                    <div className="tab-pane fade" id="finance" role="tabpanel">
                      <div class="row">


                        <div class="col-lg-12">
                          <div class="card">

                            <div class="card-header border-0 pt-6 d-flex align-items-center">

                              <h4>Super Agent Customer Loans List</h4>
                            </div>

                            <div class="card-body pt-0">
                              <div className="table-responsive">

                                <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_customers_table">

                                  <thead>

                                    <tr class="text-start tr_bg text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                      <th className="min-w-125px">D-ID</th>
                                      <th className="min-w-150px">Loan Type</th>
                                      <th className="min-w-150px">Interest Rate </th>
                                      <th className="min-w-100px">EMI</th>
                                      <th className="min-w-100px">Total Amount</th>
                                      <th className="min-w-100px">Duration Year</th>
                                      <th className="min-w-125px">Total Interest Amount</th>

                                      <th class="text-center min-w-70px">Status</th>

                                    </tr>

                                  </thead>

                                  <tbody class="fw-semibold text-gray-600">
                                    {translist?.map((item) => <tr>
                                      <td>
                                        {" "}
                                        <div class="d-flex align-items-center">
                                          <div class="symbol symbol-45px me-5">
                                            <img src={item.cust_detail.IDphoto} alt="" />
                                          </div>
                                          <div class="d-flex justify-content-start flex-column">
                                            <a
                                              href={`/Agent-customer-view/${item.cust_detail._id}`}
                                              class="usertitle Acolor mb-2"
                                            >
                                              {" "}
                                              {item.cust_detail?.fullname}
                                            </a>
                                            <span class="font-icon mb-1">
                                              <span class="">
                                                <i class="fal fa-phone-volume"></i>
                                              </span>
                                              +{item.cust_detail?.phone}
                                            </span>

                                            <span class="font-icon mb-1">
                                              <span class="">
                                                <i class="fal fa-envelope"></i>
                                              </span>
                                              {item.cust_detail?.email}{" "}
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td>{item.loanType}</td>
                                      <td><span>{`${item.interestRate}%`}</span> </td>
                                      <td>{item.payMethod}</td>
                                      <td>{item.totalAmount}</td>
                                      <td>{item.timePeriod}</td>
                                      <td>{item.intrestAmount}</td>


                                      <td class="text-center"><span class="badge py-3 px-4 fs-7 badge badge-light-info fs-5">{item.status}</span></td>
                                    </tr>)}



                                  </tbody>

                                </table>

                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  previousLabel={"previous"}
                                  nextLabel={"next"}
                                  breakLabel={"..."}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={3}
                                  onPageChange={handlePageClickpage}
                                  containerClassName={"pagination justify-content-end"}
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link pagestyle"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>



                          </div>
                        </div>
                      </div>








                    </div>
                    <div className="tab-pane fade" id="account" role="tabpanel">
                      <div className="row">
                        <div className="col-lg-12 mb-4">
                          <h3 className="fw-bold my-2">
                            User Bank Documents
                            <span className="fs-6 -400 fw-semibold ms-1"></span>
                          </h3>
                        </div>

                        <div className="row mt-4 text-hover-primary">
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">
                      Powered by
                    </span>
                    <a
                      href="#"
                      className="text-muted text-hover-primary fw-semibold me-2 fs-6"
                    >
                      Chromepay
                    </a>
                  </div>

                  <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        About
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Modal show={show} onHide={() => setshow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>DOCUMENT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <img src={photo} alt="document" style={{ width: "100%" }} />
              </div>
            </Modal.Body>
          </Modal>


        </div>
      </div>

    </>
  );
}









export default AdminDetailsView