import React, { useState, useEffect } from "react";
import axios from "axios";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import CustomerLoader from "../SuperAgent/CRM/Modal/CustomerLoder";
import UserGraph from "./GraphComponent/UserJDCGraph";
import GraphComponent from "./common/GraphComponent";
import UnionGraph from "./common/UnionGraph";
import UnionTransPerfGraph from "./common/UnionTransPerfGraph";
import UnionStoreGraph from "./common/UnionStoreGraph";

var jwt = require("jsonwebtoken");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const JDCAnalyticPage = ({ hadlerjdc }) => {
  let token = localStorage.getItem("token");

  const [Today_date, setToday_date] = useState([]);
  const [Last_Month, setLast_Month] = useState([]);
  const [useractivedata, setuseractivedata] = useState([]);
  const [useractivedatayear, setuseractivedatayear] = useState([]);
  const [useractivedatamonths, setuseractivedatamonths] = useState([]);
  const [useractiveSavingdatayear, setuseractiveSavingdatayear] = useState([]);
  const [useractiveSavingdatamonths, setuseractiveSavingdatamonths] = useState(
    []
  );
  const [ShowLoader, setShowLoader] = useState(true);
  const [useractiveStoredatayear, setuseractiveStoredatayear] = useState([]);
  const [useractiveStoredatamonths, setuseractiveStoredatamonths] = useState(
    []
  );
  const [section, setSection] = useState([]);
  const [dash, setDash] = useState([]);
  const [frequency, setFrequency] = useState("month");
  const [savingTypeFrequency, setsavingTypeFrequency] = useState("mandatory");
  const [savingFrequency, setSavingFrequency] = useState("month");
  const [storeFrequency, setStoreFrequency] = useState("month");
  const [data1, setData1] = useState([]);
  const [orgdash, setOrgDash] = useState([]);
  const [finduser, setFinduser] = useState([]);
  const [findTrans, setFindTrans] = useState([]);
  const [transData, setTransData] = useState([]);
  const [performTrans, setPerformTransData] = useState([]);
  const [transmonths, setTransMonths] = useState([]);
  const [transvalue, setTransValue] = useState([]);
  const [analyticsData, setanalyticsData] = useState([]);
  const [totalSalesCounts, setTotalSalesCOunt] = useState([]);
  const [allAnalyticalData, setAllAnalyticalData] = useState([]);
  const [graphData, setAnalyticGraphData] = useState([]);
  const [activeTab, setActiveTab] = useState("addSaving");
  const [chartData, setChartData] = useState({});
  const [filteredData, setFilteredData] = useState(graphData);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDateTransDist, setTransDistFromDate] = useState("");
  const [toDateTransDist, setTransDistToDate] = useState("");
  const [latestTransValue, setLatestTransValue] = useState(0);
  const [lastMonthTrans, setLastMonthTransValue] = useState(0);
  const [jdcUnions, setJdcUnions] = useState([]);
  const [jdcSelectedUnions, setJdcSelectedUnions] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [countValue, setCountValue] = useState("count");

  useEffect(() => {
    setShowLoader(true);

    const fetchData = async () => {
      try {
        const response = await axios.post(
          "/v1/super-Agent/jdc-new-transaction-graph",
          { Type: "month", category: "all", format: "noncumulative" },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const userData = response.data;

        let totalAmountsByMonth = {};

        // Function to add totalAmount for each month
        const addTransactionTotals = (transactions) => {
          transactions.forEach(({  month, value,year, totalAmount }) => {
            if (!totalAmountsByMonth[month]) {
              totalAmountsByMonth[month] = {
                monthName: `${month || value}-${year || ""}`,
                totalAmount: 0,
              };
            }
            totalAmountsByMonth[month].totalAmount += totalAmount;
          });
        };

        addTransactionTotals(userData?.loanTransactions || []);
        addTransactionTotals(userData?.savingTransactions || []);
        addTransactionTotals(userData?.shareTransactions || []);
        addTransactionTotals(userData?.storeTransactions || []);

    
        const sortByYearAndMonth = (data) => {
          const entries = Object.entries(data);
                        const sortedEntries = entries.sort(([, a], [, b]) => {
            const [monthA, yearA] = a.monthName.split("-").map(Number);
            const [monthB, yearB] = b.monthName.split("-").map(Number);
        
            if (yearA !== yearB) return yearA - yearB;
            return monthA - monthB;
          });
        
          const sortedObject = {};
          sortedEntries.forEach(([key, value], index) => {
            const paddedKey = String(index + 1).padStart(2, "0");
            sortedObject[paddedKey] = value;
          });
        
          return sortedObject;
        };
       const sortedData = sortByYearAndMonth(totalAmountsByMonth);
       totalAmountsByMonth = sortedData

        
    

        


       
       
        const filteredMonths = Object.keys(totalAmountsByMonth)
          .filter((month) => totalAmountsByMonth[month].totalAmount > 0)
          .sort((a, b) => a - b)
          .map((month) => totalAmountsByMonth[month]);

        const totalAmountsArray = filteredMonths.map(
          (item) => item.totalAmount
        );
        const months = filteredMonths.map((item) => item.monthName);

        setTransValue(totalAmountsArray);
        setTransMonths(months);

        console.log("sortedData", {sortedData})

        console.log("sortedData", {sortedData})

        console.log("sortedData", {sortedData})

        console.log("sortedData", {sortedData})

        console.log("sortedData", {sortedData})

        console.log("sortedData", {sortedData})


        const lastIndex = totalAmountsArray[totalAmountsArray.length - 1];
        const lastMonthIndex = totalAmountsArray[totalAmountsArray.length - 2];
        setLatestTransValue(lastIndex);
        setLastMonthTransValue(lastMonthIndex);
        setShowLoader(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setShowLoader(false);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    if (dateRange) {
      const filtered = graphData.filter((item) => {
        const month = item.month.toLowerCase();
        const startMonth = dateRange[0]
          ?.toDate()
          ?.toLocaleString("default", { month: "long" })
          .toLowerCase();
        const endMonth = dateRange[1]
          ?.toDate()
          ?.toLocaleString("default", { month: "long" })
          .toLowerCase();
        return month >= startMonth && month <= endMonth;
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(graphData);
    }
  }, [dateRange, graphData]);

  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);
  const handleFromDateChangeTarnsDist = (e) => setTransDistFromDate(e.target.value);
  const handleToDateChangeTarnsDist  = (e) => setTransDistToDate(e.target.value);
  const handleResetDID = () => {
    setToDate("");
    setFromDate("");
    setJdcSelectedUnions("");
  };
  const formatNumber = (number) => {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(2) + "M";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(2) + "k";
    } else {
      return number?.toString();
    }
  };

  const formatNumber1 = (number) => {
    return new Intl.NumberFormat('en-IN').format(number?.toFixed(2));
  };
  

  const JDCUnions = () => {
    axios
      .post(
        `/v1/super-Agent/get-unions-for-filters`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let Profession = resp.data?.data;
        console.log({ Profession });
        setJdcUnions(Profession);
      });
  };

  useEffect(() => {
    setShowLoader(true);
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "/v1/super-Agent/jdc-total-transactions",
          { fromDateTransDist, toDateTransDist, orgId: jdcSelectedUnions },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTransData(response.data);
        setChartData(response.data.chartData);
        setShowLoader(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setShowLoader(false);
      }
    };
    fetchData();
  }, [toDateTransDist, jdcSelectedUnions]);


  useEffect(() => {
    setShowLoader(true);
    // Fetch the data from your API
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "/v1/super-Agent/jdc-total-transactions",
          { fromDate, toDate, orgId: jdcSelectedUnions },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPerformTransData(response.data);
        // setChartData(response.data.chartData);
        console.log("circle data ", response.data);
        setShowLoader(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setShowLoader(false);
      }
    };
    fetchData();
  }, [toDate, jdcSelectedUnions]);


  useEffect(() => {
    setShowLoader(true);
    // Fetch the data from your API
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "/v1/super-Agent/jdc-total-transactions",
          { fromDate, toDate, orgId: jdcSelectedUnions },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPerformTransData(response.data);
        // setChartData(response.data.chartData);
        console.log("circle data ", response.data);
        setShowLoader(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setShowLoader(false);
      }
    };
    fetchData();
  }, [toDate, jdcSelectedUnions]);


  useEffect(() => {
    setShowLoader(true);
    // Fetch the data from your API
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "/v1/super-Agent/jdc-total-transactions",
          { fromDate, toDate, orgId: jdcSelectedUnions },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPerformTransData(response.data);
        // setChartData(response.data.chartData);
        console.log("circle data ", response.data);
        setShowLoader(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setShowLoader(false);
      }
    };
    fetchData();
  }, [toDate, jdcSelectedUnions]);


  useEffect(() => {
    setShowLoader(true);
    // Fetch the data from your API
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "/v1/super-Agent/jdc-total-transactions",
          { fromDate, toDate, orgId: jdcSelectedUnions },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPerformTransData(response.data);
        // setChartData(response.data.chartData);
        console.log("circle data ", response.data);
        setShowLoader(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setShowLoader(false);
      }
    };
    fetchData();
  }, [toDate, jdcSelectedUnions]);


  useEffect(() => {
    setShowLoader(true);
    // Fetch the data from your API
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "/v1/super-Agent/jdc-total-transactions",
          { fromDate, toDate, orgId: jdcSelectedUnions },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPerformTransData(response.data);
        // setChartData(response.data.chartData);
        console.log("circle data ", response.data);
        setShowLoader(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setShowLoader(false);
      }
    };
    fetchData();
  }, [toDate, jdcSelectedUnions]);


  useEffect(() => {
    setShowLoader(true);
    // Fetch the data from your API
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "/v1/super-Agent/jdc-total-transactions",
          { fromDate, toDate, orgId: jdcSelectedUnions },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPerformTransData(response.data);
        // setChartData(response.data.chartData);
        console.log("circle data ", response.data);
        setShowLoader(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setShowLoader(false);
      }
    };
    fetchData();
  }, [toDate, jdcSelectedUnions]);


  useEffect(() => {
    setShowLoader(true);
    // Fetch the data from your API
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "/v1/super-Agent/jdc-total-transactions",
          { fromDate, toDate, orgId: jdcSelectedUnions },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPerformTransData(response.data);
        // setChartData(response.data.chartData);
        console.log("circle data ", response.data);
        setShowLoader(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setShowLoader(false);
      }
    };
    fetchData();
  }, [toDate, jdcSelectedUnions]);

  const doughnutData = {
    labels: Object.keys(chartData),
    datasets: [
      {
        label: "Product Category",
        data: Object.values(chartData),
        backgroundColor: [
          "#498894", // Deep teal
          "#F4A261", // Warm orange
          "#2A9D8F", // Fresh green
          "#E76F51", // Coral red
          "#264653", // Dark blue-grey
          "#E9C46A", // Soft yellow
        ],
        hoverBackgroundColor: [
          "#406D76", // Muted teal
          "#D98B4C", // Muted orange
          "#21867A", // Muted green
          "#CC5C42", // Muted coral
          "#203D47", // Muted blue-grey
          "#D1AE5F", // Muted yellow
        ],
        borderWidth: 1,
        borderColor: "#FFFFFF", // White border for clarity
      },
    ],
  };
  

  // Api count graph

  const [dateRangeCout, setdateRangeCout] = useState({
    fromDate: "",
    toDate: "",
  });

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setdateRangeCout((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResetCount = () => {
    setdateRangeCout({
      fromDate: "",
      toDate: "",
    });
    setJdcSelectedUnions("");
  };

  function generateColorPalette(n) {
    const baseColor = "#498894"; // Your theme color
    const baseRgb = [
      parseInt(baseColor.slice(1, 3), 16), // Red
      parseInt(baseColor.slice(3, 5), 16), // Green
      parseInt(baseColor.slice(5, 7), 16), // Blue
    ];
  
    const palette = [];
    for (let i = 0; i < n; i++) {
      // Adjust the color to create variations (lighten or darken)
      const factor = (i + 1) / n; // Creates gradual variations based on the index
      const r = Math.min(255, Math.max(0, baseRgb[0] + (factor - 0.5) * 80)); // Lighten or darken red
      const g = Math.min(255, Math.max(0, baseRgb[1] + (factor - 0.5) * 80)); // Lighten or darken green
      const b = Math.min(255, Math.max(0, baseRgb[2] + (factor - 0.5) * 80)); // Lighten or darken blue
  
      palette.push(
        `#${Math.round(r).toString(16).padStart(2, "0")}${Math.round(g)
          .toString(16)
          .padStart(2, "0")}${Math.round(b).toString(16).padStart(2, "0")}`
      );
    }
  
    return palette;
  }
  
  const ApiCountData = {
    labels: analyticsData?.map((item, index) => item?.type),
    datasets: [
      {
        label: "Product Category",
        data: analyticsData?.map((item, index) => item?.total),
        backgroundColor: generateColorPalette(analyticsData.length),
        hoverBackgroundColor: generateColorPalette(analyticsData.length),
      },
    ],
  };

  const getRandomColor = (() => {
    const colors = [
      "#FF6F61", // Coral Red
      "#6B5B95", // Elegant Purple
      "#88B04B", // Soft Green
      "#F7CAC9", // Blush Pink
      "#92A8D1", // Serene Blue
      "#F4B400", // Vibrant Yellow
      "#E94B3C", // Bold Red
      "#5B84B1", // Cool Blue
      "#B565A7", // Royal Purple
      "#9E1B32", // Rich Maroon
      "#FFB400", // Amber Orange
      "#6CACE4", // Light Blue
    ];
  
    let colorIndex = 0; // Track the current index in the colors array
  
    return () => {
      const color = colors[colorIndex];
      colorIndex = (colorIndex + 1) % colors.length; // Cycle through the array
      return color;
    };
  })();
  

  // Function to generate an array of random colors based on the number of data points
  const generateColors = (numColors) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      colors.push(getRandomColor());
    }
    return colors;
  };

  const backgroundColor = generateColors(ApiCountData.datasets[0].data.length);
  const hoverBackgroundColor = generateColors(
    ApiCountData.datasets[0].data.length
  );


  const data = {
    labels: ApiCountData.labels,
    datasets: [
      {
        ...ApiCountData.datasets[0],
        backgroundColor: backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor,
      },
    ],
  };

  const options = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
    },
  };

  const chartStyles = {
    width: "80%",
    height: "500px",
    margin: "0 auto",
  };

  const fetchCountData = async () => {
    try {
      const response = await axios.post(
        "/v1/super-Agent/get-jdc-product-category-sales",
        {
          fromDate: dateRangeCout?.fromDate,
          toDate: dateRangeCout?.toDate,
          orgId: jdcSelectedUnions,
          transType: countValue,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

     
      setanalyticsData(response?.data?.data);
      setTotalSalesCOunt(response?.data?.totalCounts);

      setAnalyticGraphData(response?.data?.graphData);
      setAllAnalyticalData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCountData();
  }, [dateRangeCout?.toDate, jdcSelectedUnions, countValue]);

  const userlist = () => {
    axios
      .post(
        `/v1/super-Agent/get-jdc-dash`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
        const findLicenseFees = data.findLicenseFees;
        const finduser = data.finduser;
        const findTrans = data.findTrans;
        setData1(data);
        setOrgDash(findLicenseFees);
        setFinduser(finduser);
        setFindTrans(findTrans);
  
        setShowLoader(false);
      });
  };

  const UserPermoance = async () => {
    await axios
      .post(
        `/v1/super-Agent/superagent-report`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        const userData3 = res.data;
        const userData = res.data.detail;
        setuseractivedata(userData3);
        setDash(userData);
        setShowLoader(false);
      });
  };

  const orgTransectionsSection = async () => {
    await axios
      .post(
        `/v1/org/reports/org-detail`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.data;
        setSection(data);
      });
  };

  const getIcon = (key) => {
    switch (key) {
      case "Add Saving":
        return "fas fa-piggy-bank";
      case "Create DID":
        return "fas fa-id-card";
      case "Update DID":
        return "fas fa-edit";
      case "Add Loan":
        return "fas fa-hand-holding-usd";
      case "Pay EMI":
        return "fas fa-money-check-alt";
      case "Withdrawal Saving":
        return "fas fa-piggy-bank";
      case "Add Share":
        return "fas fa-chart-line";
      case "Withdrawal Share":
        return "fas fa-chart-line-down";
      case "Buy Product":
        return "fas fa-shopping-cart";
      case "Voluntary To Mandatoary":
        return "fas fa-exchange-alt";
      default:
        return "fas fa-chart-bar";
    }
  };

  useEffect(() => {
    UserPermoance();
    orgTransectionsSection();
    userlist();
    fetchCountData();

    JDCUnions();
    hadlerjdc();
  }, []);
 
  const transactionGraph = async (sort) => {
    const body = { Type: frequency };
    await axios
      .post(`/v1/super-Agent/get-did-graph`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const userData = res.data.data;
        const categories = userData.map((item) => item.count);
        const users = userData.map((item) => item.value);

        setuseractivedatayear(categories);
        setuseractivedatamonths(users);

      });
  };

  const savingTransactionGraph = async (sort) => {
    const body = { Type: savingFrequency, savingType: savingTypeFrequency };
    await axios
      .post(`/v1/super-Agent/get-savingtrans-graph`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const userData = res.data.data;
        const categories = userData.map((item) => item.totalSendingAmount);
        const users = userData.map((item) => item.value);

        setuseractiveSavingdatayear(categories);
        setuseractiveSavingdatamonths(users);
       
      });
  };

  const storeTransactionGraph = async (sort) => {
    const body = { Type: storeFrequency };
    await axios
      .post(`/v1/super-Agent/get-storetrans-graph`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const userData = res.data.data;

        const categories = userData.map((item) => item.totalSendingAmount);
        const users = userData.map((item) => item.value);

        setuseractiveStoredatayear(categories);
        setuseractiveStoredatamonths(users);

      });
  };
  useEffect(() => {
    transactionGraph();
  }, [frequency]);

  useEffect(() => {
    savingTransactionGraph();
  }, [savingFrequency]);

  useEffect(() => {
    savingTransactionGraph();
  }, [savingTypeFrequency]);

  useEffect(() => {
    storeTransactionGraph();
  }, [storeFrequency]);

  const handlesorting = (event) => {
    let sort = event.target.value;
    setFrequency(sort);
    transactionGraph(sort);
    console.log(sort);
  };
  const handleSavingsorting = (event) => {
    let sort = event.target.value;
    setSavingFrequency(sort);
    savingTransactionGraph(sort);
    console.log(sort);
  };
  const handleSavingTypesorting = (event) => {
    let sort = event.target.value;
    setsavingTypeFrequency(sort);
    savingTransactionGraph(sort);
    console.log(sort);
  };
  const handleStoreTypesorting = (event) => {
    let sort = event.target.value;
    setStoreFrequency(sort);
    storeTransactionGraph(sort);
    console.log(sort);
  };
  return (
    <>
      <CustomerLoader loader={ShowLoader} />

      <div
        className="wrapper d-flex flex-column flex-row-fluid mb-5 back-color"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid mb-0 py-0"
          id="kt_content"
        >
          <div className="container-xxl mb-0" id="kt_content_container">
            <div className="card-body pt-9 pb-0">
              <h2
                className="text-center mb-4"
                style={{ color: "#4A90E2", fontWeight: "bold" }}
              >
                Performance Overview
              </h2>
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="card rounded-15 mb-5 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <div className="card-body pt-9 pb-5">
                      <div className="rounded d-flex flex-column flex-lg-row mend align-items-lg-center bg-body p-5 h-lg-60px me-lg-10 my-5">
                        <div className="row flex-grow-1 mb-2 mb-lg-0">
                          <div className="col-md-3 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                            <label className="small_label my-label">
                              Start Date
                            </label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                              <i className="fad fa-calendar"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                              value={fromDate}
                              onChange={handleFromDateChange}
                            />
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                          </div>

                          <div className="col-md-3 d-flex ps-1 align-items-center mb-2 input-container mb-lg-0">
                            <label className="small_label my-label">
                              End Date
                            </label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                              <i className="fad fa-calendar"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                              value={toDate}
                              onChange={handleToDateChange}
                            />
                          </div>

                          <div className="col-md-3">
                            <form>
                              <div className="d-flex align-items-center gap-2">
                                <select
                                  value={jdcSelectedUnions}
                                  onChange={(e) =>
                                    setJdcSelectedUnions(e.target.value)
                                  }
                                  className="form-select graph-select shadow-sm"
                                >
                                  <option selected>Choose any one union</option>

                                  {jdcUnions?.map((item) => (
                                    <option key={item._id} value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </form>
                          </div>

                          <div className="col-md-2 p-0 d-flex align-items-center mb-3 justify-content-start ps-1 input-container mb-lg-0">
                            <button
                              type="reset"
                              onClick={() => handleResetDID()}
                              className="btn my_rest-btn btn-light btn-active-light-primary "
                              id="kt_advanced_search_button_1"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row gy-2">
                        <div className="col-md-6 col-lg-3">
                          <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i className="fad fa-users fs-2"></i>
                                </span>
                              </span>
                            </div>
                            <div className="">
                              
                              <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                {formatNumber(
                                  performTrans?.totalDID || "Loading..."
                                )}

                              </span>
                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6">
                                  Total Customers
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                          <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i className="fad fa-users fs-2"></i>
                                </span>
                              </span>
                            </div>
                            <div className="">
                              <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                               
                                {formatNumber(
                                  performTrans?.totalFarmers || "Loading..."
                                )}
                              </span>
                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6">
                                  Total Farmers
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                          <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i class="fas fa-landmark fs-2"></i>
                                </span>
                              </span>
                            </div>
                            <div className="">
                              <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                {formatNumber(performTrans?.TotalUnions)}
                              </span>
                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6">
                                  Total Unions
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                          <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i class="fas fa-landmark fs-2"></i>
                                </span>
                              </span>
                            </div>
                            <div className="">
                              <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                {formatNumber(performTrans?.TotalTotalCooperative)}
                              </span>
                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6">
                                  Total Cooperatives
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Transactions Over View */}
          <div className="container-xxl mb-0" id="kt_content_container">
            <div className="card-body pt-9 pb-0">
              <h2
                className="text-center mb-4"
                style={{ color: "#4A90E2", fontWeight: "bold" }}
              >
                Transactions Overview
              </h2>
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="card rounded-15 mb-5 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <div className="card-body pt-9 pb-5">
                      <div className="rounded d-flex flex-column flex-lg-row mend align-items-lg-center bg-body p-5 h-lg-60px me-lg-10 my-5">
                        <div className="row flex-grow-1 mb-2 mb-lg-0">
                          <div className="col-md-3 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                            <label className="small_label my-label">
                              Start Date
                            </label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                              <i className="fad fa-calendar"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                              value={fromDate}
                              onChange={handleFromDateChange}
                            />
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                          </div>

                          <div className="col-md-3 d-flex ps-1 align-items-center mb-2 input-container mb-lg-0">
                            <label className="small_label my-label">
                              End Date
                            </label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                              <i className="fad fa-calendar"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                              value={toDate}
                              onChange={handleToDateChange}
                            />
                          </div>

                          <div className="col-md-3">
                            <form>
                              <div className="d-flex align-items-center gap-2">
                                <select
                                  value={jdcSelectedUnions}
                                  onChange={(e) =>
                                    setJdcSelectedUnions(e.target.value)
                                  }
                                  className="form-select graph-select shadow-sm"
                                >
                                  <option selected>Choose any one union</option>

                                  {jdcUnions?.map((item) => (
                                    <option key={item._id} value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </form>
                          </div>

                          <div className="col-md-2 p-0 d-flex align-items-center mb-3 justify-content-start ps-1 input-container mb-lg-0">
                            <button
                              type="reset"
                              onClick={() => handleResetDID()}
                              className="btn my_rest-btn btn-light btn-active-light-primary "
                              id="kt_advanced_search_button_1"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row gy-2">
                        <div className="col-md-6 col-lg-3">
                          <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i className="fad fa-users fs-2"></i>
                                </span>
                              </span>
                            </div>
                            <div className="">
                              <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                              <span className="ms-2 text-muted fs-1">ETB </span>
                                
                                {formatNumber(
                                  (transData?.LoanAmount +
                                    // transData?.totalPaidLoan +
                                    transData?.Store +
                                    transData?.Saving +
                                    // transData?.totalSavingsWithd 
                                    + transData?.Share || 0) - 160000
                                )}
                              </span>
                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6">
                                  Total Transactions
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                          <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i className="fas fa-bullseye-arrow fs-2"></i>
                                </span>
                              </span>
                            </div>
                            <div className="">
                              <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                              <span className="ms-3 text-muted fs-1">ETB </span>
                               
                                {formatNumber(transData?.LoanAmount || 0)}
                              </span>
                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6">
                                  Total Loan Amount (ETB)
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                          <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i class="fas fa-landmark fs-2"></i>
                                </span>
                              </span>
                            </div>
                            <div className="">
                              <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                              <span className="ms-2 text-muted fs-1">ETB </span>
                                
                                {formatNumber(transData?.totalPaidLoan)}
                              </span>
                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6">
                                  Total Loan Repayment (ETB)
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                          <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i className="fas fa-dollar-sign fs-3"></i>
                                </span>
                              </span>
                            </div>
                            <div className="">
                              <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                              <span className="ms-2 text-muted fs-1">ETB </span>
                                
                                {formatNumber(transData?.Store)}
                              </span>
                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6">
                                  Total Store Sales
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                          <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i className="fas fa-piggy-bank fs-4"></i>
                                </span>
                              </span>
                            </div>
                            <div className="">
                              <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                              <span className="ms-2 text-muted fs-1">ETB </span>
                               
                                {formatNumber(transData?.Saving)}
                              </span>
                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6">
                                  Total Saving Amount (ETB)
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                          <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i className="fas fa-piggy-bank fs-4"></i>
                                </span>
                              </span>
                            </div>
                            <div className="">
                              <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                              <span className="ms-2 text-muted fs-1">ETB </span>
                               
                                {formatNumber(transData?.Share)}
                              </span>
                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6">
                                  Total Share Amount (ETB)
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-lg-3">
                          <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i class="fas fa-piggy-bank fs-2"></i>
                                </span>
                              </span>
                            </div>
                            <div className="">
                              <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                              <span className="ms-2 text-muted fs-1">ETB </span>
                                
                                {formatNumber(transData?.totalSavingsWithd)}
                              </span>
                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6">
                                  Total Savings Withdrawal
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="container-xxl mb-0" id="kt_content_container">
            <div className="card-body pt-9 pb-0">
              <h2
                className="text-center mb-4"
                style={{ color: "#4A90E2", fontWeight: "bold" }}
              >
                Store Front Over View
              </h2>
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="card rounded-15 mb-5 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <div className="card-body pt-9 pb-5">
                      <div className="rounded d-flex flex-column flex-lg-row mend align-items-lg-center bg-body p-5 h-lg-60px me-lg-10 my-5">
                        <div className="row flex-grow-1 mb-2 mb-lg-0">
                          <div className="col-md-3 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                            <label className="small_label my-label">
                              Start Date
                            </label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                              <i className="fad fa-calendar"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                              value={fromDate}
                              onChange={handleFromDateChange}
                            />
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                          </div>

                          <div className="col-md-3 d-flex ps-1 align-items-center mb-2 input-container mb-lg-0">
                            <label className="small_label my-label">
                              End Date
                            </label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                              <i className="fad fa-calendar"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                              value={toDate}
                              onChange={handleToDateChange}
                            />
                          </div>

                          <div className="col-md-3">
                            <form>
                              <div className="d-flex align-items-center gap-2">
                                <select
                                  value={jdcSelectedUnions}
                                  onChange={(e) =>
                                    setJdcSelectedUnions(e.target.value)
                                  }
                                  className="form-select graph-select shadow-sm"
                                >
                                  <option selected>Choose any one union</option>

                                  {jdcUnions?.map((item) => (
                                    <option key={item._id} value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </form>
                          </div>

                          <div className="col-md-2 p-0 d-flex align-items-center mb-3 justify-content-start ps-1 input-container mb-lg-0">
                            <button
                              type="reset"
                              onClick={() => handleResetDID()}
                              className="btn my_rest-btn btn-light btn-active-light-primary "
                              id="kt_advanced_search_button_1"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row gy-2">
                        {analyticsData?.map((item, index) => (
                          <div key={index} className="col-md-6 col-lg-3">
                            <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                              <div className="symbol symbol-30px me-5">
                                <span className="symbol-label">
                                  <span className="svg-icon svg-icon-1 svg-icon-primary">
                                    <i className="fad fa-users fs-2"></i>
                                  </span>
                                </span>
                              </div>
                              <div>
                                <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                 
                                  {formatNumber(item?.count || 0)}
                                </span>
                                <h6 className="nz-2">
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    {item?.type
                                      ? item.type.charAt(0).toUpperCase() +
                                        item.type.slice(1)
                                      : ""}
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="col-md-6 col-lg-3">
                          <div className="relative bg-gray-100 animation-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i className="fas fa-dollar-sign fs-3"></i>
                                </span>
                              </span>
                            </div>
                            <div className="">
                              <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1">
                                {totalSalesCounts}
                              </span>
                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6">
                                  Total Store Sales
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-xxl mb-0" id="kt_content_container">
            <div className="card-body pt-9 pb-0">
              <div className="row mt-5">
                <div className="col-lg-6">
                  <div className="card rounded-15 mb-5 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                    <div className="card-body">
                      <h3
                        className="text-center mb-4"
                        style={{ color: "#4A90E2", fontWeight: "bold" }}
                      >
                        Transaction Distribution
                      </h3>

                      <div className="rounded d-flex flex-column flex-lg-row mend align-items-lg-center bg-body p-5 h-lg-60px me-lg-10 my-5">
                        <div className="row      flex-grow-1 mb-5 mb-lg-0">
                          <div className="col-md-4 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                            <label className="small_label my-label">
                              Start Date
                            </label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                              <i className="fad fa-calendar"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                              value={fromDateTransDist}
                              onChange={handleFromDateChangeTarnsDist}
                            />
                            <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                          </div>

                          <div className="col-md-4 d-flex ps-1 align-items-center mb-3 input-container mb-lg-0">
                            <label className="small_label my-label">
                              End Date
                            </label>
                            <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                              <i className="fad fa-calendar"></i>
                            </span>
                            <input
                              type="date"
                              className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                              value={toDateTransDist}
                              onChange={handleToDateChangeTarnsDist}
                            />
                          </div>

                          <div className="col-md-3 d-flex align-items-center mb-3 justify-content-start ps-1 input-container mb-lg-0">
                            <button
                              type="reset"
                              onClick={() => handleResetDID()}
                              className="btn btn-light btn-active-light-primary"
                              id="kt_advanced_search_button_1"
                            >
                              Reset
                            </button>
                          </div>

                          <div className="col-md-3">
                            <form>
                              <div className="d-flex align-items-center gap-2">
                                <select
                                  value={jdcSelectedUnions}
                                  onChange={(e) =>
                                    setJdcSelectedUnions(e.target.value)
                                  }
                                  className="form-select graph-select shadow-sm"
                                >
                                  <option selected>Choose any one union</option>

                                  {jdcUnions?.map((item) => (
                                    <option key={item._id} value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: "67vh", overflow: "scroll" }}
                      >
                        <div style={{ width: "60%" }}>
                          <Doughnut data={doughnutData} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div
                    className="card rounded-15 mb-5 mb-xl-10  bgi-no-repeat bgi-position-x-end bgi-size-cover"
                    style={{ paddingBottom: "1rem" }}
                  >
                    <div className="card-body  pt-9 pb-5 ">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="retative bg-gray-100 animestion-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5 ">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i className="fad fa-users"></i>
                                </span>
                              </span>
                            </div>

                            {Last_Month <= 0 ? (
                              <i className="fal fa-arrow-up fz-20"></i>
                            ) : (
                              <i className="fal fa-arrow-down fz-21"></i>
                            )}
                            <div className=" ">
                              <span className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1  ">
                              <span className="ms-2 text-muted fs-1">ETB </span>
                              {formatNumber(latestTransValue || 0)}
                              </span>

                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6  ">
                                  New Transactions
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="retative bg-gray-100 animestion-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5 ">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i className="fad fa-users"></i>
                                </span>
                              </span>
                            </div>

                            <i class="fas fa-bullseye-arrow fz-200"></i>

                            <div className=" ">
                              <span className="text-gray-700 fz-230 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1  ">
                              <span className="ms-2 text-muted fs-1">ETB </span>
                              {formatNumber(lastMonthTrans || 0)}
                              </span>
                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6  ">
                                  Last Month Transactions
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6" style={{ marginTop: "40px" }}>
                          <div className="retative bg-gray-100 animestion-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5 ">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i className="fad fa-users"></i>
                                </span>
                              </span>
                            </div>

                            {Last_Month <= 0 ? (
                              <i className="fal fa-arrow-up fz-20"></i>
                            ) : (
                              <i className="fal fa-arrow-down fz-21"></i>
                            )}
                            <div className=" ">
                              <span
                             
                                className="text-gray-700 fz-23 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1  "
                              >
                                {formatNumber(
                                  transData?.currentMonthFarmers || 0
                                )}
                              </span>

                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6  ">
                                  New DID's Register
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6" style={{ marginTop: "40px" }}>
                          <div className="retative bg-gray-100 animestion-bank h-100 bg-opacity-70 rounded-2 px-6 py-5">
                            <div className="symbol symbol-30px me-5 ">
                              <span className="symbol-label">
                                <span className="svg-icon svg-icon-1 svg-icon-primary">
                                  <i className="fad fa-users"></i>
                                </span>
                              </span>
                            </div>

                            <i class="fas fa-bullseye-arrow fz-200"></i>

                            <div className=" ">
                              <span className="text-gray-700 fz-230 mt-3 fw-bolder d-block fs-4qx lh-1 ls-n1 mb-1  ">
                                {formatNumber(transData.lastMonthFarmers)}
                              </span>
                              <h6 className="nz-2">
                                <span className="text-gray-500 fw-semibold fs-6  ">
                                  Last Month DID's Registration
                                </span>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <UserGraph />
                  <UnionGraph />
                  <GraphComponent />
                  <UnionTransPerfGraph />
                  <UnionStoreGraph />
                  {/* <AdminCopGraph /> */}
                </div>
              </div>
            </div>
          </div>

          <div className="card rounded-15 mb-5 mb-xl-10 bgi-no-repeat bgi-position-x-end bgi-size-cover">
            <div className="card-body">
              <div className="rounded align-items-lg-center p-5 me-lg-10 my-5">
                <div className="row align-items-center justify-content-end flex-grow-1 mb-5 mb-lg-0">
                  <div className="col-md-3">
                    <form>
                      <div class="row mb-6">
                        <label class="col-lg-4 col-form-label  fw-semibold fs-6">
                          Count Value
                        </label>
                        <div class="col-lg-8 fv-row">
                          <div class="d-flex align-items-center mt-3">
                            <label class="form-check form-check-inline form-check-solid me-5">
                              <input
                                class="form-check-input"
                                name="transType"
                                type="radio"
                                value="Count"
                                defaultChecked
                                onChange={(e) => setCountValue(e.target.value)}
                              />
                              <span class="fw-semibold ps-2 fs-6">True</span>
                            </label>

                            <label class="form-check form-check-inline form-check-solid">
                              <input
                                class="form-check-input"
                                name="transType"
                                type="radio"
                                value="transValue"
                                onChange={(e) => setCountValue(e.target.value)}
                              />
                              <span class="fw-semibold ps-2 fs-6">False</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                    <label className="small_label my-label">Start Date</label>
                    <span className="svg-icon svg-icon-1 svg-icon-gray-400">
                      <i className="fad fa-calendar"></i>
                    </span>
                    <input
                      type="date"
                      className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                      name="fromDate" 
                      value={dateRangeCout.fromDate}
                      onChange={handleDateChange}
                    />
                    <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                  </div>

                  <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                    <label className="small_label my-label">End Date</label>
                    <span className="svg-icon svg-icon-1 svg-icon-gray-400">
                      <i className="fad fa-calendar"></i>
                    </span>
                    <input
                      type="date"
                      className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                      name="toDate" 
                      value={dateRangeCout.toDate}
                      onChange={handleDateChange}
                    />
                    <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                  </div>

                  <div className="col-md-3">
                    <form>
                      <div className="d-flex align-items-center gap-2">
                        <select
                          value={jdcSelectedUnions}
                          onChange={(e) => setJdcSelectedUnions(e.target.value)}
                          className="form-select graph-select shadow-sm"
                        >
                          <option selected>Choose any one union</option>

                          {jdcUnions?.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </form>
                  </div>

                  <div className="col-md-1 d-flex align-items-center mb-3 justify-content-start ps-1 input-container mb-lg-0">
                    <button
                      type="reset"
                      onClick={handleResetCount}
                      className="btn btn-light btn-active-light-primary"
                      id="kt_advanced_search_button_1"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="d-flex justify-content-center"
                style={{ height: "100vh", overflow: "scroll" }}
              >
                <div style={{ width: "50%" }}>
                  <Doughnut data={ApiCountData} />
                </div>
              </div>
            </div>
          </div>

          <div className="rounded align-items-lg-center p-5 me-lg-10 my-5">
            <div className="row   align-items-center justify-content-center  mb-5 ">
              <div className="col-md-3">
                <form>
                  <div class="row mb-6">
                    <label class="col-lg-4 col-form-label  fw-semibold fs-6">
                      Count Value
                    </label>
                    <div class="col-lg-8 fv-row">
                      <div class="d-flex align-items-center mt-3">
                        <label class="form-check form-check-inline form-check-solid me-5">
                          <input
                            class="form-check-input"
                            name="transType"
                            type="radio"
                            value="Count"
                            defaultChecked
                            onChange={(e) => setCountValue(e.target.value)}
                          />
                          <span class="fw-semibold ps-2 fs-6">True</span>
                        </label>

                        <label class="form-check form-check-inline form-check-solid">
                          <input
                            class="form-check-input"
                            name="transType"
                            type="radio"
                            value="transValue"
                            onChange={(e) => setCountValue(e.target.value)}
                          />
                          <span class="fw-semibold ps-2 fs-6">False</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                <label className="small_label my-label">Start Date</label>
                <span className="svg-icon svg-icon-1 svg-icon-gray-400">
                  <i className="fad fa-calendar"></i>
                </span>
                <input
                  type="date"
                  className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                  name="fromDate" 
                  value={dateRangeCout.fromDate}
                  onChange={handleDateChange}
                />
                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
              </div>

              <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                <label className="small_label my-label">End Date</label>
                <span className="svg-icon svg-icon-1 svg-icon-gray-400">
                  <i className="fad fa-calendar"></i>
                </span>
                <input
                  type="date"
                  className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                  name="toDate" 
                  value={dateRangeCout.toDate}
                  onChange={handleDateChange}
                />
                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
              </div>

              <div className="col-md-3">
                <form>
                  <div className="d-flex align-items-center gap-2">
                    <select
                      value={jdcSelectedUnions}
                      onChange={(e) => setJdcSelectedUnions(e.target.value)}
                      className="form-select graph-select shadow-sm"
                    >
                      <option selected>Choose any one union</option>

                      {jdcUnions?.map((item) => (
                        <option key={item._id} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </form>
              </div>

              <div className="col-md-1 d-flex align-items-center mb-3 justify-content-start ps-1 input-container mb-lg-0">
                <button
                  type="reset"
                  onClick={handleResetCount}
                  className="btn btn-light btn-active-light-primary"
                  id="kt_advanced_search_button_1"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          <div
            className="d-flex justify-content-center"
            style={{ height: "100vh", overflow: "scroll" }}
          >
            <div style={chartStyles}>
              <Bar data={data} options={options} />
            </div>
          </div>


        </div>

 

      
      </div>
    </>
  );
};

export default JDCAnalyticPage;
