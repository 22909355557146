import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

var jwt = require("jsonwebtoken");

export default function Sidebar() {

  const navigate = useNavigate();

  const { authType } = useSelector((state) => state.auth);
  

  let token = localStorage.getItem("token");
  var decode1 = jwt.decode(token);
  // let adminID = decode1.admminID;
  // console.log('pushpak',adminID);

  const [menu, setMenu] = useState([]);
  const [orgmenu, setOrgMenu] = useState([]);
  const [agent, setAgent] = useState([]);
  const [ip, setIP] = useState([]);

  // const subAdminlist = async () => {
  //   await axios.post(`/v1/admin/subAdminRole/${adminID}`,{},{ headers: {"Authorization" : `Bearer ${token}`}}).then((resp) => {
  //     let data = resp.data.find;

  //     setMenu(data.customer);
  //     setOrgMenu(data.Organisation);
  //     setAgent(data.Agent);
  //     setIP(data.IP);
  //   });
  // };

  // useEffect(() => {
  //   subAdminlist();
  // }, []);

  const logout = () => {
    localStorage.clear();
    navigate(`/`);
  };

  return (
    <>
      <div
        id="kt_aside"
        className="aside bg-primary"
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="auto"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_toggle"
      >
        <div
          className="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-8"
          id="kt_aside_logo"
        >
          <Link to="/admin">
            <img
              alt="Logo"
              src="/assets_new/images/logo.png"
             style={{ height: "50px", width: "50px" }}
             
            />
          </Link>
        </div>

        <div
          className="aside-nav d-flex flex-column align-lg-center flex-column-fluid w-100 pt-5 pt-lg-0"
          id="kt_aside_nav"
        >
          <div
            className="hover-scroll-overlay-y my-2 my-lg-5"
            id="kt_aside_menu_wrapper"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu"
            data-kt-scroll-offset="5px"
          >
            <div
              id="kt_aside_menu"
              className="menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-6"
              data-kt-menu="true"
            >
              <div className="menu-item here show py-2">
                <Link to={`/${authType}-dashbord`} className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i className="fad fa-home-lg fs-1"></i>
                  </span>
                </Link>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <Link to={`/${authType}-dashbord`} className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i className="far fa-user-plus fs-1"></i>
                  </span>
                </Link>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px ashow">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        CRM
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to={`/crm/${authType}-active-list`} className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Active Union IDs</span>
                    </Link>
                    {/* <Link to="/crm/jdc-active-block" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Blocked IDs</span>
                    </Link> */}

                    {/* {menu.addCustomer == 1 ? ( */}
                    {/* <>
                        <Link to="/admin-add-digiter-ids" className="menu-link">
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span>
                          <span className="menu-title">Create Digital ID</span>
                        </Link>
                      </> */}
                    {/* ) : (
                      ""
                    )}

                    <Link to="/digital-id-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">D-IDs Awaiting Approval</span>
                    </Link> */}
                    {/* {orgmenu.addOrganisation == 1 ? ( */}
                    {/* <>
                        <Link to="/organization-Add" className="menu-link">
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span>
                          <span className="menu-title">
                            Register Organisation
                          </span>
                        </Link>
                      </> */}
                    {/* ) : (
                      ""
                    )} */}
                    {/* <Link to="/organization-list-admin" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Organisation List</span>
                    </Link> */}

                    {/* <Link to="/organisations-block-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Blocked Organisation</span>
                    </Link>
                    <Link to="/Registerempolyees" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Register Empolyees</span>
                    </Link> */}

                    {/* <Link to="/Customer-block-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Blocked IDs</span>
                    </Link>

                    <Link to="/less-licence" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> D-ID Licence Renewal</span>
                    </Link> */}
                    {/* <Link to="/admin/super-agent-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Super Agent List</span>
                    </Link> */}
                  </div>
                </div>
              </div>

              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
               
                    <i className="fas fa-university fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Loan Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/jdc/pending/list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                        Pending Loan Applications{" "}
                      </span>
                    </Link>
                    <Link to="/jdc/pass/list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Approved Loans </span>
                    </Link>
                   <Link to="/Super-Agent-Share-Apply-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Approved Share </span>


                    </Link> 

                    <Link to="/superagent/Loan-Reject-customer-List" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Rejected Loans </span>
                      
                      
                    </Link>

                    <Link to="/Super-agent/Emi-logs-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> EMI Logs </span>
                      
                      
                    </Link> 
                  </div>
                </div>
              </div> */}

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    {/* <i class="fas fa-users-slash fs-1"></i> */}
                    {/* <i class="fas fa-users-class fs-1"></i> */}
                    {/* <i className="fas fa-university fs-1"></i> */}
                    <i className="fas fa-users fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Cooperative
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    {/* <Link to="/Super-Agent-pendingloan" className="menu-link"> */}
                    <Link to={`${authType}/cooperative`} className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Cooperative List</span>
                    </Link>

                    {/* <Link to="/Super-Agent-Share-Apply-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Approved Share </span>


                    </Link> */}

                    {/* <Link to="/superagent/Loan-Reject-customer-List" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Rejected Loans </span>
                      
                      
                    </Link>

                    <Link to="/Super-agent/Emi-logs-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> EMI Logs </span>
                      
                      
                    </Link> */}
                  </div>
                </div>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    {/* <i class="fas fa-users-slash fs-1"></i> */}
                    {/* <i class="fas fa-users-class fs-1"></i> */}
                    {/* <i className="fas fa-university fs-1"></i> */}
                    <i className="fas fa-underline fs-1"></i>
                    {/* <i class="fas fa-underline"></i> */}
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Unions
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    {/* <Link to="/Super-Agent-pendingloan" className="menu-link"> */}
                    <Link to={`${authType}/union`} className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Union List</span>
                    </Link>

                    {/* <Link to="/Super-Agent-Share-Apply-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Approved Share </span>


                    </Link> */}

                    {/* <Link to="/superagent/Loan-Reject-customer-List" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Rejected Loans </span>
                      
                      
                    </Link>

                    <Link to="/Super-agent/Emi-logs-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> EMI Logs </span>
                      
                      
                    </Link> */}
                  </div>
                </div>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i className="fab fa-sellcast fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Store front management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to={`${authType}/product/list`} className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Product List</span>
                    </Link>
                    {/* <Link to="/jdc/orders" className="menu-link">
                    <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">User Orders</span>
                </Link>
                <Link to="/jdc/selling/order/list" className="menu-link">
                    <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">User Selling Orders</span>
                </Link> */}
                    <Link to={`${authType}/transaction`} className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Storefront Transactions </span>
                    </Link>
                    {/* <Link to="/jdc/Buying/list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Buying Transactions </span>
                    </Link> */}
                  </div>
                </div>
              </div>

              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i
                      class="fas fa-file-chart-line"
                      style={{ fontSize: "1.8rem" }}
                    ></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Reports
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                   
                    <Link to={`${authType}/transaction/data`} className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Transaction Reports</span>
                    </Link>
                    
                  </div>
                </div>
              </div> */}

              {/* {authType == "jdc" && ( */}
              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i
                      className="fas fa-users-cog"
                      style={{ fontSize: "1.8rem" }}
                    ></i>
                  
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Sub Admin Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                   
                    <Link to={`${authType}/subadmin-list`} className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Sub Admin list</span>
                    </Link>
                    <Link to={`${authType}/subadmin-created`} className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Add Sub Admin</span>
                    </Link>
                  </div>
                </div>
              </div>


              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    {/* <i class="fas fa-users-slash fs-1"></i> */}
                    <i class="fas fa-chart-bar fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px ashow5">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Analytics
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/analytic-page" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Analytics</span>
                    </Link>
                  </div>
                </div>
              </div>
              {/* )} */}
              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <Link to="/admin" className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i class="fas fa-file-chart-line fs-1"></i>
                  </span>
                </Link>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px ashow1">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Reports
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/did-report" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">D-ID reports</span>
                    </Link>
                    <Link to="/transction-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Transaction history</span>
                    </Link>
                    <Link to="/agent-report" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                      Agent Performance Report
                        
                      </span>
                    </Link>
                    <Link to="/organzation-report" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title text-start">
                        Organisation Performance Report{" "}
                      </span>
                    </Link>
                    <Link to="/all-orgnzation-report" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title text-start">
                        Organisations Performance Report
                      </span>
                    </Link>
                    <Link to="/admin/super-agent-report" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title text-start">
                        Super Agent Performance Report
                      </span>
                    </Link>
                  </div>
                </div>
              </div> */}

              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i className="fonticon-layers fs-1"></i>
                    <i class="fas fa-money-check-alt fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px">
                  <div className="menu-item">
                    <div className="menu-content">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Transaction Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/transction-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Transaction List</span>
                    </Link>
                  </div>
                </div>
              </div> */}

              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i class="fab fa-digital-ocean fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px">
                  <div className="menu-item">
                    <div className="menu-content">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        DID Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/pendding-did" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Customer DIDs List</span>
                    </Link>
                    <Link to="/digital-approve-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Approved DID</span>
                    </Link>
                  </div>
                </div>
              </div> */}

              {/* <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" className="menu-item py-2">

                                <span className="menu-link menu-center">
                                    <span className="menu-icon me-0">
                                    <i class="fad fa-user-plus fs-1"></i>
                                   
                                    </span>
                                </span>

                                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px">

                                    <div className="menu-item">

                                        <div className="menu-content">
                                            <span className="menu-section fs-5 fw-bolder ps-1 py-1">Sub-Admin Managment</span>
                                        </div>

                                    </div>

                                    <div className="menu-item menu-accordion">
                                    
                                    <Link to="/subadmin-add" className="menu-link">

                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title"> Add Sub Admin</span>
                                        </Link>
                                        <Link to="/roles-add" className="menu-link">

                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title"> Add Roles</span>
                                        </Link>
                                        

                                    </div>
                                    


                                </div>

                            </div> */}
              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    
                    <i class="fad fa-university fs-1"></i>
                   
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px ashow2">
                  <div className="menu-item">
                    <div className="menu-content">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Organisation Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="#" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                        Organisations/Agents/Branches{" "}
                      </span>
                    </Link>
                    <Link to="#" className="menu-link menu-title">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <div
                        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                        data-kt-menu-placement="left-start"
                        className="menu-item py-2"
                      >
                        <span className="menu-title">Administration <i class="angel-arrow fal fa-angle-right"></i> </span>

                        <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px changeposition">
                          <div className="menu-item">
                            <div className="menu-content">
                                                            <span className="menu-section fs-5 fw-bolder ps-1 py-1">Security</span>
                                                        </div>
                          </div>

                          <div className="menu-item menu-accordion">
                            <Link to="/subadmin-add" className="menu-link">
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                              </span>
                              <span className="menu-title"> Add Sub Admin</span>
                            </Link>

                            <Link
                              to="/subadmin-role-list"
                              className="menu-link"
                            >
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                              </span>
                              <span className="menu-title">Admins List</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Link to="/EmpolyList" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Employees</span>
                    </Link>
                    <Link to="/Agentlist" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> All Agent </span>
                    </Link>
                    <Link to="#" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Import Data</span>
                    </Link>
                  </div>
                </div>
              </div> */}
              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i class="far fa-shield-check fs-1"></i>
                  
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px ashow3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Security
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    {ip.IPblackListing == 1 ? (
                      <>
                        <Link to="/customer-ipaddress" className="menu-link">
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span>
                          <span className="menu-title">
                            IP whitelisting/blacklisting
                          </span>
                        </Link>
                      </>
                    ) : (
                      ""
                    )}

                    <Link to="#" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Audit Records</span>
                    </Link>
                    <Link to="/agent-block-list" className="menu-link">

                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title"> Blocked Agents</span>
                                        </Link>
                  </div>
                </div>
              </div> */}
              {/* <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" className="menu-item py-2">

                                <span className="menu-link menu-center">
                                    <span className="menu-icon me-0">
                                    <i class="fas fa-users-medical fs-1"></i>
                                    </span>
                                </span>

                                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px">

                                    <div className="menu-item">

                                        <div className="menu-content">
                                            <span className="menu-section fs-5 fw-bolder ps-1 py-1">Agent Management</span>
                                        </div>

                                    </div>

                                    <div className="menu-item menu-accordion">
                                       <Link to="/Agent-admin-add" className="menu-link">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Add Agent</span>
                                        </Link>


                                        <Link to="/Agent-admin-list" className="menu-link">
                                            <span className="menu-bullet">
                                                <span className="bullet bullet-dot"></span>
                                            </span>
                                            <span className="menu-title">Agent List</span>
                                        </Link>

                                    </div>


                                </div>

                            </div>   */}
              {/* 
              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                 
                    <i class="fad fa-download fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px ashow4">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Export Files
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/admin-did-customer-export" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">All D-ID List</span>
                    </Link>
                    <Link to="/Org-customer-export" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                      Organisation Customer List
                      </span>
                    </Link>
                    <Link to="/admin-blocked-export" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Blocked D-ID</span>
                    </Link>
                  </div>
                </div>
              </div> */}

              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                  
                    <i class="fad fa-table fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px ashow5">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                         Logs Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                  <Link to="/admin-logs-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Chromepay Logs List</span>
                    </Link>
                    <Link to="/orgnization-logs-list" className="menu-link">
      <span className="menu-bullet">
        <span className="bullet bullet-dot"></span>
      </span>
      <span className="menu-title">Organisation Logs List</span>
    </Link>
    <Link to="#" className="menu-link ">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <div
                        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                        data-kt-menu-placement="left-start"
                        className="menu-item py-2"
                      >
                        <span className="menu-title">Kacha Log List <i class="angel-arrow fal fa-angle-right"></i> </span>

                        <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px changeposition">
                          <div className="menu-item">
                            <div className="menu-content">
                                                            <span className="menu-section fs-5 fw-bolder ps-1 py-1">Security</span>
                                                        </div>
                          </div>

                          <div className="menu-item menu-accordion">
                            <Link to="/kacha/ocr" className="menu-link">
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                              </span>
                              <span className="menu-title"> Ocr History</span>
                            </Link>

                            <Link
                              to="/kacha/face"
                              className="menu-link"
                            >
                              <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                              </span>
                              <span className="menu-title">Face History</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div> */}

              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i className="fonticon-setting fs-1"></i>
                 
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px ashow6">
                  <div className="menu-item">
                    <div className="menu-content">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Settings
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/my-profile" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">My Profile</span>
                    </Link>
                    <Link to="/Admin-change-password" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Account Settings</span>
                    </Link>
                    <Link to="/Admin-setting" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Passwords and OTP</span>
                    </Link>
                    <Link to="/agent-transaction-limit" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                        {" "}
                        Agent Transaction Limit
                      </span>
                    </Link>
                    <Link to="/setup-fees" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Fee Setup</span>
                    </Link>
                    <Link to="/license/request" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Request Licence </span>
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <br></br>
                       
                        <div>
                        <button onClick={()=>logout()} className="menu-link px-3" style={{border:"none",background:"none"}}><i class="fad fa-sign-out-alt fs-1"></i></button>
                        </div> */}
          </div>
        </div>

        {/* <div className="aside-footer d-flex flex-column align-items-center flex-column-auto" id="kt_aside_footer">

                    <div className="mb-7">
                        
                        <button type="button" className="btn btm-sm btn-custom btn-icon" data-kt-menu-trigger="click" data-kt-menu-overflow="true" data-kt-menu-placement="top-start" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-dismiss="click" title=" Setting">
                            <i className="fonticon-setting fs-1"></i>
                        </button>

                        <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">

                            <div className="menu-item px-3">
                                <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Setting</div>
                            </div>

                            <div className="separator mb-3 opacity-75"></div>


                            <div className="menu-item px-3">
                                <Link to="/my-profile" className="menu-link px-3">My Profile</Link>
                            </div>


                            <div className="menu-item px-3">
                                <Link to="/Admin-change-password" className="menu-link px-3">Account Settings</Link>
                            </div>
                            <div className="menu-item px-3">
                                <Link to="/Admin-setting" className="menu-link px-3">Passwords and OTP</Link>
                            </div>
                           
                            <div className="menu-item px-3">
                                <Link to="/agent-transaction-limit" className="menu-link px-3">Agent Transaction Limit</Link>
                            </div>
                            <div className="menu-item px-3">
                                <Link to="/setup-fees" className="menu-link px-3">Fee Setup</Link>
                            </div>
                            <div className="menu-item px-3">
                                <Link to="#" className="menu-link px-3">Sign Out</Link>
                            </div>


                            <div className="separator mb-3 opacity-75"></div>

                        </div>

                    </div>

                </div> */}
      </div>
    </>
  );
}
