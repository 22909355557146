import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Chart from "react-apexcharts";
function AgentViewOrg({ hadlerorg }) {
  const { _id } = useParams("");
  const [transections, setTransactions] = useState([]);
  const [users, setUsers] = useState([]);
  const [datas, setDatas] = useState("");
  let token = localStorage.getItem("organizationToken");
  const [frequency, setFrequency] = useState("month");
  const [useractivedatamonths, setuseractivedatamonths] = useState([]);
  const [useractivedatayear, setuseractivedatayear] = useState([]);
  const agentID = _id;
  const agnetdata = async () => {
    const { ID } = { ID: _id };
    await axios
      .post(
        `/v1/org/Agent/Agent_dash_main/${ID}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;
        const user = data.finduser;
        const transaction = data.findTrans;
        setTransactions(transaction);
        setUsers(user);
        setDatas(data);
      });
  };

  useEffect(() => {
    hadlerorg();
    agnetdata();
  }, []);

  const transactionGraph = async (sort) => {
    const body = { agentId: agentID, filter: "month" };
    await axios
      .post(`/v1/org/CRM/org-agent-cust-graph`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const userData = res.data.data;
        const categories = userData?.map((item) => item.count);
        const users = userData?.map((item) => item.monthName);

        setuseractivedatayear(categories);
        setuseractivedatamonths(users);
      });
  };
  useEffect(() => {
    transactionGraph(frequency);
  }, []);
  const handlesorting = (event) => {
    let sort = event.target.value;
    setFrequency(sort);
    transactionGraph(sort);
    console.log(sort);
  };

  return (
    <>
      <ToastContainer position="top-right" />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card card-flush">
                      <div className="bg_div card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px">
                        <h3 className="card-title align-items-start flex-column text-white pt-15">
                          <span className="fw-bold fs-2x mb-3">
                            Agent Dashboard
                          </span>
                          <div className="fs-4 text-white"></div>
                        </h3>
                      </div>

                      <div className="card-body mt-n20">
                        <div className="mt-n20 position-relative dashorgnastion">
                          <div className="row g-3 g-lg-6">
                            <div className="col-6">
                              <div className="retative bg-gray-100 ative animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={datas.findNoOfuser}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {datas.findNoOfuser}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Number Of Users
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-hands-usd"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span className="text-gray-700  fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                                    {"$" + datas.totalTrasections}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Transaction Volume{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-4">
                              <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fas fa-id-card"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={datas.NoOfTrans}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {datas.NoOfTrans}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Number of Transactions{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-4">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-landmark"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value="0"
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    0
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Loan Applications
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-4">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i class="fad fa-hand-holding-usd"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1">
                                    <small>$</small>
                                    {datas.commissionamount}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Commission Amounts
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-flush h-xl-100">
                      <div className="container pt-7">
                        <div className="row">
                          <div className="col-md-6">
                            {" "}
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-800">
                                Agent Activity
                              </span>
                              <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
                            </h3>
                          </div>
                          <div className="col-md-3 text-end"></div>
                          <div className="col-md-3  text-end">
                            <form>
                              <div className=" align-items-end filterCss gap-1 ">
                                <div
                                  className="d-flex align-items-center g-5 mb-4 "
                                  style={{ "justify-content": "space-between" }}
                                >
                                  <select
                                    defaultValue={frequency}
                                    onChange={handlesorting}
                                    className="form-select graph-select"
                                  >
                                    <option value="week"> Week</option>
                                    <option value="month"> Monthly</option>
                                    <option value="year">Yearly</option>
                                  </select>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div className="bg-white overflow-auto mt-md-5 pt-md-5">
                        <Chart
                          type="bar"
                          width={"100%"}
                          height={400}
                          series={[
                            {
                              name: "User",
                              data: useractivedatamonths,
                            },
                          ]}
                          options={{
                            plotOptions: {
                              bar: {
                                borderRadius: 5,
                                columnWidth: 40,
                                startingShape: "flat",
                                endingShape: "rounded",
                                dataLabels: {
                                  position: "top",
                                },
                              },
                            },

                            title: {},

                            subtitle: {},

                            colors: ["#3c7f8c"],
                            theme: { mode: "light" },

                            xaxis: {
                              tickPlacement: "on",
                              categories: useractivedatayear,
                            },

                            yaxis: {
                              labels: {
                                formatter: (val) => {
                                  return `${val}`;
                                },
                                style: { fontSize: "15", colors: ["#3c7f8c"] },
                              },
                              title: {},
                            },

                            legend: {
                              show: true,
                              position: "right",
                            },

                            dataLabels: {
                              formatter: (val) => {
                                return `${val}`;
                              },
                              style: {
                                colors: ["white"],
                                fontSize: 10,
                                position: "top",
                              },
                            },
                          }}
                        ></Chart>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">
                        Recent Data
                      </h1>
                      <span className="fw-semibold text-white opacity-50"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Recent Users
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        Over {datas.findNoOfuser} Customer
                      </span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">ID</th>
                            <th className="min-w-125px">Full Name</th>
                            <th className="min-w-150px">Email </th>
                            <th className="min-w-150px">Contact No. </th>
                            <th className="min-w-100px">Date of creation</th>
                            <th className="min-w-100px ">Status</th>
                            <th className="min-w-100px text-end">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {users?.map((item) => (
                            <tr>
                              <td>{item._id}</td>
                              <td>{item.fullname}</td>
                              <td>{item.email} </td>
                              <td>
                                <span>{item.phone} </span>
                              </td>
                              <td>
                                {moment(item.createdAt).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                {item.status == "pending" ? (
                                  <>
                                    <span className="badge badge-light-pending fs-5">
                                      {item.status?.charAt(0).toUpperCase() +
                                        item.status?.slice(1)}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span className="badge badge-light-info fs-5">
                                      {item.status?.charAt(0).toUpperCase() +
                                        item.status?.slice(1)}
                                    </span>
                                  </>
                                )}
                              </td>
                              <td>
                                <div className="d-flex justify-content-end flex-shrink-0">
                                  <a
                                    href="#"
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AgentViewOrg;
