import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import { error } from "jquery";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
var jwt = require("jsonwebtoken");

function EmpolyeesRegister({ hadlerorg }) {
  const navigate = useNavigate();
  let token = localStorage.getItem("organizationToken");

  const AddFormData = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());

    Object.keys(Formvlaues).forEach((key) => {
      Formvlaues[key] = Formvlaues[key] === "on" ? 1 : Formvlaues[key];
      Formvlaues[key] = Formvlaues[key] === "off" ? 0 : Formvlaues[key];
    });

    axios
      .post(`/v1/org/CRM/Create-Employee`, Formvlaues, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            toast.success(data.msg);
            navigate("/EmpolyListORG");

            e.target.reset();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      })
      .catch(error);
  };

  const [Other, setOther] = useState("");

  function changeOther(event) {
    setOther(event.target.value);
  }

  useEffect(() => {
    hadlerorg();
  }, []);

  const [Blockhandler, setBlockhandler] = useState(false);
  const [formbutton, setformbutton] = useState(true);

  const VerfiyBlock = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    try {
      const response = await axios.post(
        `/v1/ChromePay855658ADKI/kacha/verify-Password`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      let data = response.data;
      if (data.status) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${data.message}`,
          showConfirmButton: false,
          timer: 4500,
        });

        setBlockhandler(false);
        setformbutton(false);

        return;
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        setBlockhandler(false);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Add Employee</h3>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={(e) => AddFormData(e)}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9">
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            First Name
                          </label>
                          <div className="col-lg-8">
                            <div className="row">
                              <div className="col-lg-12 fv-row">
                                <input
                                  type="text"
                                  name="first_name"
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder=" First Name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            {" "}
                            Last Name
                          </label>
                          <div className="col-lg-8">
                            <div className="row">
                              <div className="col-lg-12 fv-row">
                                <input
                                  type="text"
                                  name="last_name"
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span className="">Contact No.</span>
                            <i
                              className="fas fa-exclamation-circle ms-1 fs-7"
                              data-bs-toggle="tooltip"
                              title="Phone number must be active"
                            ></i>
                          </label>
                          <div className="col-lg-8 fv-row">
                            <PhoneInput
                              country={"eg"}
                              enableSearch={true}
                              inputProps={{
                                name: "phone",
                                required: true,
                                placeholder: "Enter Contact No.",
                              }}
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Contact No."
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Employee Type
                          </label>
                          <div className="col-lg-8 fv-row">
                            <select
                              onChange={changeOther}
                              className="form-select border-0 flex-grow-1"
                              data-control="select2"
                              data-placeholder="Please select"
                              data-hide-search="true"
                              name="employee_type"
                            >
                              <option value="" selected disabled>
                                Please select
                              </option>
                              <option value="Agent">Agent</option>
                              <option value="Office Worker">
                                Office Worker{" "}
                              </option>
                              <option value="Loan Collector">
                                Loan Collector
                              </option>
                              <option value="Loan Dealer">Loan Dealer</option>
                              <option value="Cashier">Cashier</option>
                              <option value="DID Manager">DID Manager</option>
                              <option value="other">Other</option>
                            </select>
                            {Other == "other" ? (
                              <>
                                <input
                                  type="text"
                                  name="profession"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Employee Type"
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Email Address
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="email"
                              name="email"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Email Address"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Password
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="password"
                              name="password"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Password"
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Confirm Password
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="password"
                              name="confirmPassword"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Confirm Password"
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Is this Employee allowed to create a add
                                customer Network?
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="add_customer"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Is this Employee allowed to approve customer?
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="approve_customer"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Is this Employee allowed to block customer.
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="block_customer"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Is this Employee allowed to delete customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="delete_customer"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                {" "}
                                Is this Employee allowed to Create Digital-ID
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="createdigitalID"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        {formbutton ? (
                          <button
                            type="button"
                            className="btn btn-light btn-sub me-2"
                            onClick={() => setBlockhandler(true)}
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-light btn-sub me-2"
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="modal-center"
            show={Blockhandler}
            onHide={() => setBlockhandler(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Verify Password</Modal.Title>
            </Modal.Header>
            <form onSubmit={VerfiyBlock}>
              <Modal.Body>
                <div className="col-lg-12 ">
                  <div className="row">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      {" "}
                      Password
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="password"
                        name="password"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Enter password"
                        required
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit" className="center">
                  {" "}
                  Submit
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
}
export default EmpolyeesRegister;
