import React from 'react'
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { ToastContainer,} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function OrgLatesttransaction({hadlerorg}) {
    const {_id} = useParams();
    console.log(_id);
    
    const [data , setData] = useState([]);
 
 const userlist = async () =>{
     const {transection_ID} ={transection_ID :_id}
     await axios.post(`/Transection_detail/${transection_ID}`)
     .then(resp =>{
         let data =resp.data.transection_detail;
         setData(data);
         console.log(data);
     })
 
 }
 
     useEffect(() => {
         userlist();
         hadlerorg();
     }, []) 
  return (
    <>


            <ToastContainer />
      <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                    <div className="container-xxl" >
                        <div className="row g-5 g-xl-8">
                            <div className="col-lg-12">
                                <div className="card mb-5 mb-xl-10">
                                    <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                                        <div className="card-title m-0">
                                            <h3 className="fw-bold m-0">Transaction Details</h3>
                                        </div>
                                    </div>
                                    <div id="kt_account_settings_profile_details" className="collapse show">
                                        <form  id="kt_account_profile_details_form" className="form" >
                                            <div className="card-body border-top p-9">


                                                <div className="row mb-8">


                                                    <div className="col-lg-6 mb-6">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Transaction ID</label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input type="text" name="transactionID" value={data.transactionID} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Transaction ID" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Sender ID </label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input type="text" name="senderID" value={data.senderID} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Sender ID" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6  mb-8 ">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">Reciever ID</label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input type="text" name="recieverID" value={data.recieverID} className="form-control form-control-lg form-control-solid" placeholder="Reciever ID" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6  mb-8 ">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                                                <span className="required">Transaction Date</span>
                                                                <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i>
                                                            </label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input type="text" name="transactionDate" value={data.transactionDate} className="form-control form-control-lg form-control-solid" placeholder="Transaction Date" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">PCN</label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input type="text" name="PCN" value={data.PCN} className="form-control form-control-lg form-control-solid" placeholder="PCN" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-6">
                                                        <div className="row ">
                                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">Sender Name</label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input type="text" name="senderName" value={data.senderName} className="form-control form-control-lg form-control-solid" placeholder="senderName" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-6">
                                                        <div className="row ">
                                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">Reciever Name</label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input type="text" name="recieverName" value={data.recieverName} className="form-control form-control-lg form-control-solid" placeholder="recieverName" />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 ">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">SendingAmount </label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input type="number" name="sendingAmount" value={data.sendingAmount} className="form-control form-control-lg form-control-solid position-relative" placeholder="sendingAmount" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-6 mb-6">
                                                        <div className="row ">
                                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">ReceivingAmount</label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input type="text" name="receivingAmount" value={data.receivingAmount} className="form-control form-control-lg form-control-solid" placeholder="receivingAmount" />
                                                            </div>
                                                        </div>
                                                    </div>


                                                  
                                                    <div className="col-lg-6 ">
                                                <div className="row">
                                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">CreatedAt</label>
                                                    <div className="col-lg-8 fv-row">
                                                    <input type="text" name="createdAt" value={data.createdAt}  className="form-control form-control-lg form-control-solid position-relative" placeholder="createdAt" />
                                                    </div>
                                                </div>
                                                
                                            </div>

                                                    <div className="col-lg-6 mb-6">
                                                        <div className="row ">
                                                            <label className="col-lg-4 col-form-label fw-semibold fs-6">Status</label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input type="text" name="status"  className="form-control form-control-lg form-control-solid"  readOnly placeholder={data.status} />
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>



                                              

                                                








                                            </div>
                                          
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">

<div className="container-xxl d-flex flex-column flex-md-row flex-stack">

    <div className="text-dark order-2 order-md-1">
        <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">Created by</span>
        <a href="#" className="text-muted text-hover-primary fw-semibold me-2 fs-6">Chromepay</a>
    </div>


    <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
        <li className="menu-item">
            <a href="#" className="menu-link px-2">About</a>
        </li>
        <li className="menu-item">
            <a href="#" className="menu-link px-2">Support</a>
        </li>

    </ul>

</div>

</div>
                    </div>

                </div>
            </div>
    
    
    
    </>
  )
}
