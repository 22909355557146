// import React from "react";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { error } from "jquery";
import { useNavigate } from "react-router-dom";
import moment from "moment";
var jwt = require("jsonwebtoken");
export default function Header({ showorg }) {
  const [section, setSection] = useState([]);
  const navigate = useNavigate();
  let image = localStorage.getItem("photo");
  let email = localStorage.getItem("email");
  let name = localStorage.getItem("name");

   let token = localStorage.getItem("organizationToken");

  const box = useRef();

  const [Adddocument, setAdddocument] = useState([]);
  const [information, setinformation] = useState("");

  var decode1 = jwt.decode(token);
  // let adminID = decode1.admminID;
  const userlist = () => {
    axios
      .post(
        `/v1/org/dash/get-org-notifi-list`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.data;
        console.log("@@@@" + data);
        setAdddocument(data);
      });
  };

  const Admininformation = () => {
    // axios
    //   .get(`/v1/admin/adminProfile/${adminID}`, { headers: { "Authorization": `Bearer ${token}` } })
    //   .then((resp) => {
    //     let data = resp.data.data;
    //     console.log(" admin dada   ==========>>>>>>>>>>>", data);
    //     setinformation(data);
    //   })
    //   .catch(error);
  };
  const READmeassageadmin = (ID) => {
    axios
      .post(
        `/v1/org/dash/org-read-notification/${ID}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        userlist();
        console.log("1111" + resp);
      })
      .catch(error);
  };

  const Orginformation = async () => {
    await axios
      .post(
        `v1/org/settings/view-org`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.data;
        setinformation(data);
        console.log("+++++++++++", { data });
      });
  };

  useEffect(() => {
    userlist();
    Admininformation();
  }, []);

  useEffect(() => {}, [showorg]);

  useEffect(() => {
    Orginformation();
  }, [token]);

  const Readmeassage = (note, id) => {
    Swal.fire({
      title: "Fuse",
      text: `${note}`,
      padding: "2rem",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Go to page",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(`/notification/org`);
        userlist();
        READmeassageadmin(id);
      } else {
        READmeassageadmin(id);
      }
    });
  };

  // setTimeout(function() { logout() }, 10000)

  // setTimeout()
  const handlernotification = () => {
    box.current.classList.toggle("notification");
  };

  const logout = () => {
    localStorage.clear();
    navigate(`/loginorg`);
    //window.location="/loginorg"
  };
  console.log("user image", image);
  return (
    <>
      <div id="kt_header" className="header align-items-stretch">
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          <div
            className="d-flex align-items-center d-lg-none ms-n3 me-1"
            title="Show aside menu"
          >
            <div
              className="btn btn-icon btn-active-color-primary w-40px h-40px"
              id="kt_aside_toggle"
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.3"
                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <Link to="/organization" className="d-lg-none">
              <img
                alt="Logo"
                src="/assets_new/images/logo.png"
                className="h-50px"
              />
            </Link>
          </div>
          <div className="d-flex align-items-center" id="kt_header_wrapper">
            <div
              className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 pb-5 pb-lg-0"
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_wrapper'}"
            >
              <h1 className="text-dark fw-bold my-1 fs-3 lh-1">
                <i className="fad fa-home-lg fs-3"></i> &nbsp;Welcome Back!
              </h1>
            </div>
          </div>

          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
            <div
              className="d-flex align-items-stretch"
              id="kt_header_nav"
            ></div>
            <div className="d-flex align-items-stretch justify-self-end flex-shrink-0">
              <div className="d-flex align-items-center ms-1 ms-lg-3">
                <div
                  className="btn btn-icon btn-active-light-primary position-relative btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                  data-kt-menu-trigger="click"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                  onClick={handlernotification}
                >
                  <span className="svg-icon svg-icon-1">
                    <span className="Card-design position-relative">
                      {/* <i className="fad fa-bell-on fs-4"> </i> */}
                      <i className="fas fa-bell fs-4"></i>
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {Adddocument?.length}
                      </span>
                    </span>
                  </span>
                </div>

                <div
                  ref={box}
                  className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px "
                  data-kt-menu="true"
                >
                  <div
                    className="d-flex flex-column bgi-no-repeat rounded-top"
                    style={{ background: "#3f8891" }}
                  >
                    <h3 className="text-white fw-semibold px-9 mt-10 mb-6">
                      Notifications
                      <span className="fs-8 opacity-75 ps-3">
                        {" "}
                        {Adddocument?.length} reports
                      </span>
                    </h3>
                  </div>

                  <div className="tab-content">
                    <div
                      className=""
                      id="kt_topbar_notifications_1"
                      role="tabpanel"
                    >
                      <div className="scroll-y mh-325px my-5 px-8">
                        {Adddocument?.map((item) => (
                          <div
                            className="d-flex flex-stack py-4"
                            key={item._id}
                          >
                            <div className="d-flex align-items-center">
                              <div className="symbol symbol-35px me-4">
                                <span className="symbol-label bg-light-primary">
                                  <span className="svg-icon svg-icon-2 svg-icon-primary">
                                    {/* <img
                                      src={item.organisation?.logo}
                                      width="24"
                                      height="24"

                                      onClick={() =>
                                        Readmeassage(
                                          item.note,
                                          item.organisation?.name,
                                          item._id,
                                          item.createdAt
                                        )
                                      }

                                    /> */}
                                    <i class="fas fa-bell fs-4"></i>
                                  </span>
                                </span>
                              </div>

                              <div
                                className="mb-0 me-2"
                                onClick={() =>
                                  Readmeassage(
                                    item.note,
                                    //  item.organisation?.name,
                                    item._id,
                                    item.createdAt
                                  )
                                }
                              >
                                <a
                                  href="#"
                                  className="fs-6 text-gray-800 text-hover-primary fw-bold"
                                >
                                  {/* {item.organisation?.name} */}
                                  {/* Admin */}
                                </a>
                                <div
                                  className="text-gray-400 fs-7"
                                  onClick={() =>
                                    Readmeassage(
                                      item.note,
                                      // item.organisation?.name,
                                      item._id,
                                      item.createdAt
                                    )
                                  }
                                >
                                  {item.note}
                                </div>
                                <div
                                  className="text-gray-400 fs-7"
                                  onClick={() =>
                                    Readmeassage(
                                      item.note,
                                      //  / item.organisation?.name,
                                      item._id,
                                      item.createdAt
                                    )
                                  }
                                >
                                  {moment(item.createdAt).format(
                                    "DD-MM-YYYY,h:mm a"
                                  )}
                                </div>
                              </div>
                            </div>

                            <span
                              className="badge badge-light fs-8"
                              onClick={() =>
                                Readmeassage(
                                  item.note,
                                  // item.organisation?.name,
                                  item._id,
                                  item.createdAt
                                )
                              }
                            >
                              <i class="fas fa-chevron-right"></i>
                            </span>
                          </div>
                        ))}
                      </div>

                      <div className="py-3 text-center border-top">
                        <Link
                          to="/notification/org"
                          className="btn btn-color-gray-600 btn-active-color-primary"
                        >
                          View All
                          <span className="svg-icon svg-icon-5">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x="18"
                                y="13"
                                width="13"
                                height="2"
                                rx="1"
                                transform="rotate(-180 18 13)"
                                fill="currentColor"
                              />
                              <path
                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center ms-1 ms-lg-3  mr-3"></div>
              <div className="d-flex align-items-center ms-1 ms-lg-3">
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
                  data-kt-menu="true"
                ></div>
              </div>

              <div
                className="d-flex align-items-center ms-1 ms-lg-3 icon"
                id="kt_header_user_menu_toggle"
              >
                <div
                  className="cursor-pointer symbol symbol-30px symbol-md-40px"
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                >
                  <img
                    src={localStorage.getItem("org-image")}
                    alt="user"
                    id="imgprofile"
                  />
                </div>

                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px userprofile "
                  data-kt-menu="true"
                >
                  <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                      <div className="symbol symbol-50px me-5">
                        {/* <img alt="Logo" src= {information?.logo} /> */}
                      </div>
                      <div className="d-flex flex-column">
                        <div className="fw-bold d-flex align-items-center fs-5">
                          {information?.name}
                          <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                            Organization
                          </span>
                        </div>
                        <Link
                          to="/organization"
                          className="fw-semibold text-muted text-hover-primary fs-7"
                        >
                          {/* {localStorage.getItem('org-email')} */}
                          {information?.email}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="separator my-2"></div>

                  <div className="menu-item px-5">
                    <Link to="/my-profile-org" className="menu-link px-3">
                      My Profile
                    </Link>
                  </div>

                  {/* <div className="menu-item px-5 my-1">
                    <Link to="/org-change-password" className="menu-link px-3">
                      Account Settings
                    </Link>
                  </div> */}

                  <div className="menu-item px-5">
                    <div
                      onClick={logout}
                      style={{ textIndent: "-5px" }}
                      className="menu-link px-5"
                    >
                      Sign Out
                    </div>
                  </div>
                  <div className="separator my-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
