import React from "react";

import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import moment from "moment";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";

function AdminLogsList({ hadleradmin }) {
  let token = localStorage.getItem("ChromePayAdmitToken");
  const [pageCount, setpageCount] = useState("");
  const [ShowLoader, setShowLoader] = useState(true);
  let ID = localStorage.getItem("ID");
  const [data, setData] = useState([]);

  const limit = 10;



  const userlist = async () => {
    await axios
      .post(
        `/v1/admin/chrome_pay_logs`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp?.data?.filter;
        setData(data);
        const total = resp?.data?.totlaRow;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };

    axios
      .post(`/v1/admin/chrome_pay_logs`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp?.data?.filter;
        setData(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };




  useEffect(() => {
    userlist();
    hadleradmin();
  }, []);

  return (
    <>
      <CustomerLoader loader={ShowLoader} />
      <ToastContainer />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                 
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Chromepay Logs list
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">D-ID</th>
                            <th className="min-w-125px">Throw</th>
                            <th className="min-w-150px">IP Address</th>
                            <th className="min-w-150px">Api </th>

                            <th className="min-w-100px">Date</th>
                            <th className="min-w-100px ">Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data?.map((item) => (
                            <tr>
                              <td className="text-center">{item?._id}</td>
                              <td className="text-center">{item?.BY}</td>
                              <td className="text-center">{item?.IPAdress}</td>
                              <td className="text-center">
                                <span>{item?.url}</span>{" "}
                              </td>

                              <td className="text-center">{moment(item?.time).format("DD/MM/YYYY")}</td>
                              <td className="text-center">
                                <span className="badge badge-light-info fs-5 capitalize-text">
                                  {item?.status}
                                </span>
                              </td>
                             
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLogsList;
