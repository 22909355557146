import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ReactPaginate from "react-paginate";
import Chart from "react-apexcharts";
import { useState, useRef, useEffect } from "react";
import { JWT } from "jsonwebtoken";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { error } from "jquery";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import moment from "moment";
var jwt = require("jsonwebtoken");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function SuperAgentView({ hadlerorg }) {
  const { _id } = useParams("");
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRefDocuments = useRef(null);
  const fileInputRefprofile = useRef(null);
  const [data, setData] = useState([]);
  const [document, setdocument] = useState([]);
  const [transmonths, settransmonths] = useState([]);
  const [transvalue, settransvalue] = useState([]);
  const [translist, settranslist] = useState([]);
  const [Query, setQuery] = useState("");
  const [show, setshow] = useState(false);
  const [photo, setphoto] = useState("");
  const [pageCount, setpageCount] = useState("");
  const [Blockhandler, setBlockhandler] = useState(false);
  const [Orguselistdata, setOrguselistdata] = useState([]);
  const [imagepassword, setimagepassword] = useState(0);
  const [show1, setShow1] = useState(false);
  const [showDoc, setshowDoc] = useState(false);
  const [Docuserid, setDocuserid] = useState(false);
  let token = localStorage.getItem("organizationToken");
  let orgAgentType = localStorage.getItem("orgAgentType");

  const limit = 10;
  const userlist = () => {
    const { agentId } = { agentId: _id };
    axios
      .post(
        `v1/org/CRM/get-super-agent-detail`,
        { agentId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp?.data?.data[0];
        setData(data);
      })
      .catch(error);
  };

  // graph show
  const [frequency, setFrequency] = useState("month");
  const [useractivedatayear, setuseractivedatayear] = useState([]);
  const [useractivedatamonths, setuseractivedatamonths] = useState([]);

  const UserPermoanceyear = () => {
    let agentId = _id;
    let type = frequency;
    const body = { agentId: agentId, Type: type };

    axios
      .post(`/v1/org/CRM/org-superagent-performance-graph`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const userData = res.data.data;
        const count = userData.map((item) => item?.count);
        const month = userData.map((item) => item?.value);
        setuseractivedatayear(count);
        setuseractivedatamonths(month);
      });
  };

  useEffect(() => {
    UserPermoanceyear();
  }, [frequency]);

  const handlesorting = (event) => {
    let sort = event.target.value;
    setFrequency(sort);
    UserPermoanceyear(sort);
  };

  const userdoc = () => {
    const { orgID } = { orgID: _id };
    axios
      .post(`/v1/admin/viewDoc/${orgID}`)
      .then((resp) => {
        let data = resp?.data?.findDoc;
        setdocument(data);
      })
      .catch(error);
  };

  const Orguselist = async () => {
    const { agentId } = { agentId: _id };
    await axios
      .post(
        `/v1/org/CRM/get-super-agent-loans`,
        { agentId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.data;
        setOrguselistdata(data);
        const total = resp.data.totalRow;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };

  const fetchComments = async (page) => {
    const senData = { page: page, agentId: _id };
    axios
      .post(`/v1/org/CRM/get-super-agent-loans`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;
        setOrguselistdata(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  const Transactionuselist = () => {
    const { agentId } = { agentId: _id };
    axios
      .post(
        `/v1/org/CRM/get-super-agent-cust-loans`,
        { agentId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data11 = resp?.data?.data;
        settranslist(data11);
        const total = resp?.data?.totlaRow;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      })
      .catch(error);
  };

  const fetchCommentspage = async (page) => {
    const { orgID } = { orgID: _id };
    const senData = { page: page };
    axios.post(`/v1/admin/get_transctions/${orgID}`, senData).then((resp) => {
      let data11 = resp.data.findCust;
      settranslist(data11);
    });
    return data;
  };

  const handlePageClickpage = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchCommentspage(page);
  };

  const Transactionuse = () => {
    const { orgID } = { orgID: _id };
    axios
      .get(`/v1/admin/get_org_transections_months/${orgID}`)
      .then((resp) => {
        let data = resp.data.obj;
        settransmonths(Object.keys(data));
        settransvalue(Object.values(data));
      })
      .catch(error);
  };

  // document show function

  const imageview = (image) => {
    setphoto(image);
    setshow(true);
  };

  // api call
  useEffect(() => {
    userlist();
    userdoc();
    Transactionuse();
    Transactionuselist();
    hadlerorg();
    Orguselist();
  }, []);

  // document updated

  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event, key) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name;
    }
    // Handle file change, you can upload the file or perform other actions
    let formdata = new FormData();
    formdata.append("image", selectedFile);

    console.log("Selected file:", selectedFile);
    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        if (res.data.status) {
          handleupdatedata(imgurl);
        }
      })
      .catch(error);
  };

  const handleupdatedata = (image) => {
    let formdata = {
      type: "licenceCertificate",
      image: image,
      superAgentId: _id,
    };
    axios
      .put(`/v1/org/CRM/update-super-agent-doc-by-org`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data = res.data;
        console.log({ data });
        toast.success(data.msg);
        userlist();
      })
      .catch(error);
  };

  const handleEditClick1 = () => {
    fileInputRef1.current.click();
  };

  const handleFileChange1 = (event, key) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name;
    }
    // Handle file change, you can upload the file or perform other actions
    let formdata = new FormData();
    formdata.append("image", selectedFile);
    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        if (res.data.status) {
          handleupdatedata1(imgurl);
        }
      })
      .catch(error);
  };

  const handleupdatedata1 = (image) => {
    let formdata = {
      type: "membershipRegistration",
      image: image,
      superAgentId: _id,
    };
    axios
      .put(`/v1/org/CRM/update-super-agent-doc-by-org`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data = res.data;
        toast.success(data.msg);
        userlist();
      })
      .catch(error);
  };

  const handleEditClick2 = () => {
    fileInputRef2.current.click();
  };

  const handleFileChange2 = (event, key) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name;
    }
    // Handle file change, you can upload the file or perform other actions
    let formdata = new FormData();
    formdata.append("image", selectedFile);

    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        if (res.data.status) {
          handleupdatedata2(imgurl);
        }
      })
      .catch(error);
  };

  const handleupdatedata2 = (image) => {
    let formdata = {
      type: "applicationLetter",
      image: image,
      superAgentId: _id,
    };
    axios
      .put(`/v1/org/CRM/update-super-agent-doc-by-org`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data = res.data;
        toast.success(data.msg);
        userlist();
      })
      .catch(error);
  };

  const handleEditClickprofile = () => {
    fileInputRefprofile.current.click();
  };

  const handleFileChangeprofileupdated = (event, docId) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
    }
    let formdata = new FormData();
    formdata.append("image", selectedFile);
    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        if (res.data.status) {
          handleupdateprofileApi(imgurl);
        }
      })
      .catch(error);
  };

  const handleupdateprofileApi = (imgurl) => {
    let formdata = {
      image: imgurl,
      agentId: _id,
    };
    axios
      .post(`/v1/org/CRM/update-profile-picture`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data = res.data;
        console.log({ data });
        toast.success(data.msg);
        userlist();
      })
      .catch(error);
  };

  // delect document
  const HandlerDeleteDocument = async () => {
    const formData = {
      agentId: _id,
      docId: Docuserid,
    };
    try {
      const response = await axios.delete(`/v1/org/CRM/delete-doc`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      let data = response.data;
      if (data.status) {
        toast.success(data.message);
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        return userlist();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    }
  };

  const VerfiyBlock = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    try {
      const response = await axios.post(
        `/v1/ChromePay855658ADKI/kacha/verify-Password`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setBlockhandler(false);
      let data = response.data;
      if (data.status) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${data.message}`,
          showConfirmButton: false,
          timer: 2000,
        });
        if (imagepassword == 0) {
          return handleEditClick();
        } else if (imagepassword == 1) {
          return handleEditClick1();
        } else if (imagepassword == 2) {
          return handleEditClick2();
        } else if (imagepassword == 3) {
          setTimeout(() => {
            setShow1(true);
          }, 4000);
        } else if (imagepassword == 4) {
          ADDdocumentupload();
          userlist();
        } else if (imagepassword == 5) {
          handleEditClickDocument();
        } else if (imagepassword == 6) {
          handleEditClickprofile();
        } else if (imagepassword == 7) {
          HandlerDeleteDocument();
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        setBlockhandler(false);
      }
    }
  };

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo (Congo-Kinshasa)",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor (Timor-Leste)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini (fmr. 'Swaziland')",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia (formerly Macedonia)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  // super agents details updated

  const AddformupdatesuperAgents = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());

    data.append(
      "lincenseCertificateNumber",
      parseInt(Formvlaues.lincenseCertificateNumber)
    );
    data.append(
      "cooperativeLicenseFee ",
      parseInt(Formvlaues.cooperativeLicenseFee)
    );
    const formData = Formvlaues;
    axios
      .post(`/v1/org/CRM/update-super-agent-profile-by-org`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data;
        if (data.status) {
          toast.success(data.msg);
          setShow1(false);
          return userlist();
        } else {
          toast.error(data.msg);
        }
      });
  };

  // Add document super agents

  const [AddDocumentimageurl, setAddDocumentimageurl] = useState("");
  const [AddDocumentName, setAddDocumentName] = useState("");

  const handleFileChangeDocument = (event, key) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name;
    }
    let formdata = new FormData();
    formdata.append("image", selectedFile);
    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        if (res.data.status) {
          setAddDocumentimageurl(imgurl);
        }
      })
      .catch(error);
  };

  const ADDdocumentupload = () => {
    let formdata = {
      docName: AddDocumentName,
      docImage: AddDocumentimageurl,
      superAgentId: _id,
    };
    axios
      .post(`/v1/org/CRM/add-super-agent-doc`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data = res.data;
        toast.success(data.msg);
      })
      .catch(error);
  };

  const handleEditClickDocument = () => {
    fileInputRefDocuments.current.click();
  };

  const handleFileChangeDocumentUpdated = (event, docId) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
    }
    let formdata = new FormData();
    formdata.append("image", selectedFile);
    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        if (res.data.status) {
          handleupdateDocument(imgurl, docId);
        }
      })
      .catch(error);
  };

  const handleupdateDocument = (docImage) => {
    let formdata = {
      docId: Docuserid,
      docImage: docImage,
      superAgentId: _id,
    };
    axios
      .post(`/v1/org/CRM/update-extra-doc`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data = res.data;
        toast.success(data.msg);
        userlist();
      })
      .catch(error);
  };
  return (
    <>
      <ToastContainer position="top-right" />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="card mb-12 rounded-15 bgi-no-repeat bgi-position-x-end 
        bgi-size-cover bg_card"
                    >
                      <div className="card-body flex-column p-5">
                        <div className="d-flex align-items-center ">
                          <div className="card-body  pt-9 pb-0 ">
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                              <div className="me-7 mb-4">
                                <div className="symbol border border-gray-300 border-dashed symbol-100px symbol-lg-160px symbol-fixed position-relative text-white">
                                  <img
                                    src={data.Image}
                                    alt="image"
                                    className="rounded-circle mx-2"
                                  />

                                  <input
                                    type="file"
                                    name="applicationLetter"
                                    ref={fileInputRefprofile}
                                    style={{ display: "none" }}
                                    onChange={(event) =>
                                      handleFileChangeprofileupdated(event)
                                    }
                                  />

                                  <button
                                    className="editbtn"
                                    onClick={(e) => [
                                      setBlockhandler(true),
                                      setimagepassword(6),
                                    ]}
                                  >
                                    <i className="fas fa-pen"></i>
                                  </button>
                                </div>
                              </div>

                              <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                  <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-2">
                                      <a
                                        href="#"
                                        className="-900 text-hover-primary fs-2 text-white fw-bold me-1"
                                      >
                                        {data.firstName} {data.lastName}
                                      </a>
                                    </div>
                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                              fill="currentColor"
                                            />
                                            <rect
                                              x="7"
                                              y="6"
                                              width="4"
                                              height="4"
                                              rx="2"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {"Super Agent"}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        <span className="svg-icon text-white  svg-icon-4 me-1">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {data.country || "Ethiopia"}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-white mb-2"
                                      >
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {data.email}
                                      </a>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex flex-wrap flex-stack">
                                  <div className="d-flex flex-column flex-grow-1 pe-8">
                                    <div className="d-flex flex-wrap">
                                      {orgAgentType == "Union" ? (
                                        ""
                                      ) : (
                                        <>
                                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <div className="d-flex align-items-center">
                                              <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                                <svg
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <rect
                                                    opacity="0.5"
                                                    x="13"
                                                    y="6"
                                                    width="13"
                                                    height="2"
                                                    rx="1"
                                                    transform="rotate(90 13 6)"
                                                    fill="currentColor"
                                                  />
                                                  <path
                                                    d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                                    fill="currentColor"
                                                  />
                                                </svg>
                                              </span>

                                              <div
                                                className="fs-2 fw-bold text-white"
                                                data-kt-countup="true"
                                                data-kt-countup-prefix="$"
                                              >{`$${data.wallet?.amount}`}</div>
                                            </div>

                                            <div className="fw-semibold fs-6 -400 text-white">
                                              Wallet Amount
                                            </div>
                                          </div>

                                          <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                            <div className="d-flex align-items-center">
                                              <span className="svg-icon svg-icon-3 svg-icon-danger me-2">
                                                <svg
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <rect
                                                    opacity="0.5"
                                                    x="11"
                                                    y="18"
                                                    width="13"
                                                    height="2"
                                                    rx="1"
                                                    transform="rotate(-90 11 18)"
                                                    fill="currentColor"
                                                  />
                                                  <path
                                                    d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                                    fill="currentColor"
                                                  />
                                                </svg>
                                              </span>
                                              <div
                                                className="fs-2 fw-bold text-white"
                                                data-kt-countup="true"
                                                data-kt-countup-prefix="$"
                                              >{`$${0}`}</div>
                                            </div>

                                            <div className="fw-semibold fs-6 -400 text-white">
                                              Total Transaction{" "}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      {data.orgType == "Cooperative" ? (
                                        <div class="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                          <div class="d-flex align-items-center justify-content-center">
                                            <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                            <div
                                              class="fs-2 fw-bold text-white"
                                              data-kt-countup="true"
                                              data-kt-countup-prefix="$"
                                            >
                                              {data?.orgType || " N/A"}
                                            </div>
                                          </div>
                                          <div class="fw-semibold fs-6 text-white text-center">
                                            &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                                            {data?.cooperativeType || " N/A"}
                                            &nbsp; &nbsp; &nbsp; &nbsp;
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {data.orgType == "Cooperative" ? (
                                        <div class="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                          <div class="d-flex align-items-center justify-content-center">
                                            <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                            <div
                                              class="fs-2 fw-bold text-white"
                                              data-kt-countup="true"
                                              data-kt-countup-prefix="$"
                                            >
                                              {data?.lincenseCertificateNumber ||
                                                " N/A"}
                                            </div>
                                          </div>
                                          <div class="fw-semibold fs-6 text-white text-center">
                                            &nbsp; &nbsp; &nbsp; &nbsp; License
                                            Certificate Numbers &nbsp; &nbsp;
                                            &nbsp; &nbsp; &nbsp; &nbsp;
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="d-flex mt-3">
                                      <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div class="d-flex align-items-center justify-content-center">
                                          <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            class="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${
                                              data?.shares !== undefined
                                                ? data?.shares.toFixed(2)
                                                : "N/A"
                                            }`}
                                          </div>
                                        </div>
                                        <div class="fw-semibold fs-6 text-white text-center">
                                          Shares
                                        </div>
                                      </div>
                                      <div class="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div class="d-flex align-items-center justify-content-center">
                                          <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            class="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${
                                              data?.Loans?.toFixed(2) || " N/A"
                                            }`}
                                          </div>
                                        </div>
                                        <div class="fw-semibold fs-6 text-white text-center">
                                          Loans
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex-equal d-flex justify-content-center align-items-end ms-5">
                            <img
                              src="../../assets/media/illustrations/dozzy-1/20.png"
                              alt=""
                              className="mw-100 mh-125px mh-lg-275px mb-lg-n12"
                            />
                          </div>
                        </div>

                        <div className="card-rounded  col-12 d-flex flex-stack flex-wrap p-5 pb-0">
                          <ul
                            className="nav flex-wrap border-transparent fw-bold"
                            role="tablist"
                          >
                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#overview"
                                className="btn btn-color-gray-600 btn-active-secondary  active btn-active-color-primary fw-bolder fs-5 fs-lg-base nav-link px-3 px-lg-7 mx-1 "
                              >
                                Overview
                              </a>
                            </li>

                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#documents"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-5 fs-lg-base nav-link px-3 px-lg-7 mx-1 "
                              >
                                Super Agent Documents
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="overview"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-title m-0">
                                <h3 className="fw-bold m-0">
                                  Super Agent Details
                                </h3>
                              </div>

                              <div className="mt-3">
                                <button
                                  onClick={(e) => [
                                    setBlockhandler(true),
                                    setimagepassword(3),
                                  ]}
                                  className="btn "
                                  style={{ height: "50px" }}
                                >
                                  <i class="fas fa-edit fs-51"></i>
                                </button>
                              </div>
                            </div>

                            <div className="card-body p-9">
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Super Agent Name
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-bold fs-6 -800">
                                    {data.firstName} {data.lastName}
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Code
                                </label>

                                <div className="col-lg-8 fv-row">
                                  <span className="fw-semibold -800 fs-6">
                                    {data.AgentCode}
                                  </span>
                                </div>
                              </div>
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Joining Date
                                </label>

                                <div className="col-lg-8 fv-row">
                                  <span className="fw-semibold -800 fs-6">
                                    {moment(data.createdAt).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Email Address
                                </label>

                                <div className="col-lg-8 fv-row">
                                  <span className="fw-semibold -800 fs-6">
                                    {data.email}
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Contact No.
                                  <i
                                    className="fas fa-exclamation-circle ms-1 fs-7"
                                    data-bs-toggle="tooltip"
                                    title="Phone number must be active"
                                  ></i>
                                </label>

                                <div className="col-lg-8 d-flex align-items-center">
                                  <span className="fw-bold fs-6 -800 me-2">
                                    +{data.phone}
                                  </span>
                                  <span className="badge badge-success">
                                    Verified
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Address
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-semibold fs-6 -800 text-hover-primary">
                                    {data.address || "Ethiopia"}
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  city
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-semibold fs-6 -800 text-hover-primary">
                                    {data.city || "Ethiopia"}
                                  </span>
                                </div>
                              </div>
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Country
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-semibold fs-6 -800 text-hover-primary">
                                    {data.country || "Ethiopia"}
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Registration Fee
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-semibold fs-6 -800 text-hover-primary">
                                    {data.registrationFee || "0"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-title m-0">
                                <h3 className="fw-bold m-0">
                                  Super Agent Activity
                                </h3>
                              </div>
                              <div className="col-md-6 text-end mt-5">
                                <form>
                                  <div className=" align-items-end filterCss gap-1 ">
                                    <div>
                                      <select
                                        defaultValue={frequency}
                                        onChange={handlesorting}
                                        className="form-select graph-select"
                                      >
                                        <option value="day"> Day</option>
                                        <option value="week"> Week</option>
                                        <option value="month"> Monthly</option>
                                        <option value="year">Yearly</option>
                                      </select>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>

                            <div className="card-body p-9">
                              <div className="bg-white overflow-auto mt-md-5 pt-md-5">
                                <Chart
                                  type="bar"
                                  width={"100%"}
                                  height={300}
                                  series={[
                                    {
                                      name: "User",
                                      data: useractivedatayear,
                                    },
                                  ]}
                                  options={{
                                    plotOptions: {
                                      bar: {
                                        borderRadius: 5,
                                        columnWidth: 40,
                                        startingShape: "flat",
                                        endingShape: "rounded",
                                        dataLabels: {
                                          position: "top",
                                        },
                                      },
                                    },

                                    title: {},

                                    subtitle: {},

                                    colors: ["#3c7f8c"],
                                    theme: { mode: "light" },

                                    xaxis: {
                                      tickPlacement: "on",
                                      categories: useractivedatamonths,
                                    },

                                    yaxis: {
                                      labels: {
                                        formatter: (val) => {
                                          return `${val}`;
                                        },
                                        style: {
                                          fontSize: "15",
                                          colors: ["#3c7f8c"],
                                        },
                                      },
                                      title: {},
                                    },

                                    legend: {
                                      show: true,
                                      position: "right",
                                    },

                                    dataLabels: {
                                      formatter: (val) => {
                                        return `${val}`;
                                      },
                                      style: {
                                        colors: ["white"],
                                        fontSize: 10,
                                        position: "top",
                                      },
                                    },
                                  }}
                                ></Chart>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="documents"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-12 mb-4 row">
                          <div className="col-md-6 col-lg-6 col-sm-6">
                            <h3 className="fw-bold my-2">
                              User Documents
                              <span className="fs-6 -400 fw-semibold ms-1"></span>
                            </h3>
                          </div>
                          <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end">
                            <button
                              onClick={() => setshowDoc(true)}
                              className=" Add-doc  btn btn-color-gray-600 btn-secondary btn-color-primary fw-bolder fs-5 fs-lg-base nav-link px-3 px-lg-7 mx-1"
                            >
                              Add Documents
                            </button>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-lg-4 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() =>
                                  imageview(data.licenceCertificate)
                                }
                              >
                                <a className="img_div">
                                  <img
                                    src={data.licenceCertificate}
                                    alt="Licence Certificate"
                                  />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    data-toggle="lightbox"
                                    data-gallery="gallery"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between align-items-center p-3">
                                <h5>
                                  Identification Licence Certificate Image
                                </h5>
                                <div className="d-flex  justify-content-between  align-items-center">
                                  <button
                                    className="btn btn-link me-4"
                                    onClick={(e) => [
                                      setBlockhandler(true),
                                      setimagepassword(0),
                                    ]}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    onChange={(event) =>
                                      handleFileChange(event)
                                    }
                                    name="licenceCertificate"
                                  />
                                  <a
                                    href={data.licenceCertificate}
                                    className="ml-2"
                                    download
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() =>
                                  imageview(data.membershipRegistration)
                                }
                              >
                                <a className="img_div">
                                  <img src={data.membershipRegistration} />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between align-items-center p-3">
                                <h5> membership Registration Image </h5>
                                <div>
                                  <button
                                    className="btn btn-link me-4"
                                    onClick={(e) => [
                                      setBlockhandler(true),
                                      setimagepassword(1),
                                    ]}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <input
                                    type="file"
                                    ref={fileInputRef1}
                                    style={{ display: "none" }}
                                    onChange={(event) =>
                                      handleFileChange1(event)
                                    }
                                  />
                                  <a
                                    href={data.membershipRegistration}
                                    className="ml-2"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() =>
                                  imageview(data.applicationLetter)
                                }
                              >
                                <a className="img_div">
                                  <img src={data.applicationLetter} />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>

                              <div className="d-flex justify-content-between align-items-center p-3">
                                <h5>Application Letter</h5>
                                <div>
                                  <button
                                    className="btn btn-link me-4"
                                    onClick={(e) => [
                                      setBlockhandler(true),
                                      setimagepassword(2),
                                    ]}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <input
                                    type="file"
                                    name="applicationLetter"
                                    ref={fileInputRef2}
                                    style={{ display: "none" }}
                                    onChange={(event) =>
                                      handleFileChange2(event)
                                    }
                                  />
                                  <a
                                    href={data.applicationLetter}
                                    className="ml-2"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          {data?.extraDocs?.map((item) => (
                            <div
                              className="col-lg-4 basicdetail mt-2"
                              key={item?._id}
                            >
                              <div className="carduser">
                                <div
                                  className="text-center"
                                  onClick={() => imageview(item?.docImage)}
                                >
                                  <a className="img_div">
                                    <img
                                      src={item?.docImage}
                                      alt={`Document ${item?._id}`}
                                    />
                                  </a>
                                  <a className="viewimg">
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>

                                <div className="d-flex justify-content-between align-items-center p-3">
                                  <h5>{item?.docName}</h5>
                                  <div>
                                    <button
                                      className="btn btn-link me-4"
                                      onClick={(e) => [
                                        setBlockhandler(true),
                                        setimagepassword(7),
                                        setDocuserid(item?._id),
                                      ]}
                                    >
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                    <button
                                      className="btn btn-link me-4"
                                      onClick={(e) => [
                                        setBlockhandler(true),
                                        setimagepassword(5),
                                        setDocuserid(item?._id),
                                      ]}
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <input
                                      type="file"
                                      name="applicationLetter"
                                      ref={fileInputRefDocuments}
                                      style={{ display: "none" }}
                                      onChange={(event) =>
                                        handleFileChangeDocumentUpdated(
                                          event,
                                          item?._id
                                        )
                                      }
                                    />
                                    <a
                                      href={item?.docImage}
                                      className="ml-2"
                                      download
                                    >
                                      <i
                                        className="fa fa-download"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane fade" id="land" role="tabpanel">
                      <div className="row g-5 g-xl-8">
                        <div className="col-lg-12">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div class="card-header border-0 pt-6 d-flex align-items-center">
                              <h4>Super Agent Loans List</h4>
                            </div>

                            <div class="card-body pt-0">
                              <div className="table-responsive">
                                <table
                                  class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                                  id="kt_customers_table"
                                >
                                  <thead>
                                    <tr class="fw-bold text-muted th-title">
                                      <th className="min-w-125px">D-ID</th>
                                      <th className="min-w-150px">Loan Type</th>
                                      <th className="min-w-150px">
                                        Interest Rate{" "}
                                      </th>
                                      <th className="min-w-100px">EMI</th>
                                      <th className="min-w-100px">
                                        Total Amount
                                      </th>
                                      <th className="min-w-100px">
                                        Duration Year
                                      </th>
                                      <th className="min-w-125px">
                                        Total Interest Amount
                                      </th>
                                      <th className="min-w-100px ">Status</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {Orguselistdata?.map((item) => (
                                      <tr>
                                        <td>
                                          <div class="d-flex align-items-center">
                                            <div class="symbol symbol-45px me-5">
                                              <img
                                                src={item?.agent_detail?.Image}
                                                alt=""
                                              />
                                            </div>
                                            <div class="d-flex justify-content-start flex-column">
                                              <a
                                                href={`/customer-view-admin/${item?.agent_detail?._id}`}
                                                class="usertitle Acolor mb-2"
                                              >
                                                {" "}
                                                {
                                                  item?.agent_detail?.firstName
                                                }{" "}
                                                {item?.agent_detail?.lastName}
                                              </a>
                                              <span class="font-icon mb-1">
                                                <span class="">
                                                  <i class="fal fa-phone-volume"></i>
                                                </span>
                                                +{item?.agent_detail?.phone}
                                              </span>

                                              <span class="font-icon mb-1">
                                                <span class="">
                                                  <i class="fal fa-envelope"></i>
                                                </span>
                                                {item?.agent_detail?.email}{" "}
                                              </span>
                                            </div>
                                          </div>
                                        </td>

                                        <td>{item?.loanType}</td>
                                        <td>
                                          <span>{`${item?.interestRate}%`}</span>{" "}
                                        </td>
                                        <td>{item?.payMethod}</td>
                                        <td>{item?.totalAmount?.toFixed(2)}</td>
                                        <td>{item?.timePeriod}</td>
                                        <td>
                                          {item?.intrestAmount?.toFixed(2)}
                                        </td>
                                        <td>
                                          <span class="badge badge-light-info fs-5">
                                            {item?.status}
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  previousLabel={"previous"}
                                  nextLabel={"next"}
                                  breakLabel={"..."}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={3}
                                  onPageChange={handlePageClick}
                                  containerClassName={
                                    "pagination justify-content-end"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link pagestyle"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane fade" id="finance" role="tabpanel">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="card">
                            <div class="card-header border-0 pt-6 d-flex align-items-center">
                              <h4>Super Agent Customer Loans List</h4>
                            </div>

                            <div class="card-body pt-0">
                              <div className="table-responsive">
                                <table
                                  class="table align-middle table-row-dashed fs-6 gy-5"
                                  id="kt_customers_table"
                                >
                                  <thead>
                                    <tr class="text-start tr_bg text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                      <th className="min-w-125px">D-ID</th>
                                      <th className="min-w-150px">Loan Type</th>
                                      <th className="min-w-150px">
                                        Interest Rate{" "}
                                      </th>
                                      <th className="min-w-100px">EMI</th>
                                      <th className="min-w-100px">
                                        Total Amount
                                      </th>
                                      <th className="min-w-100px">
                                        Duration Year
                                      </th>
                                      <th className="min-w-125px">
                                        Total Interest Amount
                                      </th>

                                      <th class="text-center min-w-70px">
                                        Status
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody class="fw-semibold text-gray-600">
                                    {translist?.map((item) => (
                                      <tr>
                                        <td>
                                          {" "}
                                          <div class="d-flex align-items-center">
                                            <div class="symbol symbol-45px me-5">
                                              <img
                                                src={item?.cust_detail.IDphoto}
                                                alt=""
                                              />
                                            </div>
                                            <div class="d-flex justify-content-start flex-column">
                                              <a
                                                href={`/Agent-customer-view/${item?.cust_detail._id}`}
                                                class="usertitle Acolor mb-2"
                                              >
                                                {" "}
                                                {item?.cust_detail?.fullname}
                                              </a>
                                              <span class="font-icon mb-1">
                                                <span class="">
                                                  <i class="fal fa-phone-volume"></i>
                                                </span>
                                                +{item?.cust_detail?.phone}
                                              </span>

                                              <span class="font-icon mb-1">
                                                <span class="">
                                                  <i class="fal fa-envelope"></i>
                                                </span>
                                                {item?.cust_detail?.email}{" "}
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>{item?.loanType}</td>
                                        <td>
                                          <span>{`${item?.interestRate}%`}</span>{" "}
                                        </td>
                                        <td>{item?.payMethod}</td>
                                        <td>{item?.totalAmount}</td>
                                        <td>{item?.timePeriod}</td>
                                        <td>{item?.intrestAmount}</td>

                                        <td class="text-center">
                                          <span class="badge py-3 px-4 fs-7 badge badge-light-info fs-5">
                                            {item?.status}
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  previousLabel={"previous"}
                                  nextLabel={"next"}
                                  breakLabel={"..."}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={3}
                                  onPageChange={handlePageClickpage}
                                  containerClassName={
                                    "pagination justify-content-end"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  previousClassName={"page-item"}
                                  previousLinkClassName={"page-link pagestyle"}
                                  nextClassName={"page-item"}
                                  nextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="account" role="tabpanel">
                      <div className="row">
                        <div className="col-lg-12 mb-4">
                          <h3 className="fw-bold my-2">
                            User Bank Documents
                            <span className="fs-6 -400 fw-semibold ms-1"></span>
                          </h3>
                        </div>

                        <div className="row mt-4 text-hover-primary"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">Powered by</span>
                    <a
                      href="#"
                      className="text-muted text-hover-primary fw-semibold me-2 fs-6"
                    >
                      Chromepay
                    </a>
                  </div>

                  <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        About
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={show1}
            onHide={() => setShow1(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Super Agent Details</Modal.Title>
            </Modal.Header>
            <form onSubmit={AddformupdatesuperAgents}>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-6 ">
                    <label className="mb-2"> Super Agent Name </label>
                    <input
                      className="form-control"
                      placeholder="Enter your email"
                      type="hidden"
                      name="superAgentId"
                      defaultValue={_id}
                    />
                    <input
                      className="form-control"
                      placeholder="Enter your email"
                      type="text"
                      name="firstName"
                      defaultValue={data.firstName}
                    />
                  </div>
                  <div className="col-lg-6 mt-3">
                    <label className="mb-2"> Super Agent lastName</label>
                    <input
                      className="form-control"
                      placeholder="Next of Kin (Name)"
                      type="text"
                      name="lastName"
                      defaultValue={data.lastName}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 mt-3">
                    <label className="mb-2">Code</label>
                    <input
                      className="form-control"
                      placeholder="Next of Kin (Name)"
                      type="text"
                      name="AgentCode"
                      defaultValue={data.AgentCode}
                    />
                  </div>

                  <div className="col-lg-6 mt-3 ">
                    <label className=" fs-6">
                      <span className="">Contact No.</span>
                    </label>

                    <input
                      type="number"
                      name="phone"
                      defaultValue={data.phone}
                      pattern="[0-9]{10}"
                      title=" Only number allow  Ten digits code"
                      required
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Contact No."
                    />
                  </div>

                  <div className="col-lg-6 mt-3">
                    <label className="mb-2">email</label>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      defaultValue={data.email}
                    />
                  </div>
                  <div className="col-lg-6 mt-3">
                    <label className="mb-2">City</label>
                    <input
                      className="form-control"
                      type="text"
                      name="city"
                      defaultValue={data.city}
                    />
                  </div>
                  <div className="col-lg-6 mt-3">
                    <label className="mb-2">Address</label>
                    <input
                      className="form-control"
                      type="text"
                      name="address"
                      defaultValue={data.address}
                    />
                  </div>
                  <div className="col-lg-6 mt-3">
                    <label className="mb-2"> Area/Postal Code</label>
                    <input
                      type="number"
                      name="postalCode"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Area/Postal Code"
                      defaultValue={data?.postalCode}
                    />
                  </div>

                  <div className="col-lg-6 mt-3">
                    <label className="mb-2">Savings</label>
                    <input
                      className="form-control"
                      type="number"
                      name="Savings"
                      defaultValue={data.Savings}
                      step="any"
                    />
                  </div>
                  <div className="col-lg-6 mt-3">
                    <label className="mb-2">Loans (ETB)</label>
                    <input
                      className="form-control"
                      type="number"
                      name="Loans"
                      defaultValue={data.Loans}
                      step="any"
                    />
                  </div>
                  <div className="col-lg-6 mt-3">
                    <label className="mb-2">Shares</label>
                    <input
                      className="form-control"
                      type="number"
                      name="shares"
                      defaultValue={data.shares}
                      step="any"
                    />
                  </div>
                  <div className="col-lg-6 mt-3">
                    <label className="mb-2"> Child Savings (ETB)</label>
                    <input
                      type="number"
                      name="childSaving"
                      class="form-control form-control-lg form-control-solid"
                      placeholder=" Enter Amount"
                      defaultValue={data?.childSaving}
                      step="any"
                    />
                  </div>

                  <div className="col-lg-6 mt-3">
                    <label className="mb-2"> Voluntary Savings ETB</label>
                    <input
                      type="number"
                      name="voluntarySavings"
                      class="form-control form-control-lg form-control-solid"
                      placeholder=" Enter Amount"
                      defaultValue={data?.voluntarySavings}
                      step="any"
                    />
                  </div>
                  <div className="col-lg-6 mt-3">
                    <label className="mb-2">Mandatory Savings (ETB)</label>
                    <input
                      type="number"
                      name="mandatorySavings"
                      class="form-control form-control-lg form-control-solid"
                      placeholder="Enter Amount "
                      step="any"
                      defaultValue={data?.mandatorySavings}
                    />
                  </div>

                  <div className="col-lg-6 mt-3">
                    <label className="mb-2">license certificate number </label>
                    <input
                      className="form-control"
                      type="number"
                      name="lincenseCertificateNumber"
                      defaultValue={data.lincenseCertificateNumber}
                    />
                  </div>

                  <div className="col-lg-6 mt-3">
                    <label className="mb-2"> Organisation Type</label>
                    <select
                      className="form-select border-0 flex-grow-1"
                      defaultValue={data.orgType}
                      name="orgType"
                      required
                    >
                      <option value="1">
                        {" "}
                        {data?.orgType || "Organisation Type"}
                      </option>

                      <option value="Cooperative">Cooperative</option>
                      <option value="Merchant">Merchant</option>
                      <option value="Individual"> Individual</option>
                    </select>
                  </div>

                  <div className="col-lg-6 mt-3">
                    <label className="mb-2"> Cooperative Type</label>
                    <select
                      className="form-select border-0 flex-grow-1"
                      defaultValue={data.Licenses}
                      name="Licenses"
                      required
                    >
                      <option value="1">
                        {data?.orgType || "Cooperative Type"}
                      </option>

                      <option value="SavingsandCredit">
                        Savings and Credit
                      </option>
                      <option value="Multipurpose">Multipurpose</option>
                      <option value="SeedFertilizer">
                        {" "}
                        Seed Fertilizer and Irrigation
                      </option>
                    </select>
                  </div>

                  <div className="col-lg-6 mt-3">
                    <label className="mb-2"> Country</label>
                    <select
                      className="form-select border-0 flex-grow-1"
                      value={selectedCountry}
                      defaultValue={data.country || "Ethiopia"}
                      onChange={handleCountryChange}
                      name="country"
                    >
                      <option value="">
                        {data.country || "Select a country"}
                      </option>
                      {countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow1(false)}>
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  {" "}
                  Save Changes
                </Button>
              </Modal.Footer>
            </form>
          </Modal>

          <Modal show={show} onHide={() => setshow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>DOCUMENT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <img src={photo} alt="document" style={{ width: "100%" }} />
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            className="modal-center"
            show={Blockhandler}
            onHide={() => setBlockhandler(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Verify Password</Modal.Title>
            </Modal.Header>
            <form onSubmit={VerfiyBlock}>
              <Modal.Body>
                <div className="col-lg-12 ">
                  <div className="row">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      {" "}
                      Password
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="password"
                        name="password"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Enter password"
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" type="submit" className="center">
                  {" "}
                  Submit
                </Button>
              </Modal.Footer>
            </form>
          </Modal>

          {/* Add Document modal */}

          <Modal
            show={showDoc}
            onHide={() => setshowDoc(false)}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Super Agent Documents</Modal.Title>
            </Modal.Header>
            <form onSubmit={AddformupdatesuperAgents}>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 ">
                    <label className="mb-2"> Document Name</label>

                    <input
                      className="form-control"
                      placeholder="Enter your document Name"
                      type="text"
                      name="docName"
                      required
                      onChange={(e) => setAddDocumentName(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-12 ">
                    <label className="mb-2"> Document Image </label>

                    <input
                      className="form-control"
                      placeholder="Enter your document Name"
                      type="file"
                      name="docImage"
                      required
                      onChange={handleFileChangeDocument}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setshowDoc(false)}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => [
                    setBlockhandler(true),
                    setshowDoc(false),
                    setimagepassword(4),
                  ]}
                >
                  {" "}
                  Save Changes
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default SuperAgentView;
