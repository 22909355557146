import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import CustomerLoader from "../SuperAgent/CRM/Modal/CustomerLoder";

function SubAdminList({ hadleradmin }) {
  let token = localStorage.getItem("ChromePayAdmitToken");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [ShowLoader, setShowLoader] = useState(true);
  const userlist = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/admin/findSubAdmin`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.find;
        setData(data);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    userlist();
    hadleradmin();
  }, []);

  const handleView = (_id) => {
    console.log(_id);
    navigate(`/Admin_user_profile_View/${_id}`);
  };
  const ADDROLES = (_id) => {
    console.log(_id);
    navigate(`/roles-add/${_id}`);
  };

  /////////////unblocked user api call ////////////

  const handleunblocked = (_id) => {
    const { sub_admin_ID } = { sub_admin_ID: _id };
    axios
      .post(
        `/v1/admin/Unblock_sub_admin/${sub_admin_ID}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        if (res.status) {
          let data = res.data;
          toast.success(data.msg);
          return userlist();
        }
      });
  };

  /////////////blocked user api call ////////////
  const handlesuspend = (_id) => {
    const { sub_admin_ID } = { sub_admin_ID: _id };
    axios
      .post(
        `/v1/admin/Block_sub_admin/${sub_admin_ID}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        if (res.status) {
          let data = res.data;
          toast.success(data.msg);
          return userlist();
        }
      });
  };

  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">
                        {" "}
                        Sub Admin
                      </h1>
                      <span className="fw-semibold text-white opacity-50">
                        {" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Sub Admin List
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">D-ID</th>
                            <th className="min-w-125px">Name</th>
                            <th className="min-w-150px">Email</th>
                            <th className="min-w-150px">Contact No. </th>
                            <th className="min-w-100px">Address</th>
                            <th className="min-w-100px">State</th>
                            <th className="min-w-100px">City</th>
                            <th className="min-w-100px ">Role</th>
                            <th className="min-w-100px text-center">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data.map((item) => (
                            <tr>
                              <td>{item?._id}</td>
                              <td> {`${item?.Firstname} ${item?.lastName}`} </td>
                              <td> {item?.email} </td>
                              <td>
                                <span>{item?.phone}</span>{" "}
                              </td>
                              <td>{item?.address}</td>
                              <td>{item?.state}</td>
                              <td>{item?.city}</td>
                              <td>
                                <td>
                                  <span className="badge badge-light-info fs-5 capitalize-text">
                                    {item?.role}
                                  </span>
                                </td>
                              </td>
                              <td>
                                <div className="d-flex justify-content-center flex-shrink-0">
                                  {item?.blocked == "1" ? (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handleunblocked(item?._id);
                                        }}
                                        className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Suspend "
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          <img
                                            src="/assets_new/images/blocked.png"
                                            width="20px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handlesuspend(item?._id);
                                        }}
                                        className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Password Reset "
                                        data-bs-trigger="hover"
                                        title="Suspend "
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          <img
                                            src="/assets_new/images/suspand.png"
                                            width="20px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  )}
                                  <button
                                    onClick={(e) => {
                                      handleView(item?._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="View Detail"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      ADDROLES(item?._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Add Roles"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <img
                                        src="\assets_new\images\addroles.png"
                                        width="30px"
                                      />
                                    </span>
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      navigate(`/subadmin-view/${item?._id}`);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Update role"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i class="fas fa-user-edit fs-1"></i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubAdminList;
