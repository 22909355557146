import Header from "../Header";
import ReactPaginate from "react-paginate";
import Chart from "react-apexcharts";
import Sidebar from "../Sidebar";
import { useHistory, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { JWT } from "jsonwebtoken";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { error } from "jquery";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { Pagination } from "react-bootstrap";
var jwt = require("jsonwebtoken");

function SuperAgentViewAdmin({ hadleradmin }) {
  const navigate = useNavigate("");
  let token = localStorage.getItem("ChromePayAdmitToken");
  const { _id } = useParams("");

  const [data, setData] = useState([]);
  const [datacust, setCust] = useState([]);

  const [document, setdocument] = useState([]);
  const [transmonths, settransmonths] = useState([]);
  const [transvalue, settransvalue] = useState([]);
  const [translist, settranslist] = useState([]);
  const [Query, setQuery] = useState("");
  const [searching, setsearching] = useState("");
  const [show, setshow] = useState(false);
  const [photo, setphoto] = useState("");
  const [pageCount, setpageCount] = useState("");

  const [Orguselistdata, setOrguselistdata] = useState([]);

  const limit = 10;
  const userlist = () => {
    const { agentId } = { agentId: _id };
    axios
      .post(
        `v1/org/CRM/get-super-agent-detail`,
        { agentId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp?.data?.data[0];
        setData(data);
      })
      .catch(error);
  };

  const agentDetail = () => {
    const { agentId } = { agentId: _id };
    axios
      .post(
        `/v1/admin/super-agent-detail`,
        { agentId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp?.data?.findAgentDetail;
        let custData = resp?.data?.customers;
        setData(data);
        setCust(custData);
      })
      .catch(error);
  };
  const userdoc = () => {
    const { orgID } = { orgID: _id };
    axios
      .post(`/v1/admin/viewDoc/${orgID}`)
      .then((resp) => {
        let data = resp?.data?.findDoc;
        setdocument(data);
      })
      .catch(error);
  };

  // first Pagination start

  const Orguselist = async () => {
    const { agentId } = { agentId: _id };
    await axios
      .post(
        `/v1/admin/super-agent-loans`,
        { agentId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp?.data?.data;
        setOrguselistdata(data);
        const total = resp?.data?.totalRow;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };

  const fetchComments = async (page) => {
    const senData = { page: page, agentId: _id };
    axios
      .post(`/v1/admin/super-agent-loans`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp?.data?.data;
        setOrguselistdata(data);
      });
    return data;
  };
  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  const Transactionuselist = () => {
    const { agentId } = { agentId: _id };
    axios
      .post(
        `/v1/org/CRM/get-super-agent-cust-loans`,
        { agentId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data11 = resp.data.data;
        settranslist(data11);
        const total = resp?.data?.totlaRow;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      })
      .catch(error);
  };

  const fetchCommentspage = async (page) => {
    const { orgID } = { orgID: _id };
    const senData = { page: page };
    axios.post(`/v1/admin/get_transctions/${orgID}`, senData).then((resp) => {
      let data11 = resp?.data?.findCust;
      settranslist(data11);
    });
    return data;
  };
  const handlePageClickpage = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchCommentspage(page);
  };

  // end  seconded page

  // graph use transction

  const Transactionuse = () => {
    const { orgID } = { orgID: _id };
    axios
      .get(`/v1/admin/get_org_transections_months/${orgID}`)
      .then((resp) => {
        let data = resp?.data?.obj;
        settransmonths(Object.keys(data));
        settransvalue(Object.values(data));
      })
      .catch(error);
  };

  // document show function

  const imageview = (image) => {
    setphoto(image);
    setshow(true);
  };

  // api call
  useEffect(() => {
    userlist();
    userdoc();
    Transactionuse();
    Transactionuselist();
    hadleradmin();
    Orguselist();
    agentDetail();
  }, []);

  const handleView = (_id) => {
    navigate(`/customer-view-admin/${_id}`);
  };

  return (
    <>
      <ToastContainer position="top-right" />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="card mb-12 rounded-15 bgi-no-repeat bgi-position-x-end 
        bgi-size-cover bg_card"
                    >
                      <div className="card-body flex-column p-5">
                        <div className="d-flex align-items-center ">
                          <div className="card-body  pt-9 pb-0 ">
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                              <div className="me-7 mb-4">
                                <div className="symbol border border-gray-300 border-dashed symbol-100px symbol-lg-160px symbol-fixed position-relative text-white">
                                  <img
                                    src={
                                      data?.Image ||
                                      "/assets/img/male_avtar.png"
                                    }
                                    alt="image"
                                    className="rounded-circle mx-2"
                                  />
                                </div>
                              </div>

                              <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                  <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-2">
                                      <a
                                        href="#"
                                        className="-900 text-hover-primary fs-2 text-white fw-bold me-1"
                                      >
                                        {data?.firstName} {data?.lastName}
                                      </a>
                                    </div>

                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                              fill="currentColor"
                                            />
                                            <rect
                                              x="7"
                                              y="6"
                                              width="4"
                                              height="4"
                                              rx="2"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {"Super Agent"}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        <span className="svg-icon text-white  svg-icon-4 me-1">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {"Ethiopian"}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-white mb-2"
                                      >
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {data?.email}
                                      </a>
                                    </div>
                                  </div>
                                </div>

                                <div className="d-flex flex-wrap flex-stack">
                                  <div className="d-flex flex-column flex-grow-1 pe-8">
                                    <div className="d-flex flex-wrap">
                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2">
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <rect
                                                opacity="0.5"
                                                x="13"
                                                y="6"
                                                width="13"
                                                height="2"
                                                rx="1"
                                                transform="rotate(90 13 6)"
                                                fill="currentColor"
                                              />
                                              <path
                                                d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </span>

                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >{`$${data?.wallet_detail?.amount}`}</div>
                                        </div>

                                        <div className="fw-semibold fs-6 -400 text-white">
                                          Wallet Amount
                                        </div>
                                      </div>

                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-danger me-2">
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <rect
                                                opacity="0.5"
                                                x="11"
                                                y="18"
                                                width="13"
                                                height="2"
                                                rx="1"
                                                transform="rotate(-90 11 18)"
                                                fill="currentColor"
                                              />
                                              <path
                                                d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >{`$${0}`}</div>
                                        </div>

                                        <div className="fw-semibold fs-6 -400 text-white">
                                          Total Transaction{" "}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex-equal d-flex justify-content-center align-items-end ms-5">
                            <img
                              src="../../assets/media/illustrations/dozzy-1/20.png"
                              alt=""
                              className="mw-100 mh-125px mh-lg-275px mb-lg-n12"
                            />
                          </div>
                        </div>

                        <div className="card-rounded  col-12 d-flex flex-stack flex-wrap p-5 pb-0">
                          <ul
                            className="nav flex-wrap border-transparent fw-bold"
                            role="tablist"
                          >
                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#overview"
                                className="btn btn-color-gray-600 btn-active-secondary  active btn-active-color-primary fw-bolder fs-5 fs-lg-base nav-link px-3 px-lg-7 mx-1 "
                              >
                                Overview
                              </a>
                            </li>

                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#documents"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-5 fs-lg-base nav-link px-3 px-lg-7 mx-1 "
                              >
                                Super Agent Documents
                              </a>
                            </li>

                            <li className="nav-item my-1" role="presentation">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#land"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-5 fs-lg-base nav-link px-3 px-lg-7 mx-1  "
                              >
                                Super Agent Loans
                              </a>
                            </li>

                            <li className="nav-item my-1">
                              <a
                                role="tab"
                                aria-selected="true"
                                data-bs-toggle="tab"
                                href="#finance"
                                className="btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-5 fs-lg-base nav-link px-3 px-lg-7 mx-1"
                              >
                                OnBoarding Customer
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="overview"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-6">
                          <div
                            className="card mb-5 mb-xl-10"
                            id="kt_profile_details_view"
                          >
                            <div className="card-header cursor-pointer">
                              <div className="card-title m-0">
                                <h3 className="fw-bold m-0">
                                  Super Agent Details
                                </h3>
                              </div>
                            </div>

                            <div className="card-body p-9">
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  First Name
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-bold fs-6 -800">
                                    {data?.firstName}
                                  </span>
                                </div>
                              </div>
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Last Name
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-bold fs-6 -800">
                                    {data?.lastName}
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Code
                                </label>

                                <div className="col-lg-8 fv-row">
                                  <span className="fw-semibold -800 fs-6">
                                    {data?.AgentCode}
                                  </span>
                                </div>
                              </div>
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Joining Date
                                </label>

                                <div className="col-lg-8 fv-row">
                                  <span className="fw-semibold -800 fs-6">
                                    {moment(data?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Email Address
                                </label>

                                <div className="col-lg-8 fv-row">
                                  <span className="fw-semibold -800 fs-6">
                                    {data?.email}
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Contact No.
                                  <i
                                    className="fas fa-exclamation-circle ms-1 fs-7"
                                    data-bs-toggle="tooltip"
                                    title="Phone number must be active"
                                  ></i>
                                </label>

                                <div className="col-lg-8 d-flex align-items-center">
                                  <span className="fw-bold fs-6 -800 me-2">
                                    +{data?.phone}
                                  </span>
                                  <span className="badge badge-success">
                                    Verified
                                  </span>
                                </div>
                              </div>

                              <div className="row mb-7">
                                <label className="col-lg-4 fw-semibold text-muted">
                                  Country
                                </label>

                                <div className="col-lg-8">
                                  <span className="fw-semibold fs-6 -800 text-hover-primary">
                                    {"Ethiopian"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="card card-flush overflow-hidden h-md-100">
                            <div className="card-header py-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-dark">
                                  Transaction Activities
                                </span>
                                <span className="-400 mt-1 fw-semibold fs-6"></span>
                              </h3>
                            </div>

                            <div className="card-body d-flex  flex-column pb-1 px-0">
                              <div className="px-9 mb-5">
                                <div className="d-flex align-items-center mb-2">
                                  <span className="fs-4 fw-semibold -400 align-self-start me-1">
                                    $
                                  </span>

                                  <span className="fs-2hx fw-bold -800 me-2 lh-1 ls-n2">
                                    {data?.totalTransection_amount}
                                  </span>

                                  <span className="badge badge-light-success fs-base">
                                    <span className="svg-icon svg-icon-5 svg-icon-success ms-n1">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          opacity="0.5"
                                          x="11"
                                          y="18"
                                          width="13"
                                          height="2"
                                          rx="1"
                                          transform="rotate(-90 11 18)"
                                          fill="currentColor"
                                        />
                                        <path
                                          d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                                          fill="currentColor"
                                        />
                                      </svg>
                                    </span>
                                  </span>
                                </div>

                                <span className="fs-6 fw-semibold -400">
                                  Transaction Amount This year
                                </span>
                              </div>
                              <div className="bg-white overflow-auto  card">
                                <Chart
                                  type="area"
                                  width={"100%"}
                                  height={400}
                                  series={[
                                    {
                                      name: "Transaction",
                                      data: transvalue,
                                    },
                                  ]}
                                  options={{
                                    plotOptions: {
                                      bar: {
                                        borderRadius: 2,
                                        columnWidth: 50,
                                      },
                                    },
                                    markers: {
                                      size: 0,
                                    },
                                    stroke: {
                                      width: 3,
                                      curve: "smooth",
                                    },

                                    colors: ["#3c7f8c"],
                                    theme: { mode: "light" },

                                    xaxis: {
                                      tickPlacement: "on",
                                      categories: transmonths,
                                      title: {
                                        style: {
                                          color: "#f90000",
                                          fontSize: 0,
                                        },
                                      },
                                    },

                                    yaxis: {
                                      labels: {
                                        formatter: (val) => {
                                          return `${val}`;
                                        },
                                      },
                                      //
                                    },

                                    legend: {
                                      show: true,
                                      position: "right",
                                    },

                                    dataLabels: {
                                      formatter: (val) => {
                                        return `${val}`;
                                      },
                                      style: {
                                        colors: ["#3c7f8c"],
                                        fontSize: 15,
                                      },
                                    },
                                  }}
                                ></Chart>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="documents"
                      role="tabpanel"
                    >
                      <div className="row">
                        <div className="col-lg-12 mb-4">
                          <h3 className="fw-bold my-2">
                            User Documents
                            <span className="fs-6 -400 fw-semibold ms-1"></span>
                          </h3>
                        </div>

                        <div className="row mt-4">
                          <div className="col-lg-3 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() =>
                                  imageview(
                                    "../../assets_new/images/largepreview.png"
                                  )
                                }
                              >
                                <a className="img_div">
                                  <img src="../../assets_new/images/largepreview.png" />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    data-toggle="lightbox"
                                    data-gallery="gallery"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between p-3">
                                <h5> Identification Document Image</h5>
                                <div>
                                  <a
                                    href="../../assets_new/images/largepreview.png"
                                    className="ml-2"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-3 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() =>
                                  imageview(
                                    "../../assets_new/images/electronic.png"
                                  )
                                }
                              >
                                <a className="img_div">
                                  <img src="../../assets_new/images/electronic.png" />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between p-3">
                                <h5>Company Profile Brochure</h5>
                                <div>
                                  <a
                                    href="../../assets_new/images/electronic.png"
                                    className="ml-2"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() =>
                                  imageview("../../assets_new/images/gov.jpg")
                                }
                              >
                                <a className="img_div">
                                  <img src="../../assets_new/images/gov.jpg" />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between p-3">
                                <h5>Proof of Company Address</h5>
                                <div>
                                  <a
                                    href="../../assets_new/images/gov.jpg"
                                    className="ml-2"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 basicdetail">
                            <div className="carduser">
                              <div
                                className="text-center"
                                onClick={() =>
                                  imageview(
                                    "../../assets_new/images/largepreview.png"
                                  )
                                }
                              >
                                <a className="img_div">
                                  <img src="../../assets_new/images/largepreview.png" />
                                </a>
                                <a className="viewimg">
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex justify-content-between p-3">
                                <h5>Customer Agreement</h5>
                                <div>
                                  <a
                                    href="../../assets_new/images/largepreview.png"
                                    className="ml-2"
                                  >
                                    <i
                                      className="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane fade" id="land" role="tabpanel">
                      <div className="row g-5 g-xl-8">
                        <div className="col-lg-12">
                          <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div class="card-header border-0 pt-6 d-flex align-items-center">
                              <h4>Super Agent Loans List</h4>
                            </div>

                            <div class="card-body pt-0">
                              <div className="table-responsive">
                                <table
                                  class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                                  id="kt_customers_table"
                                >
                                  <thead>
                                    <tr class="fw-bold text-muted th-title">
                                      <th className="min-w-125px">D-ID</th>
                                      <th className="min-w-150px">Loan Type</th>
                                      <th className="min-w-150px">
                                        Interest Rate{" "}
                                      </th>
                                      <th className="min-w-100px">EMI</th>
                                      <th className="min-w-100px">
                                        Total Amount
                                      </th>
                                      <th className="min-w-100px">
                                        Duration Year
                                      </th>
                                      <th className="min-w-125px">
                                        Total Interest Amount
                                      </th>
                                      <th className="min-w-100px ">Status</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {Orguselistdata?.map((item) => (
                                      <tr>
                                        <td>
                                          <div class="d-flex align-items-center">
                                            <div class="symbol symbol-45px me-5">
                                              <img
                                                src={item?.agent_detail?.Image}
                                                alt=""
                                              />
                                            </div>
                                            <div class="d-flex justify-content-start flex-column">
                                              <a
                                                href={`/customer-view-admin/${item?.agent_detail?._id}`}
                                                class="usertitle Acolor mb-2"
                                              >
                                                {" "}
                                                {
                                                  item?.agent_detail?.firstName
                                                }{" "}
                                                {item?.agent_detail?.lastName}
                                              </a>
                                              <span class="font-icon mb-1">
                                                <span class="">
                                                  <i class="fal fa-phone-volume"></i>
                                                </span>
                                                +{item?.agent_detail?.phone}
                                              </span>

                                              <span class="font-icon mb-1">
                                                <span class="">
                                                  <i class="fal fa-envelope"></i>
                                                </span>
                                                {item?.agent_detail?.email}{" "}
                                              </span>
                                            </div>
                                          </div>
                                        </td>

                                        <td>{item?.loanType}</td>
                                        <td>
                                          <span>{`${item?.interestRate}%`}</span>{" "}
                                        </td>
                                        <td>{item?.payMethod}</td>
                                        <td>{item?.totalAmount?.toFixed(2)}</td>
                                        <td>{item?.timePeriod}</td>
                                        <td>
                                          {item?.intrestAmount?.toFixed(2)}
                                        </td>
                                        <td>
                                          <span class="badge badge-light-info fs-5 capitalize-text">
                                            {item?.status}
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  PreviousLabel={"Previous"}
                                  NextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={3}
                                  onPageChange={handlePageClick}
                                  containerClassName={
                                    "pagination justify-content-end"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  PreviousClassName={"page-item"}
                                  PreviousLinkClassName={"page-link pagestyle"}
                                  NextClassName={"page-item"}
                                  NextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane fade" id="finance" role="tabpanel">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="card">
                            <div class="card-header border-0 pt-6 d-flex align-items-center">
                              <h4>Super Agent Customer Loans List</h4>
                            </div>

                            <div class="card-body pt-0">
                              <div className="table-responsive">
                                <table
                                  class="table align-middle table-row-dashed fs-6 gy-5"
                                  id="kt_customers_table"
                                >
                                  <thead>
                                    <tr class="text-start tr_bg text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                      <th className="min-w-125px">ID Photo</th>
                                      <th className="min-w-150px">
                                        D-ID Ref Number
                                      </th>
                                      <th className="min-w-150px">
                                        {" "}
                                        Date of Creation
                                      </th>
                                      <th className="min-w-100px">Country</th>
                                      <th className="min-w-100px">
                                        Biometrics
                                      </th>
                                      <th className="min-w-100px ">Status</th>
                                      <th className="min-w-100px ">Actions</th>
                                    </tr>
                                  </thead>

                                  <tbody class="fw-semibold text-gray-600">
                                    {datacust?.map((item) => (
                                      <tr>
                                        <td>
                                          {" "}
                                          <div class="d-flex align-items-center">
                                            <div class="symbol symbol-45px me-5">
                                              <img src={item?.IDphoto} alt="" />
                                            </div>
                                            <div class="d-flex justify-content-start flex-column">
                                              <a
                                                href={`/Agent-customer-view/${item?._id}`}
                                                class="usertitle Acolor mb-2"
                                              >
                                                {" "}
                                                {item?.fullname}
                                              </a>
                                              <span class="font-icon mb-1">
                                                <span class="">
                                                  <i class="fal fa-phone-volume"></i>
                                                </span>
                                                +{item?.phone}
                                              </span>

                                              <span class="font-icon mb-1">
                                                <span class="">
                                                  <i class="fal fa-envelope"></i>
                                                </span>
                                                {item?.email}{" "}
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>{item?.digitalrefID}</td>
                                        <td>
                                          {moment(item?.createdAt).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>

                                        <td>
                                          {item?.nationality.toUpperCase()}
                                        </td>
                                        {item?.facialIdentification == 0 ? (
                                          <td class="text-center">
                                            <span
                                              class="svg-icon svg-icon-muted svg-icon-2hx faild"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-trigger="hover"
                                              data-kt-initialized="1"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                              >
                                                <path
                                                  opacity="0.3"
                                                  d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                  fill="currentColor"
                                                ></path>
                                                <rect
                                                  x="9"
                                                  y="13.0283"
                                                  width="7.3536"
                                                  height="1.2256"
                                                  rx="0.6128"
                                                  transform="rotate(-45 9 13.0283)"
                                                  fill="currentColor"
                                                ></rect>
                                                <rect
                                                  x="9.86664"
                                                  y="7.93359"
                                                  width="7.3536"
                                                  height="1.2256"
                                                  rx="0.6128"
                                                  transform="rotate(45 9.86664 7.93359)"
                                                  fill="currentColor"
                                                ></rect>
                                              </svg>
                                            </span>
                                          </td>
                                        ) : (
                                          <td class="text-center ">
                                            <span
                                              class="svg-icon svg-icon-muted svg-icon-2hx success"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              data-bs-trigger="hover"
                                              data-kt-initialized="1"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                              >
                                                <path
                                                  opacity="0.3"
                                                  d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                  fill="currentColor"
                                                ></path>
                                                <path
                                                  d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                                  fill="currentColor"
                                                ></path>
                                              </svg>
                                            </span>
                                          </td>
                                        )}
                                        <td>
                                          {item?.status == "pending" ? (
                                            <>
                                              <span class="capitalize-text badge badge-light-pending fs-5">
                                                {item?.status
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  item?.status.slice(1)}
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <span class="badge badge-light-info  fs-5">
                                                {item?.status
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  item?.status.slice(1)}
                                              </span>
                                            </>
                                          )}
                                        </td>

                                        <td>
                                          <button
                                            onClick={(e) => {
                                              handleView(item?._id);
                                            }}
                                            className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-trigger="hover"
                                            title="View Detail"
                                          >
                                            <span className="svg-icon svg-icon-3">
                                              <i className="fad fa-eye fs-4"></i>
                                            </span>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <div className="col-lg-12 mt-2 text-end">
                                <ReactPaginate
                                  PreviousLabel={"Previous"}
                                  NextLabel={"Next"}
                                  breakLabel={"..."}
                                  pageCount={pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={3}
                                  onPageChange={handlePageClickpage}
                                  containerClassName={
                                    "pagination justify-content-end"
                                  }
                                  pageClassName={"page-item mx-2"}
                                  pageLinkClassName={"page-link"}
                                  PreviousClassName={"page-item"}
                                  PreviousLinkClassName={"page-link pagestyle"}
                                  NextClassName={"page-item"}
                                  NextLinkClassName={"page-link"}
                                  breakClassName={"page-item"}
                                  breakLinkClassName={"page-link"}
                                  activeClassName={"active"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="account" role="tabpanel">
                      <div className="row">
                        <div className="col-lg-12 mb-4">
                          <h3 className="fw-bold my-2">
                            User Bank Documents
                            <span className="fs-6 -400 fw-semibold ms-1"></span>
                          </h3>
                        </div>

                        <div className="row mt-4 text-hover-primary"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                  <div className="text-dark order-2 order-md-1">
                    <span className="text-muted text-hover-primary fw-semibold me-2 fs-6">Powered by</span>
                    <a
                      href="#"
                      className="text-muted text-hover-primary fw-semibold me-2 fs-6"
                    >
                      Chromepay
                    </a>
                  </div>

                  <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        About
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Modal show={show} onHide={() => setshow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>DOCUMENT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="container">
                <img src={photo} alt="document" style={{ width: "100%" }} />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default SuperAgentViewAdmin;
