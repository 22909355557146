import React, { useState, useEffect } from "react";
import { Bar } from 'react-chartjs-2';
import axios from "axios";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const UnionGraph = () => {
    const [data, setData] = useState([]);
  const [JdcProfssion, setJdcProfssion] = useState("");
  const [JdcProfession, setJdcProfession] = useState([]); // Default to saving data
  const [genderFilter, setJdcGender] = useState("");
   let token = localStorage.getItem("token");
  const [frequency, setFrequency] = useState("month");
  const [fromDate, setFromDate] = useState(""); // State for start date
  const [toDate, setToDate] = useState(""); 
  const [ShowLoader, setShowLoader] = useState(true);

  //ChromePayAdmitToken

  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);
  const handleResetDID = () => {
    console.log("hlloooo");

    setToDate("");
    setFromDate("");
    setFrequency("month");
    setJdcGender("")
    setJdcProfssion("")

  };

  const JdcCategories = () => {
    
    axios
      .post(
        `/v1/super-Agent/get-profession-categories `,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let Profession = resp.data?.data;
        console.log({ Profession });
        setJdcProfession(Profession);
      });
  };

    useEffect(() => {
        setShowLoader(true);
        const fetchData = () => {
          let body = {
            Type: frequency,
            // category: JdcFilter,
            gender: genderFilter,
            fromDate: fromDate,
            toDate: toDate,
      category: JdcProfssion,

            // agentId: id,
            // format: JdcFilter === 'all' ?  'nonCumulative' : cumulative,
            // orgId : jdcSelectedUnions
          };
          axios
            .post(`/v1/super-Agent/get-unions-jdc-user-graphs`, body, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              const userData = res.data?.data;
              setData(userData)
              console.log("graph data", { userData });
    
             
    
              // Data for chart
    
              // Conditionally show loan or saving data
            
    
              setShowLoader(false);
            });
        };
    
        fetchData();
      }, [frequency, fromDate, toDate, genderFilter, JdcProfssion]);
    // Transform data for Chart.js
    const unions = [...new Set(data.map((item) => item.union))];
    const months = [...new Set(data.map((item) => `${item.month}-${item.year}`))];

    // Transform data for Chart.js
    const colors = [
      "#FF6F61", // Coral Red
      "#6B5B95", // Elegant Purple
      "#88B04B", // Soft Green
      "#F7CAC9", // Blush Pink
      "#92A8D1", // Serene Blue
      "#F4B400", // Vibrant Yellow
      "#E94B3C", // Bold Red
      "#5B84B1", // Cool Blue
      "#B565A7", // Royal Purple
      "#9E1B32", // Rich Maroon
      "#FFB400", // Amber Orange
      "#6CACE4", // Sky Blue
    ];
    
    const chartData = {
      labels: months,
      datasets: unions.map((union, index) => ({
        label: union,
        data: months.map(
          (month) =>
            data.find(
              (item) =>
                `${item.month}-${item.year}` === month && item.union === union
            )?.count || 0
        ),
        backgroundColor: colors[index % colors.length], // Cycle through diverse colors
        borderColor: colors[index % colors.length], // Use same color for borders
        borderWidth: 1,
      })),
    };
    
    

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Customers Added by Unions per Month',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Month-Year',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Customers Added',
                },
                beginAtZero: true,
            },
        },
    };

    useEffect(() => {
        JdcCategories();
      }, []);

    return(

        <div className="col-lg-12 mt-5">
      <CustomerLoader loader={ShowLoader} />

      <div className="card card-flush h-xl-100">
        <div className="card-header pt-7">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">
              User Onboarded Activity
            </span>
            <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
          </h3>
          <div className="row mb-4 align-items-center">
            {/* Frequency Filter */}
       
            <div className="col-md-3">
            <div className="d-flex align-items-center gap-2">
                  <select
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                    className="form-select graph-select shadow-sm"
                  >
                    
                    <option value="month">Monthly</option>
                    <option value="year">Yearly</option>
                  </select>
                </div>
            </div>

            <div className="col-md-3">
              <form>
                <div className="d-flex align-items-center gap-2">
                  <select
                    value={genderFilter}
                    onChange={(e) => setJdcGender(e.target.value)}
                    className="form-select graph-select shadow-sm"
                  >
                    <option selected>Choose any gender option</option>

                    <option key="Male" value="Male">
                      Male
                    </option>

                    <option key="Female" value="Female">
                      Female
                    </option>
                  </select>
                </div>
              </form>
            </div>

            <div className="col-md-3">
              <form>
                <div className="d-flex align-items-center gap-2">
                  <select
                    value={JdcProfssion}
                    onChange={(e) => setJdcProfssion(e.target.value)}
                    className="form-select graph-select shadow-sm"
                  >
                    <option selected>Choose any one option</option>

                    {JdcProfession?.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>

            <div className="rounded d-flex flex-column flex-lg-row mend align-items-lg-center bg-body p-5 h-lg-60px me-lg-10 my-5">
              <div className="row  justify-content-end  flex-grow-1 mb-5 mb-lg-0">
                <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                  <label className="small_label my-label">Start Date</label>
                  <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                    <i className="fad fa-calendar"></i>
                  </span>
                  <input
                    type="date"
                    className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                    value={fromDate}
                    onChange={handleFromDateChange}
                  />
                  <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                </div>

                <div className="col-md-3 d-flex ps-1 align-items-center mb-3 input-container mb-lg-0">
                  <label className="small_label my-label">End Date</label>
                  <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                    <i className="fad fa-calendar"></i>
                  </span>
                  <input
                    type="date"
                    className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                    value={toDate}
                    onChange={handleToDateChange}
                  />
                </div>
                <div className="col-md-2 d-flex justify-content-start align-items-center mb-3 ps-1 input-container mb-lg-0">
                  <button
                    type="button"
                    onClick={() => handleResetDID()}
                    className="btn btn-light btn-active-light-primary"
                    id="kt_advanced_search_button_1"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-auto">
        <Bar data={chartData} options={options} />;
        </div>
      </div>
    </div>
    
    // <div className="row mb-4 align-items-center">
        

        

       

        
      
    //     <div className="col-md-3">
    //     <div className="d-flex align-items-center gap-2">
    //           <select
    //             value={frequency}
    //             onChange={(e) => setFrequency(e.target.value)}
    //             className="form-select graph-select shadow-sm"
    //           >
               
    //             <option value="month">Monthly</option>
    //             <option value="year">Yearly</option>
    //           </select>
    //         </div>
    //     </div>

    //     <div className="rounded d-flex flex-column flex-lg-row mend align-items-lg-center bg-body p-5 h-lg-60px me-lg-10 my-5">
    //       <div className="row  justify-content-end  flex-grow-1 mb-5 mb-lg-0">
    //         <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
    //           <label className="small_label my-label">Start Date</label>
    //           <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
    //             <i className="fad fa-calendar"></i>
    //           </span>
    //           <input
    //             type="date"
    //             className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
    //             value={fromDate}
    //             onChange={handleFromDateChange}
    //           />
    //           <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
    //         </div>

    //         <div className="col-md-3 d-flex ps-1 align-items-center mb-3 input-container mb-lg-0">
    //           <label className="small_label my-label">End Date</label>
    //           <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
    //             <i className="fad fa-calendar"></i>
    //           </span>
    //           <input
    //             type="date"
    //             className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
    //             value={toDate}
    //             onChange={handleToDateChange}
    //           />
    //         </div>
    //         <div className="col-md-2 d-flex justify-content-start align-items-center mb-3 ps-1 input-container mb-lg-0">
    //           <button
    //             type="button"
    //             onClick={() => handleResetDID()}
    //             className="btn btn-light btn-active-light-primary"
    //             id="kt_advanced_search_button_1"
    //           >
    //             Reset
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //     <Bar data={chartData} options={options} />;
    //   </div>
      
    )
    
    
    
};

export default UnionGraph
