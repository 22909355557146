import React, { useRef, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import Moment from "moment";

function RemaningLicenses({ hadlerorg }) {


  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [license, setLicense] = useState([]);
  const [Paymentprocess, setPaymentprocess] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const focusPoint = useRef(null);
  const [pageCount, setpageCount] = useState("");
  const currentFont = localStorage.getItem("liesenapply");

  let token = localStorage.getItem("organizationToken");

  const addactive = () => {
    focusPoint.current.classList.toggle("processactive");
  };
  const focusPointpayment = useRef(null);

  const addactivepayment = () => {
    focusPoint.current.classList.toggle("processactivepayment");
  };

  const options = [
    { value: "1000", label: "1K" },
    { value: "10000", label: "10K" },
    { value: "50000", label: "50K" },
    { value: "100000", label: "100K" },
    { value: "500000", label: "500K" },
    { value: "1000000", label: "1M" },
    { value: "2000000", label: "2M" },
    { value: "5000000", label: "5M" },
    { value: "option2", label: "Custom" },
  ];

  const Paymentprocessbank = async () => {
    await axios
      .post(
        `/v1/org/dash/LicenseProcess`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.status;
        setPaymentprocess(data);
      });
  };




  useEffect(() => {
    Paymentprocessbank();
    if (currentFont) {
      addactive();
    }
  }, []);




  useEffect(() => {
    if (Paymentprocess) {
      addactivepayment();
    }
  }, [Paymentprocess]);



  useEffect(() => {
    hadlerorg();
    userlist();
  }, []);


  const AddLicense = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log("@@@" + Formvlaues.Licenses);
    axios
      .post(`/v1/org/dash/applyUpdateLicenses`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            Swal.fire("SuccessFully!", "D-ID Add Licenses!", "success");
            toast.success(data.msg);

            e.target.reset();
            return userlist();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      });
   
  };

  const userlist = () => {
    axios
      .post(
        `/v1/org/dash/detail`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;

        setLicense(data);
      });
  };

  const limit = 10;

  const formsave = (e, page) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log("Formvlaues === ", Formvlaues);
    axios
      .post(`/v1/org/dash/get-org-license-hist`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
        const total = resp.data.totalRow;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };




  const Licenseslist = async () => {
    await axios
      .post(
        `/v1/org/dash/get-org-license-hist`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
        const total = resp.data.totalRow;
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };



  useEffect(() => {
    Licenseslist();
    hadlerorg();
  }, []);




  const fetchComments = async (page) => {
    const senData = { page: page };

    axios
      .post(`v1/org/dash/get-org-license-hist`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };
  return (
    <>
      <div>
        <ToastContainer />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <div
            className="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="container-xxl">
              <div className="row g-5 g-xl-8">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-xl-10">
                    <div
                      className="card-header border-0 cursor-pointer"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_profile_details"
                      aria-expanded="true"
                      aria-controls="kt_account_profile_details"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0">Request D-ID Licenses</h3>
                      </div>
                    </div>
                    <div
                      id="kt_account_settings_profile_details"
                      className="collapse show"
                    >
                      <div className="d-flex p-9">
                        <label className="col-lg-2 col-form-label fw-semibold fs-6">
                          Remaining D-ID licenses
                        </label>
                        <div className="col-lg-4 fv-row">
                          <input
                            type="text"
                            value={license.remaning_Licenses}
                            name="Licenses"
                            className="form-control form-control-lg form-control-solid"
                            placeholder="Remaining D-ID licenses"
                          />
                        </div>
                      </div>
                      <form
                        onSubmit={(e) => AddLicense(e)}
                        id="kt_account_profile_details_form"
                        className="form"
                      >
                        <div className="card-body border-top p-9">
                          <div className="row mb-6">
                            <label className="col-lg-2 col-form-label fw-semibold fs-6">
                              Licenses
                            </label>

                            <div className="col-lg-4 fv-row">
                              <select
                                value={selectedOption}
                                onChange={(e) => {
                                  setSelectedOption(e.target.value);
                                  setInputValue("");
                                }}
                                name="Licenses"
                                className="form-select border-0 flex-grow-1"
                              >
                                <option value="">Select an option</option>
                                {options.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {selectedOption === "option2" && (
                              <>
                                <div className="col-lg-4 fv-row">
                                  <input
                                    type="text"
                                    value={inputValue}
                                    onChange={(e) =>
                                      setInputValue(e.target.value)
                                    }
                                    name="Licenses"
                                    className="form-control form-control-lg form-control-solid"
                                    placeholder="Amount of required licenses"
                                  />
                                </div>
                              </>
                            )}
                            <div className="col-lg-2 fv-row">
                          

                              <button
                                type="submit"
                                className="btn btn-light btn-sub me-2"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                    
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="row g-5 g-xl-8">
                  <div className="col-lg-12">
                    <div
                      className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                      style={{ marginTop: "2rem" }}
                    >
                      <div className="card-body container-xxl pt-10 pb-8">
                        <div className="d-flex align-items-center">
                          <h1 className="fw-semibold me-3 text-white">
                            Filter
                          </h1>
                          <span className="fw-semibold text-white opacity-50">
                            {" "}
                            Licens Order History
                          </span>
                        </div>

                        <div className="d-flex flex-column">
                          <div className="d-lg-flex align-lg-items-center">
                            <form onSubmit={(e) => formsave(e)}>
                              <div className="d-flex flex-column">
                                <div className="d-lg-flex align-lg-items-center">
                                  <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                                    <div className="row flex-grow-1 mb-5 mb-lg-0">
                                     

                                      <div className="col-lg-4 d-flex align-items-center mb-5 mb-lg-0">
                                        <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <rect
                                              x="2"
                                              y="2"
                                              width="9"
                                              height="9"
                                              rx="2"
                                              fill="currentColor"
                                            />
                                            <rect
                                              opacity="0.3"
                                              x="13"
                                              y="2"
                                              width="9"
                                              height="9"
                                              rx="2"
                                              fill="currentColor"
                                            />
                                            <rect
                                              opacity="0.3"
                                              x="13"
                                              y="13"
                                              width="9"
                                              height="9"
                                              rx="2"
                                              fill="currentColor"
                                            />
                                            <rect
                                              opacity="0.3"
                                              x="2"
                                              y="13"
                                              width="9"
                                              height="9"
                                              rx="2"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        <select
                                          name="status"
                                          className="form-select border-0 flex-grow-1"
                                          data-control="select2"
                                          data-placeholder="Status"
                                          data-hide-search="true"
                                        >
                                          <option value="" selected disabled>
                                            Status
                                          </option>
                                          <option value="pending">
                                            Pending
                                          </option>
                                          <option value="approve">
                                            Approved
                                          </option>
                                          <option value="confirm">
                                            Confirm
                                          </option>
                                        </select>
                                      </div>

                                      <div className="col-lg-4 d-flex input-container align-items-center mb-3  mb-lg-0">
                                        <label className="small_label">
                                          Start Date
                                        </label>
                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                          <i className="fad fa-calendar fs-2"></i>
                                        </span>
                                        <input
                                          type="date"
                                          className="form-control unstyled form-control-flush flex-grow-1"
                                          name="fromDate"
                                          placeholder="User Name.."
                                        />
                                        <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                      </div>

                                      <div className="col-lg-4 d-flex align-items-center mb-3 input-container mb-lg-0">
                                        <label className="small_label">
                                          End Date
                                        </label>
                                        <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                          <i className="fad fa-calendar fs-2"></i>
                                        </span>
                                        <input
                                          type="date"
                                          className="form-control unstyled form-control-flush flex-grow-1"
                                          name="toDate"
                                          placeholder="User Name.."
                                        />
                                      </div>
                                    </div>

                                    <div className="min-w-150px text-end d-flex">
                                      <button
                                        type="submit"
                                        className="btn btn-dark mr-3"
                                        id="kt_advanced_search_button_1"
                                      >
                                        Search
                                      </button>
                                      <button
                                        type="reset"
                                        onClick={userlist}
                                        className="btn btn-secondary"
                                        id="kt_advanced_search_button_1"
                                      >
                                        Reset
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold fs-3 mb-1">
                            License Order History
                          </span>
                          <span className="text-muted mt-1 fw-semibold fs-7"></span>
                        </h3>
                      </div>

                      <div className="card-body py-3">
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bold text-muted th-title">
                                <th className="min-w-125px">ID Photo</th>

                                <th className="min-w-150px">No of Licenses</th>
                                <th className="min-w-150px">
                                  {" "}
                                  Date of Creation
                                </th>
                                <th className="min-w-100px">Country</th>
                                <th className="min-w-100px">Payment Status</th>
                              
                                <th className="min-w-100px ">Approve Status</th>
                              </tr>
                            </thead>

                            <tbody>
                              {data.map((item) => (
                                <tr>
                                  <td>
                                    {" "}
                                    <div class="d-flex align-items-center">
                                      <div class="symbol symbol-45px me-5">
                                        <img
                                          src={item.ord_detail?.logo}
                                          alt=""
                                        />
                                      </div>
                                      <div class="d-flex justify-content-start flex-column">
                                        <a
                                          href={`/org-customer-view/${item._id}`}
                                          class="usertitle Acolor mb-2"
                                        >
                                          {" "}
                                          {item.ord_detail?.name}
                                        </a>
                                        <span class="font-icon mb-1">
                                          <span class="">
                                            <i class="fal fa-phone-volume"></i>
                                          </span>
                                          +{item.phone}
                                        </span>

                                        <span class="font-icon mb-1">
                                          <span class="">
                                            <i class="fal fa-envelope"></i>
                                          </span>
                                          {item.ord_detail?.email}{" "}
                                        </span>
                                      </div>
                                    </div>
                                  </td>

                                  <td>{item.noofLicenses}</td>
                                  <td>
                                    {Moment(item.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>

                                  <td>{item.ord_detail?.country}</td>


                                  {item.paymentStatus == "pending" ? (
                                    <>
                                      <td>
                                        <span className="badge badge-light-pending text-danger fs-5">
                                          {item.paymentStatus
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            item.paymentStatus?.slice(1)}
                                        </span>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td>
                                        <span className="badge badge-light-info fs-5">
                                          {item.paymentStatus
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            item.paymentStatus?.slice(1)}
                                        </span>
                                      </td>
                                    </>
                                  )}
                                  {item.approveStatus == "pending" ? (
                                    <>
                                      <td>
                                        <span className="badge badge-light-pending  text-danger fs-5">
                                          {item.approveStatus
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            item.approveStatus?.slice(1)}
                                        </span>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      {item.approveStatus == "rejected" ? (
                                        <>
                                          <td>
                                            <span class="badge badge-light-pending text-danger  fs-5">
                                              {item.approveStatus}
                                            </span>
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td>
                                            <span class="badge badge-light-info text-success fs-5">
                                              {item.approveStatus}
                                            </span>
                                          </td>
                                        </>
                                      )}
                                    </>
                                  )}
                                </tr>
                              ))}

                            </tbody>
                          </table>
                        </div>

                        <div className="col-lg-12 mt-2 text-end">
                          <ReactPaginate
                            previousLabel={"previous"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item mx-2"}
                            previousLinkClassName={"page-link pagestyle"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default RemaningLicenses;
