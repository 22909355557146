import React from "react";
import './error.css';
import shape1 from './images/shape1.png';
import shape2 from './images/shape2.png';
import shape4 from './images/shape4.png';
import shape5 from './images/shape5.png';
import shape3 from './images/shape3.png';
import shape6 from './images/shape6.png';
import shape7 from './images/shape7.png';
import logo from './images/logo.png';
import bg from './images/background-3.png';
import { useNavigate } from "react-router-dom";

const ServiceUnavailable = () => {

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // Go back one step in history
    };

    return (
        <>
            <section className="error-page-section" style={{ backgroundImage: `url(${bg})` }}>
                <div className="full-width-screen">
                    <div className="container-fluid">
                        <div className="content-detail">
                            <img src={logo} style={{width : '70px'}} />
                            <img id="shape1" className="parallax" src={shape1} />
                            {/* <img id="shape2" className="parallax top" src={shape2} /> */}
                            <img id="shape4" className="parallax left top" src={shape4} />
                            <img id="shape5" className="parallax left" src={shape5} />
                            <img id="shape3" className="parallax top" src={shape3} />
                            <img id="shape6" className="parallax top" src={shape6} />
                            <img id="shape7" className="parallax" src={shape7} />
                            <h1 className="global-title">
                                we are under maintenance
                            </h1>

                            <p className="detail-text">
                                We're sorry,<br />
                                Our developers are actively working on this page, and it will be available soon.
                                Thank you for your patience!
                            </p>

                            {/* <div className="back-btn">
                                <a href="index.html" className="btn">Back to Home</a>
                            </div> */}
                            <div className="back-btn">
                                <button className="btn" onClick={handleGoBack}>
                                    Back to Previous Page
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ServiceUnavailable;